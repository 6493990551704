import * as Actions from './constants';

export type AppAction = {
    type: string;
    payload: {} | string;
};

export const getCurrencies = (): AppAction => ({
    type: Actions.GET_CURRENCIES,
    payload: null,
});

export const getCurrenciesSuccess = (currencies): AppAction => ({
    type: Actions.GET_CURRENCIES_SUCCESS,
    payload: currencies,
});

export const getCurrenciesFailed = (error: string): AppAction => ({
    type: Actions.GET_CURRENCIES_FAILED,
    payload: error,
});
