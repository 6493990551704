import * as ActionType from './constants';

export type VpxAction = {
    type: string;
    payload: {} | string;
};

export const searchVpxVpExchangeList = (companyName, vpName, ownerName, groupId, codeId, hashtag): VpxAction => ({
    type: ActionType.SEARCH_VPX_VP_EXCHANGE_LIST,
    payload: { companyName, vpName, ownerName, groupId, codeId, hashtag },
});

export const searchVpxVpExchangeListSuccess = (vpxList): VpxAction => ({
    type: ActionType.SEARCH_VPX_VP_EXCHANGE_LIST_SUCCESS,
    payload: { vpxList },
});

export const searchVpxVpExchangeListFailed = (error: string): VpxAction => ({
    type: ActionType.SEARCH_VPX_VP_EXCHANGE_LIST_FAILED,
    payload: error,
});

export const vpViewUserProfile = (userId): VpxAction => ({
    type: ActionType.VALUE_PROPOSITION_VIEW_USER_PROFILE,
    payload: { userId },
});

export const vpViewUserProfileSuccess = (userPofile): VpxAction => ({
    type: ActionType.VALUE_PROPOSITION_VIEW_USER_PROFILE_SUCCESS,
    payload: { userPofile },
});

export const vpViewUserProfileFailed = (error: string): VpxAction => ({
    type: ActionType.VALUE_PROPOSITION_VIEW_USER_PROFILE_FAILED,
    payload: error,
});

export const vpViewCompanyProfile = (companyId): VpxAction => ({
    type: ActionType.VALUE_PROPOSITION_VIEW_COMPANY_PROFILE,
    payload: { companyId },
});

export const vpViewCompanyProfileSuccess = (companyProfile): VpxAction => ({
    type: ActionType.VALUE_PROPOSITION_VIEW_COMPANY_PROFILE_SUCCESS,
    payload: { companyProfile },
});

export const vpViewCompanyProfileFailed = (error: string): VpxAction => ({
    type: ActionType.VALUE_PROPOSITION_VIEW_COMPANY_PROFILE_FAILED,
    payload: error,
});

export const clearVpProfileError = (): VpxAction => ({
    type: ActionType.CLEAR_VALUE_PROPOSITION_PROFILE_ERROR,
    payload: null,
});

export const requestToFollow = (vpId): VpxAction => ({
    type: ActionType.REQUEST_TO_FOLLOW,
    payload: { vpId },
});

export const requestToFollowSuccess = (vpId): VpxAction => ({
    type: ActionType.REQUEST_TO_FOLLOW_SUCCESS,
    payload: { vpId },
});

export const requestToFollowFailed = (error: string): VpxAction => ({
    type: ActionType.REQUEST_TO_FOLLOW_FAILED,
    payload: error,
});

export const requestToUnfollow = (vpId): VpxAction => ({
    type: ActionType.REQUEST_TO_UNFOLLOW,
    payload: { vpId },
});

export const requestToUnfollowSuccess = (vpId): VpxAction => ({
    type: ActionType.REQUEST_TO_UNFOLLOW_SUCCESS,
    payload: { vpId },
});

export const requestToUnfollowFailed = (error: string): VpxAction => ({
    type: ActionType.REQUEST_TO_UNFOLLOW_FAILED,
    payload: error,
});

export const loadExternalList = (permissionType): VpxAction => ({
    type: ActionType.LOAD_EXTERNAL_LIST,
    payload: { permissionType },
});

export const loadExternalListSuccess = (extenalList): VpxAction => ({
    type: ActionType.LOAD_EXTERNAL_LIST_SUCCESS,
    payload: { extenalList },
});

export const loadExternalListFailed = (error: string): VpxAction => ({
    type: ActionType.LOAD_EXTERNAL_LIST_FAILED,
    payload: error,
});
