// Profile
export const UPDATE_PROFILE_PASSWORD = 'UPDATE_PROFILE_PASSWORD';
export const UPDATE_PROFILE_PASSWORD_SUCCESS = 'UPDATE_PROFILE_PASSWORD_SUCCESS';
export const UPDATE_PROFILE_PASSWORD_FAILED = 'UPDATE_PROFILE_PASSWORD_FAILED';

export const LOAD_PROFILE_INFORMATION = 'LOAD_PROFILE_INFORMATION';
export const LOAD_PROFILE_INFORMATION_SUCCESS = 'LOAD_PROFILE_INFORMATION_SUCCESS';
export const LOAD_PROFILE_INFORMATION_FAILED = 'LOAD_PROFILE_INFORMATION_FAILED';

export const UPDATE_PROFILE_INFORMATION = 'UPDATE_PROFILE_INFORMATION';
export const UPDATE_PROFILE_INFORMATION_SUCCESS = 'UPDATE_PROFILE_INFORMATION_SUCCESS';
export const UPDATE_PROFILE_INFORMATION_FAILED = 'UPDATE_PROFILE_INFORMATION_FAILED';

export const LOAD_USER_SUBSCRIPTION_INFORMATION = 'LOAD_USER_SUBSCRIPTION_INFORMATION';
export const LOAD_USER_SUBSCRIPTION_INFORMATION_SUCCESS = 'LOAD_USER_SUBSCRIPTION_INFORMATION_SUCCESS';
export const LOAD_USER_SUBSCRIPTION_INFORMATION_FAILED = 'LOAD_USER_SUBSCRIPTION_INFORMATION_FAILED';

export const CANCEL_SUBSCRIPTION = 'CANCEL_SUBSCRIPTION';
export const CANCEL_SUBSCRIPTION_SUCCESS = 'CANCEL_SUBSCRIPTION_SUCCESS';
export const CANCEL_SUBSCRIPTION_FAILED = 'CANCEL_SUBSCRIPTION_FAILED';

export const UPGRADE_OR_DOWNGRADE_SUBSCRIPTION = 'UPGRADE_OR_DOWNGRADE_SUBSCRIPTION';
export const UPGRADE_OR_DOWNGRADE_SUBSCRIPTION_SUCCESS = 'UPGRADE_OR_DOWNGRADE_SUBSCRIPTION_SUCCESS';
export const UPGRADE_OR_DOWNGRADE_SUBSCRIPTION_FAILED = 'UPGRADE_OR_DOWNGRADE_SUBSCRIPTION_FAILED';

export const UPDATE_SUCCESSFUL_PLAN = 'UPDATE_SUCCESSFUL_PLAN';
export const UPDATE_SUCCESSFUL_PLAN_SUCCESS = 'UPDATE_SUCCESSFUL_PLAN_SUCCESS';
export const UPDATE_SUCCESSFUL_PLAN_FAILED = 'UPDATE_SUCCESSFUL_PLAN_FAILED';
