import { store } from 'react-notifications-component';

export const defaultTransaction = {
    duration: 0,  // Transition duration in ms.
    timingFunction: 'linear',  // CSS timing function for the transition.
    delay: 0,  //Delay of the transition in ms.
};

export const defaultDismiss = {
    duration: 2000,  // Time in milliseconds after notification gets dismissed.
    onScreen: false,  // Show time left directly on the notification.
    showIcon: true,  // Show or hide the close icon.
    click: true,  // Enable dismissal by click.
    touch: true,  // Enable dismiss by touch move.
    pauseOnHover: true,  // Hovering over notification will pause the dismiss timer.
};

// https://github.com/teodosii/react-notifications-component
const defaultNotification = {
    title: '',  // Title of the notification. Option is ignored if content is set, otherwise it is required.
    message: 'Unknown message',  // Message of the notification. Option is ignored if content is set, otherwise it is required.
    type: "default",  // "success" || "danger" || "info" || "default" || "warning"
    insert: "top",  // Specify where to append notification into the container - top or bottom.
    container: "top-center",  // Container in which the notification will be displayed.
    slidingEnter: defaultTransaction,  // Transition to be used when sliding to show a notification.
    slidingExit: defaultTransaction,  // Transition to be used when sliding to remove a notification.
    touchRevert: defaultTransaction,  // Transition to be used when sliding back after an incomplete swipe.
    touchSlidingExit: defaultTransaction,  // Transition to be used when sliding on swipe.
    dismiss: defaultDismiss,  // Specify how a notification should be dismissed.
}

export const addNotification = (props) => {
    store.addNotification({
        ...defaultNotification,
        ...props
    });
}
