import * as Actions from './constants';

export type FundamentalsAction = {
    type: string;
    payload: {} | string;
};

export const clearFundamentalsList = (): FundamentalsAction => ({
    type: Actions.CLEAR_FUNDAMENTALS_LIST,
    payload: null,
})

export const loadFundamentalsList = (vpId): FundamentalsAction => ({
    type: Actions.LOAD_FUNDAMENTALS_LIST,
    payload: { vpId },
});

export const loadFundamentalsListSuccess = (fundamentals, clientProfile, vpCurrency): FundamentalsAction => ({
    type: Actions.LOAD_FUNDAMENTALS_LIST_SUCCESS,
    payload: { fundamentals, clientProfile, vpCurrency },
});

export const loadFundamentalsListFailed = (error: string): FundamentalsAction => ({
    type: Actions.LOAD_FUNDAMENTALS_LIST_FAILED,
    payload: error,
});

export const fetchFundamentalsById = (id): FundamentalsAction => ({
    type: Actions.FETCH_FUNDAMENTALS_BY_ID,
    payload: { id },
});

export const fetchFundamentalsByIdSuccess = (fundament): FundamentalsAction => ({
    type: Actions.FETCH_FUNDAMENTALS_BY_ID_SUCCESS,
    payload: { fundament },
});

export const fetchFundamentalsByIdFailed = (error: string): FundamentalsAction => ({
    type: Actions.FETCH_FUNDAMENTALS_BY_ID_FAILED,
    payload: error,
});

export const saveFundamentals = (fundament): FundamentalsAction => ({
    type: Actions.SAVE_FUNDAMENTALS,
    payload: { fundament },
});

export const saveFundamentalsSuccess = (): FundamentalsAction => ({
    type: Actions.SAVE_FUNDAMENTALS_SUCCESS,
    payload: null,
});

export const saveFundamentalsFailed = (error: string): FundamentalsAction => ({
    type: Actions.SAVE_FUNDAMENTALS_SUCCESS,
    payload: error,
});

export const deleteFundamentals = (id): FundamentalsAction => ({
    type: Actions.DELETE_FUNDAMENTALS,
    payload: { id }
});

export const deleteFundamentalsSuccess = (): FundamentalsAction => ({
    type: Actions.DELETE_FUNDAMENTALS_SUCCESS,
    payload: null,
});

export const deleteFundamentalsFailed = (error: String): FundamentalsAction => ({
    type: Actions.DELETE_FUNDAMENTALS_FAILED,
    payload: error
});

export const loadFundamentalParams = (): FundamentalsAction => ({
    type: Actions.LOAD_FUNDAMENTAL_PARAMS,
    payload: null,
});

export const loadFundamentalParamsSuccess = (params): FundamentalsAction => ({
    type: Actions.LOAD_FUNDAMENTAL_PARAMS_SUCCESS,
    payload: { params },
});

export const loadFundamentalParamsFailed = (error: String): FundamentalsAction => ({
    type: Actions.LOAD_FUNDAMENTAL_PARAMS_FAILED,
    payload: error,
});

export const clearCalculatedFundamentalValues = (): FundamentalsAction => ({
    type: Actions.CLEAR_CALCULATED_FUNDAMENTAL_VALUES,
    payload: null
});

export const calculateFundamentalValues = (
    existingCost, proposedCost, existingUnitId, proposedUnitId, existingUnitTypeId, proposedUnitTypeId,
    proposalDate, implementationDate, forecastDate, terminationDate
): FundamentalsAction => ({
    type: Actions.CALCULATE_FUNDAMENTAL_VALUES,
    payload: {
        existingCost, proposedCost,
        existingUnitId, proposedUnitId,
        existingUnitTypeId, proposedUnitTypeId,
        proposalDate, implementationDate,
        forecastDate, terminationDate
    },
});

export const calculateFundamentalValuesSuccess = (calculatedFundamentalValues): FundamentalsAction => ({
    type: Actions.CALCULATE_FUNDAMENTAL_VALUES_SUCCESS,
    payload: { calculatedFundamentalValues },
});

export const calculateFundamentalValuesFailed = (error: String): FundamentalsAction => ({
    type: Actions.CALCULATE_FUNDAMENTAL_VALUES_FAILED,
    payload: error,
});
