import { AppAction } from './actions';
import * as Actions from './constants';

const INIT_STATE = {
    profileInformation: null,
    subscriptions: null,
    loading: false,
    error: null,
    successMessage: null,
    subscriptionLoading: false,
};

type State = {
    profileInformation?: {} | null;
    subscriptions?: {} | null;
    loading?: boolean;
    error?: string;
    successMessage?: string;
    subscriptionLoading?: boolean;
};

const User = (state: State = INIT_STATE, action: AppAction) => {
    switch (action.type) {
        case Actions.UPDATE_PROFILE_PASSWORD:
            return { ...state, loading: true, error: null };
        case Actions.UPDATE_PROFILE_PASSWORD_SUCCESS:
            return { ...state, loading: false };
        case Actions.UPDATE_PROFILE_PASSWORD_FAILED:
            return { ...state, error: action.payload, loading: false };
        case Actions.LOAD_PROFILE_INFORMATION:
            return { ...state, profileInformation: null, loading: true, error: null };
        case Actions.LOAD_PROFILE_INFORMATION_SUCCESS:
            return { ...state, profileInformation: action.payload, loading: false };
        case Actions.LOAD_PROFILE_INFORMATION_FAILED:
            return { ...state, error: action.payload, loading: false };
        case Actions.UPDATE_PROFILE_INFORMATION:
            return { ...state, loading: true, error: null };
        case Actions.UPDATE_PROFILE_INFORMATION_SUCCESS:
            return { ...state, profileInformation: action.payload, loading: false };
        case Actions.UPDATE_PROFILE_INFORMATION_FAILED:
            return { ...state, error: action.payload, loading: false };
        case Actions.LOAD_USER_SUBSCRIPTION_INFORMATION:
            return { ...state, subscriptions: null, subscriptionLoading: true, error: null };
        case Actions.LOAD_USER_SUBSCRIPTION_INFORMATION_SUCCESS:
            return { ...state, subscriptions: action.payload, subscriptionLoading: false };
        case Actions.LOAD_USER_SUBSCRIPTION_INFORMATION_FAILED:
            return { ...state, error: action.payload, subscriptionLoading: false };
        case Actions.UPDATE_SUCCESSFUL_PLAN:
            return { ...state, successMessage: null, loading: true, error: null };
        case Actions.UPDATE_SUCCESSFUL_PLAN_SUCCESS:
            return { ...state, successMessage: action.payload.successMessage, loading: false };
        case Actions.UPDATE_SUCCESSFUL_PLAN_FAILED:
            return { ...state, error: action.payload, loading: false };
        case Actions.UPGRADE_OR_DOWNGRADE_SUBSCRIPTION:
            return { ...state, successMessage: null, loading: true, error: null };
        case Actions.UPGRADE_OR_DOWNGRADE_SUBSCRIPTION_SUCCESS:
            return { ...state, successMessage: action.payload.successMessage, loading: false };
        case Actions.UPGRADE_OR_DOWNGRADE_SUBSCRIPTION_FAILED:
            return { ...state, error: action.payload, loading: false };
        case Actions.CANCEL_SUBSCRIPTION:
            return { ...state, successMessage: null, loading: true, error: null };
        case Actions.CANCEL_SUBSCRIPTION_SUCCESS:
            return { ...state, successMessage: action.payload.successMessage, loading: false };
        case Actions.CANCEL_SUBSCRIPTION_FAILED:
            return { ...state, error: action.payload, loading: false };
        default:
            return { ...state };
    }
}

export default User;
