/* VALUE PROPOSITIONS LIST */
export const LOAD_VALUE_PROPOSITIONS_LIST = 'LOAD_VALUE_PROPOSITIONS_LIST';
export const LOAD_VALUE_PROPOSITIONS_LIST_SUCCESS = 'LOAD_VALUE_PROPOSITIONS_LIST_SUCCESS';
export const LOAD_VALUE_PROPOSITIONS_LIST_FAILED = 'LOAD_VALUE_PROPOSITIONS_LIST_FAILED';

export const CLEAR_SELECTED_VALUE_PROPOSITION = 'CLEAR_SELECTED_VALUE_PROPOSITION';
export const CLEAR_VALUE_PROPOSITION_LIST = 'CLEAR_VALUE_PROPOSITION_LIST';

export const DUPLICATE_VALUE_PROPOSITION = 'DUPLICATE_VALUE_PROPOSITION';
export const DUPLICATE_VALUE_PROPOSITION_SUCCESS = 'DUPLICATE_VALUE_PROPOSITION_SUCCESS';
export const DUPLICATE_VALUE_PROPOSITION_FAILED = 'DUPLICATE_VALUE_PROPOSITION_FAILED';

/* VALUE PROPOSITIONS CREATE/EDIT */
export const FETCH_VALUE_PROPOSITION_BY_ID = 'FETCH_VALUE_PROPOSITION_BY_ID';
export const FETCH_VALUE_PROPOSITION_BY_ID_SUCCESS = 'FETCH_VALUE_PROPOSITION_BY_ID_SUCCESS';
export const FETCH_VALUE_PROPOSITION_BY_ID_FAILED = 'FETCH_VALUE_PROPOSITION_BY_ID_FAILED';

export const FETCH_VALUE_PROPOSITION_BY_SHARED_KEY = 'FETCH_VALUE_PROPOSITION_BY_SHARED_KEY';
export const FETCH_VALUE_PROPOSITION_BY_SHARED_KEY_SUCCESS = 'FETCH_VALUE_PROPOSITION_BY_SHARED_KEY_SUCCESS';
export const FETCH_VALUE_PROPOSITION_BY_SHARED_KEY_FAILED = 'FETCH_VALUE_PROPOSITION_BY_SHARED_KEY_FAILED';

export const SAVE_VALUE_PROPOSITION = 'SAVE_VALUE_PROPOSITION';
export const SAVE_VALUE_PROPOSITION_SUCCESS = 'SAVE_VALUE_PROPOSITION_SUCCESS';
export const SAVE_VALUE_PROPOSITION_FAILED = 'SAVE_VALUE_PROPOSITION_FAILED';

export const DELETE_VALUE_PROPOSITION = 'DELETE_VALUE_PROPOSITION';
export const DELETE_VALUE_PROPOSITION_SUCCESS = 'DELETE_VALUE_PROPOSITION_SUCCESS';
export const DELETE_VALUE_PROPOSITION_FAILED = 'DELETE_VALUE_PROPOSITION_FAILED';

export const CHANGE_VALUE_PROPOSITION_STATE = 'CHANGE_VALUE_PROPOSITION_STATE';
export const CHANGE_VALUE_PROPOSITION_STATE_SUCCESS = 'CHANGE_VALUE_PROPOSITION_STATE_SUCCESS';
export const CHANGE_VALUE_PROPOSITION_STATE_FAILED = 'CHANGE_VALUE_PROPOSITION_STATE_FAILED';

/* FOLLOWERS */
export const FETCH_FOLLOWERS_BY_VALUE_PROPOSITION_ID = 'FETCH_FOLLOWERS_BY_VALUE_PROPOSITION_ID';
export const FETCH_FOLLOWERS_BY_VALUE_PROPOSITION_ID_SUCCESS = 'FETCH_FOLLOWERS_BY_VALUE_PROPOSITION_ID_SUCCESS';
export const FETCH_FOLLOWERS_BY_VALUE_PROPOSITION_ID_FAILED = 'FETCH_FOLLOWERS_BY_VALUE_PROPOSITION_ID_FAILED';

/* ATTACHMENTS */
export const FETCH_ATTACHMENTS_BY_VALUE_PROPOSITION_ID = 'FETCH_ATTACHMENTS_BY_VALUE_PROPOSITION_ID';
export const FETCH_ATTACHMENTS_BY_VALUE_PROPOSITION_ID_SUCCESS = 'FETCH_ATTACHMENTS_BY_VALUE_PROPOSITION_ID_SUCCESS';
export const FETCH_ATTACHMENTS_BY_VALUE_PROPOSITION_ID_FAILED = 'FETCH_ATTACHMENTS_BY_VALUE_PROPOSITION_ID_FAILED';