import React from 'react';
import { Redirect } from 'react-router';
import { Route } from 'react-router-dom';

import * as PagePaths from '../constants/pagePath';
import { StripCompanyPaymentCancelFeedback, StripCompanyPaymentSuccessFeedback } from '../pages/company/StripFeedbackPages';
import { MenuOptions, PrivateRoute, PublicAndPrivateRoute } from './Util';

// lazy load all the views
// auth
const SignIn = React.lazy(() => import('../pages/auth/SignIn'));
// TODO(rodrigo.santos): Add this when Sigup is released.
// const SignUp = React.lazy(() => import('../pages/auth/SignUp'));
const Home = React.lazy(() => import('../pages/home/index'));
const Register = React.lazy(() => import('../pages/auth/register/index'));
const Logout = React.lazy(() => import('../pages/auth/Logout'));
const ForgetPassword = React.lazy(() => import('../pages/auth/ForgetPassword'));
const ForgotPassword = React.lazy(() => import('../pages/auth/ForgotPassword'));
// auth register error pages
const ErrorPage = React.lazy(() => import('../pages/auth/register/components/ErrorPage'))
// dashboard
const Dashboard = React.lazy(() => import('../pages/dashboard'));
// client
const ClientList = React.lazy(() => import('../pages/clients/ClientList'));
const AddClient = React.lazy(() => import('../pages/clients/AddClient'));
const EditClient = React.lazy(() => import('../pages/clients/EditClient'));
// value proposition
const ValuePropositionsList = React.lazy(() => import('../pages/valuePropositions/ValuePropositionsList'));
const AddValueProposition = React.lazy(() => import('../pages/valuePropositions/AddValueProposition'));
const EditValueProposition = React.lazy(() => import('../pages/valuePropositions/EditValueProposition'));
const SharedValueProposition = React.lazy(() => import('../pages/valuePropositions/SharedValueProposition'));
// fundamentals
const FundamentalsList = React.lazy(() => import('../pages/fundamentals/FundamentalsList'));
const AddFundamentals = React.lazy(() => import('../pages/fundamentals/AddFundament'));
const EditFundamentals = React.lazy(() => import('../pages/fundamentals/EditFundament'));
// profile
const ProfileSecurity = React.lazy(() => import('../pages/profile/Security'));
const PersonalInformation = React.lazy(() => import('../pages/profile/PersonalInformation'));
const Subscription = React.lazy(() => import('../pages/profile/Subscription'));
const PaymentSuccess = React.lazy(() => import('../pages/profile/PaymentSuccess'));
// company
const CompanyDashboard = React.lazy(() => import('../pages/company/Dashboard'));
const EditCompanyDetails = React.lazy(() => import('../pages/company/EditCompanyDetails'));

const ListCompanyClients = React.lazy(() => import('../pages/company/ListClients'));
const AddCompanyClient = React.lazy(() => import('../pages/company/AddCompanyClient'));
const EditCompanyClient = React.lazy(() => import('../pages/company/EditCompanyClient'));

const ListUsers = React.lazy(() => import('../pages/company/ListUsers'));
const CreateCompany = React.lazy(() => import('../pages/company/CreateCompany'));
const CompanyValupPropositionList = React.lazy(() => import('../pages/company/ValuePropositionList'));
const LicenseChangeConfirm = React.lazy(() => import('../pages/company/LicenseChangeConfirm'));
const ListCompanyInvites = React.lazy(() => import('../pages/company/ListInvites'));

// VPX
const Vpxlist = React.lazy(() => import('../pages/vpx/VpxList'));
const VpxCompanyProfile = React.lazy(() => import('../pages/vpx/VpxCompanyProfile'));
const VpxUserProfile = React.lazy(() => import('../pages/vpx/VpxUserProfile'));

const VpxPublicInvitation = React.lazy(() => import('../pages/vpx/ExternalPublicList'));
const VpxPrivateInvitation = React.lazy(() => import('../pages/vpx/ExternalPrivateList'));

// VPX Invitation
const VpxInvitationList = React.lazy(() => import('../pages/vpx-invitation/VpxInvitationList'));

const InviteUsers = React.lazy(() => import('../pages/admin/InviteUsers'))

// auth
export const authRoutes = {
    path: '/account',
    name: 'Auth',
    children: [
        {
            path: PagePaths.LOGIN_PATH,
            name: 'SignIn',
            component: SignIn,
            exact: true,
            route: Route,
        },
        // TODO(rodrigo.santos): Add this when SIgnup is released.
        // {
        //     path: PagePaths.SIGN_UP_PATH,
        //     name: 'SignUp',
        //     component: SignUp,
        //     exact: true,
        //     route: Route,
        // },
        {
            path: PagePaths.REGISTER_PATH,
            name: 'Register',
            component: Register,
            exact: true,
            route: Route,
        },
        {
            path: PagePaths.ERROR_PAGE_PATH,
            name: 'Error',
            component: ErrorPage,
            route: Route,
        },
        {
            path: PagePaths.LOGOUT_PATH,
            name: 'Logout',
            component: Logout,
            route: Route,
        },
        {
            path: PagePaths.FORGET_PASSWORD_PATH,
            name: 'Forgot Password',
            component: ForgetPassword,
            route: Route,
        },
        {
            path: PagePaths.FORGOT_PASSWORD_PATH,
            name: 'Forgot Password',
            component: ForgotPassword,
            route: Route,
        },
    ]
};

// root routes
export const rootRoute = {
    path: PagePaths.HOME_PATH,
    exact: true,
    component: () => <Redirect to={PagePaths.DASHBOARDS_PATH} />,
    route: PrivateRoute,
};

// root routes
export const landingRoute = {
    path: PagePaths.LANDING_PAGE_PATH,
    exact: true,
    name: 'Landing Page',
    icon: 'mdi mdi-laptop',
    component: Home,
    route: PrivateRoute,
};

const nonExistentCompanyRoutes = {
    path: PagePaths.NON_EXISTENT_COMPANY_PATH,
    name: 'Organization',
    icon: 'uil-building',
    children: [
        {
            path: PagePaths.CREATE_COMPANY,
            name: 'Create',
            exact: true,
            component: CreateCompany,
            route: PrivateRoute,
        },
        {
            path: PagePaths.LIST_COMPANY_INVITES,
            name: 'Invites',
            exact: true,
            component: ListCompanyInvites,
            route: PrivateRoute,
        }
    ]
};

const existentCompanyRoutes = {
    path: PagePaths.COMPANY_PATH,
    name: 'Organization',
    icon: 'uil-building',
    children: [
        {
            path: PagePaths.COMPANY_DASHBOARD_PATH,
            name: 'Dashboard',
            component: CompanyDashboard,
            route: PrivateRoute,
            exact: true,
        },
        {
            path: PagePaths.COMPANY_CLIENTS_PATH,
            name: 'Clients',
            component: ListCompanyClients,
            route: PrivateRoute,
            exact: true,
        },
        {
            path: PagePaths.COMPANY_USERS_PATH,
            name: 'Users',
            component: ListUsers,
            route: PrivateRoute,
            exact: true,
        },
        {
            path: PagePaths.COMPANY_VALUE_PROPOSITIONS_PATH,
            name: 'Value Propositions',
            component: CompanyValupPropositionList,
            route: PrivateRoute,
            exact: true,
        },
    ]
}

const dashboardRoutes = {
    path: PagePaths.DASHBOARDS_PATH,
    name: 'Dashboards',
    icon: 'uil-home-alt',
    header: 'Menu',
    component: Dashboard,
    exact: true,
    route: PrivateRoute,
}

const clientListRoutes = {
    path: PagePaths.CLIENT_LIST_PATH,
    name: 'Clients',
    icon: 'uil-bag-alt',
    component: ClientList,
    exact: true,
    route: PrivateRoute,
}

const valuePrepositionsListRoutes = {
    path: PagePaths.VALUE_PREPOSITIONS_LIST_PATH,
    name: 'Value propositions',
    icon: 'uil-chart-pie-alt',
    component: ValuePropositionsList,
    exact: true,
    route: PrivateRoute,
}

const vpxRoutes = {
    path: PagePaths.VPX_LIST_PATH,
    name: 'VPX',
    icon: 'uil-exchange',
    children: [
        {
            path: PagePaths.VPX_LIST_PATH,
            name: 'Search',
            component: Vpxlist,
            exact: true,
            route: PrivateRoute,
        },
        {
            path: PagePaths.VPX_INVITATION_LIST_PATH,
            name: 'Followers',
            component: VpxInvitationList,
            exact: true,
            route: PrivateRoute,
        },
        {
            path: PagePaths.VPX_INVITATION_PUBLIC_PATH,
            name: 'Following - Public',
            component: VpxPublicInvitation,
            exact: true,
            route: PrivateRoute,
        },
        {
            path: PagePaths.VPX_INVITATION_PRIVATE_PATH,
            name: 'Following - Private',
            component: VpxPrivateInvitation,
            exact: true,
            route: PrivateRoute,
        }
    ]
}

export const internalProfileRoutes = [
    {
        path: PagePaths.PROFILE_PERSONAL_INFORMATION_PATH,
        name: 'Personal information',
        icon: 'uil-folder-check',
        component: PersonalInformation,
        exact: true,
        menuOption: MenuOptions.profile,
        route: PrivateRoute,
    },
    {
        path: PagePaths.PROFILE_SECURITY_PATH,
        name: 'Security',
        icon: 'uil-lock-alt',
        component: ProfileSecurity,
        exact: true,
        menuOption: MenuOptions.profile,
        route: PrivateRoute,
    },
    {
        path: PagePaths.PROFILE_SUBSCRIPTION_PATH,
        name: 'Subscription',
        icon: 'uil-clipboard-notes',
        component: Subscription,
        exact: true,
        menuOption: MenuOptions.profile,
        route: PrivateRoute,
    },
];

const profileRoutes = {
    path: internalProfileRoutes[0].path,
    name: 'Profile',
    icon: 'uil-user',
    component: internalProfileRoutes[0].component,
    exact: true,
    menuOption: MenuOptions.profile,
    route: PrivateRoute,
}

const inviteUsersRoutes = {
    path: PagePaths.ADMIN_INVITE_NEW_USERS,
    name: 'Invite Users',
    icon: 'uil-user-plus',
    component: InviteUsers,
    exact: true,
    route: PrivateRoute,
}

// Menu of prove it to me app.
export const menuRoutes = [
    dashboardRoutes,
    landingRoute,
    clientListRoutes,
    valuePrepositionsListRoutes,
    profileRoutes,
    inviteUsersRoutes,
    nonExistentCompanyRoutes,
    existentCompanyRoutes,
    vpxRoutes,
];

export const internalRoutes = [
    {
        path: PagePaths.SHARED_VALUE_PROPOSITION_PATH,
        exact: true,
        component: SharedValueProposition,
        route: PublicAndPrivateRoute
    },
    {
        path: PagePaths.COMPANY_LICENSE_CHANGE_CONFIRM_PATH,
        exact: true,
        component: LicenseChangeConfirm,
        route: PrivateRoute
    },
    {
        path: PagePaths.ADD_COMPANY_CLIENT_PATH,
        exact: true,
        component: AddCompanyClient,
        route: PrivateRoute
    },
    {
        path: PagePaths.EDIT_COMPANY_CLIENT_PATH,
        exact: true,
        component: EditCompanyClient,
        route: PrivateRoute
    },
    {
        path: PagePaths.COMPANY_LICENSE_STRIP_PURCHASE_SUCCESS,
        exact: true,
        component: StripCompanyPaymentSuccessFeedback,
        route: PrivateRoute
    },
    {
        path: PagePaths.COMPANY_LICENSE_STRIP_PURCHASE_CANCEL,
        exact: true,
        component: StripCompanyPaymentCancelFeedback,
        route: PrivateRoute
    },
    {
        path: PagePaths.ADD_CLIENT_PATH,
        exact: true,
        component: AddClient,
        route: PrivateRoute,
    },
    {
        path: PagePaths.EDIT_CLIENT_PATH,
        exact: true,
        component: EditClient,
        route: PrivateRoute,
    },
    {
        path: PagePaths.ADD_VALUE_PREPOSITION_PATH,
        exact: true,
        component: AddValueProposition,
        route: PrivateRoute,
    },
    {
        path: PagePaths.EDIT_VALUE_PREPOSITION_PATH,
        exact: true,
        component: EditValueProposition,
        route: PrivateRoute,
    },
    {
        path: PagePaths.FUNDAMENTALS_LIST_PATH,
        exact: true,
        component: FundamentalsList,
        route: PrivateRoute,
    },
    {
        path: PagePaths.ADD_FUNDAMENTALS_PATH,
        exact: true,
        component: AddFundamentals,
        route: PrivateRoute,
    },
    {
        path: PagePaths.EDIT_FUNDAMENTALS_PATH,
        exact: true,
        component: EditFundamentals,
        route: PrivateRoute,
    },
    {
        path: PagePaths.PAYMENT_SUCCESS_PATH,
        exact: true,
        component: PaymentSuccess,
        route: PrivateRoute,
    },
    {
        path: PagePaths.EDIT_COMPANY_DETAILS_PATH,
        exact: true,
        component: EditCompanyDetails,
        route: PrivateRoute
    },
    {
        path: PagePaths.VPX_USER_PROFILE_PATH,
        exact: true,
        component: VpxUserProfile,
        route: PrivateRoute,
    },
    {
        path: PagePaths.VPX_COMPANY_PROFILE_PATH,
        exact: true,
        component: VpxCompanyProfile,
        route: PrivateRoute,
    },
];
