// @flow
import * as ActionsType from './constants';
import * as UserActionType from '../user/constants'
import * as CompanyActionType from '../company/constants'

import { getLoggedInUser } from '../../helpers/authUtils';
import { setSession } from './saga';

const INIT_STATE = {
    user: getLoggedInUser(),
    loading: false,
    invalidTokenStatus: null,
    error: null,
};

type AuthAction = { type: string, payload: {} | string };
type State = { user?: {} | null, loading?: boolean, +value?: boolean };

const Auth = (state: State = INIT_STATE, action: AuthAction) => {
    switch (action.type) {
        case ActionsType.LOGIN_USER:
            return { ...state, loading: true };
        case ActionsType.LOGIN_USER_SUCCESS:
            return { ...state, user: action.payload, loading: false, error: null };
        case ActionsType.LOGIN_USER_FAILED:
            return { ...state, error: action.payload, loading: false };

        case ActionsType.REGISTER_USER:
            return { ...state, loading: true };
        case ActionsType.REGISTER_USER_SUCCESS:
            return { ...state, user: action.payload, loading: false, error: null, };
        case ActionsType.REGISTER_USER_FAILED:
            return { ...state, error: action.payload, loading: false };

        case ActionsType.REGISTER_USER_EMAIL:
            return { ...state, loading: true, error: null };
        case ActionsType.REGISTER_USER_EMAIL_SUCCESS:
            return { ...state, loading: false };
        case ActionsType.REGISTER_USER_EMAIL_FAILED:
            return { ...state, error: action.payload, loading: false };

        case ActionsType.VALIDATE_REGISTRATION_EMAIL:
            return { ...state, loading: true, error: null, invalidTokenStatus: null };
        case ActionsType.VALIDATE_REGISTRATION_EMAIL_SUCCESS:
            return { ...state, loading: false };
        case ActionsType.VALIDATE_REGISTRATION_EMAIL_FAILED:
            return {
                ...state,
                invalidTokenStatus: action.payload.status,
                error: action.payload.error,
                loading: false
            };

        case ActionsType.LOGOUT_USER:
            return { ...state, user: null };

        case ActionsType.FORGET_PASSWORD:
            return { ...state, loading: true };
        case ActionsType.FORGET_PASSWORD_SUCCESS:
            return { ...state, passwordResetStatus: action.payload, loading: false, error: null };
        case ActionsType.FORGET_PASSWORD_FAILED:
            return { ...state, error: action.payload, loading: false };

        case UserActionType.UPGRADE_OR_DOWNGRADE_SUBSCRIPTION_SUCCESS: {
            const newUser = { ...state.user, currentPlan: action.payload.newPlanId };
            setSession(newUser);
            return { ...state, user: newUser};
        }
        case UserActionType.CANCEL_SUBSCRIPTION_SUCCESS: {
            const newUser = { ...state.user, currentPlan: action.payload.newPlanId };
            setSession(newUser);
            return { ...state, user: newUser};
        }
        case UserActionType.UPDATE_SUCCESSFUL_PLAN_SUCCESS: {
            const newUser = { ...state.user, currentPlan: action.payload.newPlanId };
            setSession(newUser);
            return { ...state, user: newUser};
        }

        case CompanyActionType.ACCEPT_INVITATION_SUCCESS: {
            const newUser = {
                ...state.user,
                token: action.payload.newUserToken,
                companyId: action.payload.companyId
            };
            setSession(newUser);
            return { ...state, user: newUser };
        }
        
        case CompanyActionType.CREATE_COMPANY_SUCCESS:
            const newUser = {
                ...state.user,
                token: action.payload.newUserToken,
            };
            setSession(newUser);
            return { ...state, user: newUser };

        default:
            return { ...state };
    }
};

export default Auth;
