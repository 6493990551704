export const VPFollowersStatus = {
    PENDING: 1,
    ACCEPT: 2,
    REJECT: 3,
    WAITING_FOR_APPROVAL: 4,
}

export const VPFollowersPermissionType = {
    PUBLIC: 1,
    PRIVATE: 2,
}
