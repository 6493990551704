import { ValuePropositionsAction } from './actions';
import * as ActionType from './constants';

const INIT_STATE = {
    vpList: null,
    vpListLoading: false,
    vpListError: null,
    duplicating: false,
    duplicateError: null,
    vp: null,
    vpLoading: false,
    vpError: null,
    saving: false,
    savingResult: null,
    deleting: false,
    changingActiveState: false,
    followers: null,
    followersLoading: false,
    followersError: null,
    Attachments: null,
    AttachmentsLoading: false,
    AttachmentsError: null,
    sharedVp: null,
    sharedVpLoading: false,
    sharedVpError: null,
};

type State = {
    vpList?: {};
    vpListLoading: boolean;
    vpListError?: string;
    duplicating?: boolean;
    duplicateError?: string;
    vp?: {};
    vpLoading?: boolean;
    vpError?: string;
    saving?: boolean;
    savingResult?: {};
    deleting?: boolean;
    changingActiveState?: Boolean;
    followers?: {},
    followersLoading?: boolean;
    followersError?: string;
    attachments?: {},
    attachmentsLoading?: boolean;
    attachmentsError?: string;
};

const ValueProposition = (state: State = INIT_STATE, action: ValuePropositionsAction) => {
    switch (action.type) {
        case ActionType.CLEAR_SELECTED_VALUE_PROPOSITION:
            return { ...state, vp: null };
        case ActionType.CLEAR_VALUE_PROPOSITION_LIST:
            return { ...state, vpList: null };
        case ActionType.LOAD_VALUE_PROPOSITIONS_LIST:
            return { ...state, vpList: [], vpListLoading: true, vpListError: null };
        case ActionType.LOAD_VALUE_PROPOSITIONS_LIST_SUCCESS:
            return { ...state, vpList: action.payload, vpListLoading: false };
        case ActionType.LOAD_VALUE_PROPOSITIONS_LIST_FAILED:
            return { ...state, vpListError: action.payload, vpListLoading: false };
        case ActionType.DUPLICATE_VALUE_PROPOSITION:
            return { ...state, duplicating: true, duplicateError: null };
        case ActionType.DUPLICATE_VALUE_PROPOSITION_SUCCESS:
            return { ...state, duplicating: false };
        case ActionType.DUPLICATE_VALUE_PROPOSITION_FAILED:
            return { ...state, duplicating: false, duplicateError: action.payload };

        case ActionType.FETCH_VALUE_PROPOSITION_BY_SHARED_KEY:
            return { ...state, sharedVp: null, sharedVpLoading: true, sharedVpError: null };
        case ActionType.FETCH_VALUE_PROPOSITION_BY_SHARED_KEY_SUCCESS:
            return { ...state, sharedVpLoading: false, sharedVp: action.payload.vp, sharedVpError: null };
        case ActionType.FETCH_VALUE_PROPOSITION_BY_SHARED_KEY_FAILED:
            return { ...state, sharedVpLoading: false, sharedVpError: action.payload };

        case ActionType.FETCH_VALUE_PROPOSITION_BY_ID:
            return { ...state, vpLoading: true, vpError: null };
        case ActionType.FETCH_VALUE_PROPOSITION_BY_ID_SUCCESS:
            return { ...state, vpLoading: false, vp: action.payload.vp };
        case ActionType.FETCH_VALUE_PROPOSITION_BY_ID_FAILED:
            return { ...state, vpLoading: false, vpError: action.payload };
        case ActionType.SAVE_VALUE_PROPOSITION:
            return { ...state, savingResult: null, saving: true, error: null };
        case ActionType.SAVE_VALUE_PROPOSITION_SUCCESS:
            return {
                ...state,
                savingResult: { vpId: action.payload.vpId, clientId: action.payload.clientId },
                saving: false
            };
        case ActionType.SAVE_VALUE_PROPOSITION_FAILED:
            return { ...state, saving: false, vpError: action.payload };

        case ActionType.CHANGE_VALUE_PROPOSITION_STATE:
            return { ...state, changingActiveState: true, vpError: null };
        case ActionType.CHANGE_VALUE_PROPOSITION_STATE_SUCCESS:
            return { ...state, changingActiveState: false };
        case ActionType.CHANGE_VALUE_PROPOSITION_STATE_FAILED:
            return { ...state, changingActiveState: false, vpError: action.payload };
        case ActionType.DELETE_VALUE_PROPOSITION:
            return { ...state, deleting: true, vpError: null };
        case ActionType.DELETE_VALUE_PROPOSITION_SUCCESS:
            return { ...state, deleting: false };
        case ActionType.DELETE_VALUE_PROPOSITION_FAILED:
            return { ...state, deleting: false, vpError: action.payload };

        case ActionType.FETCH_FOLLOWERS_BY_VALUE_PROPOSITION_ID:
            return { ...state, followers: null, followersError: null, followersLoading: true };
        case ActionType.FETCH_FOLLOWERS_BY_VALUE_PROPOSITION_ID_SUCCESS:
            return { ...state, followers: action.payload.followers, followersLoading: false };
        case ActionType.FETCH_FOLLOWERS_BY_VALUE_PROPOSITION_ID_FAILED:
            return { ...state, followersError: action.payload, followersLoading: false };
        
        case ActionType.FETCH_ATTACHMENTS_BY_VALUE_PROPOSITION_ID:
            return { ...state, attachments: null, attachmentsError: null, attachmentsLoading: true };
        case ActionType.FETCH_ATTACHMENTS_BY_VALUE_PROPOSITION_ID_SUCCESS:
            return { ...state, attachments: action.payload.attachments, attachmentsLoading: false };
        case ActionType.FETCH_ATTACHMENTS_BY_VALUE_PROPOSITION_ID_FAILED:
            return { ...state, attachmentsError: action.payload, attachmentsLoading: false };
        default:
            return { ...state };
    }
}

export default ValueProposition;
