import * as Actions from './constants';

export type ReportAction = {
    type: string;
    payload: {} | string;
};

export const downloadClientReport = (clientId, clientName): ReportAction => ({
    type: Actions.DOWNLOAD_CLIENT_REPORT,
    payload: { clientId, clientName },
});

export const downloadClientReportSuccess = (): ReportAction => ({
    type: Actions.DOWNLOAD_CLIENT_REPORT_SUCCESS,
    payload: null,
});

export const downloadClientReportFailed = (error: string): ReportAction => ({
    type: Actions.DOWNLOAD_CLIENT_REPORT_FAILED,
    payload: error,
});

export const downloadValuePropositionReport = (vpId, vpName): ReportAction => ({
    type: Actions.DOWNLOAD_VALUE_PROPOSITION_REPORT,
    payload: { vpId, vpName },
});

export const downloadValuePropositionReportSuccess = (): ReportAction => ({
    type: Actions.DOWNLOAD_VALUE_PROPOSITION_REPORT_SUCCESS,
    payload: null,
});

export const downloadValuePropositionReportFailed = (error: string): ReportAction => ({
    type: Actions.DOWNLOAD_VALUE_PROPOSITION_REPORT_FAILED,
    payload: error,
});
