export const isFreePlan = (planId) => planId === Plan.FREE;
export const isPremiumPlan = (planId) => planId === Plan.PREMIUM;
export const isExecutivePlan = (planId) => planId === Plan.EXECUTIVE;

export const Plan = {
    FREE: 1,
    PREMIUM: 2,
    EXECUTIVE: 3,
}

export const paidLicenseOptionItems = [
    { label: 'Premium', value: Plan.PREMIUM },
    { label: 'Executive', value: Plan.EXECUTIVE },
];

export const hasSubscriptionPlan = (planId) => isPremiumPlan(planId) || isExecutivePlan(planId);
