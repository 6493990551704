// @flow
import { all } from 'redux-saga/effects';

import appSaga from './app/saga';
import appMenuSaga from './appMenu/saga';
import authSaga from './auth/saga';
import clientSaga from './client/saga';
import companySaga from './company/saga';
import dashboardSaga from './dashboard/saga';
import followersSaga from './followers/saga';
import fundamentalsSaga from './fundamentals/saga';
import layoutSaga from './layout/saga';
import reportSaga from './report/saga';
import userSaga from './user/saga';
import valuePropositionsSaga from './valuePropositions/saga';
import vpxSaga from './vpx/saga';

export const CreateSuccessMockedData = (data) => new Promise(
    function(resolve, reject) {
        window.setTimeout(
            function() {
                resolve({
                    status: 200,
                    ...data
                });
            },
            Math.random() * 2000 + 1000
        );
    }
);

export default function* rootSaga(getState: any): any {
    yield all([
        appSaga(),
        authSaga(),
        layoutSaga(),
        appMenuSaga(),
        clientSaga(),
        companySaga(),
        valuePropositionsSaga(),
        fundamentalsSaga(),
        followersSaga(),
        dashboardSaga(),
        userSaga(),
        reportSaga(),
        vpxSaga(),
    ]);
}
