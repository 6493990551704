// @flow
import * as ActionsType from './constants';

type AuthAction = { type: string, payload: {} | string };

export const loginUser = (username: string, password: string): AuthAction => ({
    type: ActionsType.LOGIN_USER,
    payload: { username, password },
});

export const loginUserSuccess = (user: string): AuthAction => ({
    type: ActionsType.LOGIN_USER_SUCCESS,
    payload: user,
});

export const loginUserFailed = (error: string): AuthAction => ({
    type: ActionsType.LOGIN_USER_FAILED,
    payload: error,
});

export const registerUserEmail = (email: string): AuthAction => ({
    type: ActionsType.REGISTER_USER_EMAIL,
    payload: { email },
});

export const registerUserEmailSuccess = (): AuthAction => ({
    type: ActionsType.REGISTER_USER_EMAIL_SUCCESS,
    payload: null,
});

export const registerUserEmailFailed = (error: string): AuthAction => ({
    type: ActionsType.REGISTER_USER_EMAIL_FAILED,
    payload: error,
});

export const validateRegistrationEmail = (token?: string): AuthAction => ({
    type: ActionsType.VALIDATE_REGISTRATION_EMAIL,
    payload: { token },
});

export const validateRegistrationEmailSuccess = (): AuthAction => ({
    type: ActionsType.VALIDATE_REGISTRATION_EMAIL_SUCCESS,
    payload: null,
});

export const validateRegistrationEmailFailed = (status, error): AuthAction => ({
    type: ActionsType.VALIDATE_REGISTRATION_EMAIL_FAILED,
    payload: { status, error },
});

export const registerUser = (
    name, companyName, codeId, groupId, logo, country, state, city, zipcode, address, password, token
): AuthAction => ({
    type: ActionsType.REGISTER_USER,
    payload: { name, companyName, codeId, groupId, logo, country, state, city, zipcode, address, password, token },
});

export const registerUserSuccess = (user: {}): AuthAction => ({
    type: ActionsType.REGISTER_USER_SUCCESS,
    payload: user,
});

export const registerUserFailed = (error: string): AuthAction => ({
    type: ActionsType.REGISTER_USER_FAILED,
    payload: error,
});

export const logoutUser = (history: any): AuthAction => ({
    type: ActionsType.LOGOUT_USER,
    payload: { history },
});

export const forgetPassword = (username: string): AuthAction => ({
    type: ActionsType.FORGET_PASSWORD,
    payload: { username },
});

export const forgetPasswordSuccess = (passwordResetStatus: string): AuthAction => ({
    type: ActionsType.FORGET_PASSWORD_SUCCESS,
    payload: passwordResetStatus,
});

export const forgetPasswordFailed = (error: string): AuthAction => ({
    type: ActionsType.FORGET_PASSWORD_FAILED,
    payload: error,
});

export const resetPassword = (username: string, password: string, code: string): AuthAction => ({
    type: ActionsType.RESET_PASSWORD,
    payload: { username, password, code },
});
