import * as Actions from './constants';

export type UserAction = {
    type: string;
    payload: {} | string;
};

export const updateProfilePassword = (currentPassword, newPassword): UserAction => ({
    type: Actions.UPDATE_PROFILE_PASSWORD,
    payload: { currentPassword, newPassword },
});

export const updateProfilePasswordSuccess = (): UserAction => ({
    type: Actions.UPDATE_PROFILE_PASSWORD_SUCCESS,
    payload: null,
});

export const updateProfilePasswordFailed = (error: string): UserAction => ({
    type: Actions.UPDATE_PROFILE_PASSWORD_FAILED,
    payload: error,
});

export const loadProfileInformation = (): UserAction => ({
    type: Actions.LOAD_PROFILE_INFORMATION,
    payload: null,
});

export const loadProfileInformationSuccess = (profile): UserAction => ({
    type: Actions.LOAD_PROFILE_INFORMATION_SUCCESS,
    payload: profile,
});

export const loadProfileInformationFailed = (error: string): UserAction => ({
    type: Actions.LOAD_PROFILE_INFORMATION_FAILED,
    payload: error,
});

export const updateProfileInformation = (
    name, companyName, phoneNumber, city, state, country, codeId, groupId, email, zipCode, address, logo
): UserAction => ({
    type: Actions.UPDATE_PROFILE_INFORMATION,
    payload: {
        name, companyName, phoneNumber, city, state, country, codeId, groupId, email, zipCode, address, logo
    },
});

export const updateProfileInformationSuccess = (profile): UserAction => ({
    type: Actions.UPDATE_PROFILE_INFORMATION_SUCCESS,
    payload: profile,
});

export const updateProfileInformationFailed = (error: string): UserAction => ({
    type: Actions.UPDATE_PROFILE_INFORMATION_FAILED,
    payload: error,
});

export const loadUserSubscriptionInformation = (): UserAction => ({
    type: Actions.LOAD_USER_SUBSCRIPTION_INFORMATION,
    payload: null,
});

export const loadUserSubscriptionInformationSuccess = (subscriptions): UserAction => ({
    type: Actions.LOAD_USER_SUBSCRIPTION_INFORMATION_SUCCESS,
    payload: subscriptions,
});

export const loadUserSubscriptionInformationFailed = (error: string): UserAction => ({
    type: Actions.LOAD_USER_SUBSCRIPTION_INFORMATION_FAILED,
    payload: error,
});

export const cancelSubscription = (newPlanId): UserAction => ({
    type: Actions.CANCEL_SUBSCRIPTION,
    payload: { newPlanId },
});

export const cancelSubscriptionSuccess = (message, newPlanId): UserAction => ({
    type: Actions.CANCEL_SUBSCRIPTION_SUCCESS,
    payload: { successMessage: message, newPlanId },
});

export const cancelSubscriptionFailed = (error: string): UserAction => ({
    type: Actions.CANCEL_SUBSCRIPTION_FAILED,
    payload: error,
});

export const upgradeOrDowngradeSubscription = (newPlanId): UserAction => ({
    type: Actions.UPGRADE_OR_DOWNGRADE_SUBSCRIPTION,
    payload: { newPlanId },
});

export const upgradeOrDowngradeSubscriptionSuccess = (message, newPlanId): UserAction => ({
    type: Actions.UPGRADE_OR_DOWNGRADE_SUBSCRIPTION_SUCCESS,
    payload: { successMessage: message, newPlanId },
});

export const upgradeOrDowngradeSubscriptionFailed = (error: string): UserAction => ({
    type: Actions.UPGRADE_OR_DOWNGRADE_SUBSCRIPTION_FAILED,
    payload: error,
});

export const updateSuccessfulPlan = (newPlanId): UserAction => ({
    type: Actions.UPDATE_SUCCESSFUL_PLAN,
    payload: { newPlanId },
});

export const updateSuccessfulPlanSuccess = (message, newPlanId): UserAction => ({
    type: Actions.UPDATE_SUCCESSFUL_PLAN_SUCCESS,
    payload: { successMessage: message, newPlanId },
});

export const updateSuccessfulPlanFailed = (error: string): UserAction => ({
    type: Actions.UPDATE_SUCCESSFUL_PLAN_FAILED,
    payload: error,
});
