import { CompanyAction } from './actions';
import * as ActionType from './constants';

const INIT_STATE = {
    details: null,
    detailsLoading: false,
    detailsError: null,
    successMessage: null,
    failureMessage: null,
    dashboard: null,
    dashboardLoading: false,
    dashboardError: null,
    listClients: null,
    listClientsLoading: false,
    listClientsError: null,
    mainClientProfileLoading: false,
    mainClientProfileError: null,
    mainClientProfile: null,
    savingMainClient: false,
    listUsers: null,
    listUsersLoading: false,
    listUsersError: null,
    performingAction: false,
    actionSuccessMessage: null,
    actionFailureMessage: null,
    vpList: null,
    vpListLoading: null,
    vpListFailureMessage: null,
    vpDetailsDict: {},
    vpDetailsLoading: null,
    vpDetailsFailureMessage: null,
    licensesActionLoading: false,
    licensesActionSuccessMessage: false,
    licensesActionFailureMessage: false,
    license: null,
    invitations: null,
    invitationsLoading: false,
    invitationsError: null,
}

type State = {
    details?: {};
    detailsLoading: boolean;
    detailsError?: string;
    successMessage?: string;
    failureMessage?: string;
    dashboard?: {};
    dashboardLoading: boolean;
    dashboardError?: string;
    listClients?: {},
    listClientsLoading: boolean,
    listClientsError?: string,
    mainClientProfileLoading: boolean,
    mainClientProfileError?: string,
    mainClientProfile?: {},
    savingMainClient: boolean,
    listUsers?: {};
    listUsersLoading: boolean;
    listUsersError?: string;
    performingAction: boolean;
    actionSuccessMessage?: string;
    actionFailureMessage?: string;
    vpList?: {};
    vpListLoading?: boolean;
    vpListFailureMessage?: boolean;
    vpDetailsDict: {};
    vpDetailsLoading?: boolean;
    vpDetailsFailureMessage?: boolean;
    licensesActionLoading: boolean;
    licensesActionSuccessMessage?: string;
    licensesActionFailureMessage?: string;
    license: boolean;
    invitations?: {},
    invitationsLoading: boolean,
    invitationsError?: string,
}

const Company = (state: State = INIT_STATE, action: CompanyAction) => {
    switch (action.type) {
        case ActionType.CLEAR_COMPANY_MESSAGES:
            return {
                ...state,
                detailsError: null,
                successMessage: null,
                failureMessage: null,
                dashboardError: null,
                listUsersError: null,
                actionSuccessMessage: null,
                actionFailureMessage: null,
                licensesActionSuccessMessage: null,
                licensesActionFailureMessage: null,
            }

        case ActionType.LOAD_COMPANY_DETAILS:
            return { ...state, detailsLoading: true, detailsError: null };
        case ActionType.LOAD_COMPANY_DETAILS_SUCCESS:
            return { ...state, detailsLoading: false, details: action.payload };
        case ActionType.LOAD_COMPANY_DETAILS_FAILED:
            return { ...state, detailsLoading: false, detailsError: action.payload };

        case ActionType.EDIT_COMPANY_DETAILS:
            return {
                ...state,
                detailsLoading: true,
                detailsError: null,
                successMessage: null,
                failureMessage: null,
            };
        case ActionType.EDIT_COMPANY_DETAILS_SUCCESS:
            return {
                ...state,
                detailsLoading: false,
                details: null,
                successMessage: action?.payload?.successMessage
            };
        case ActionType.EDIT_COMPANY_DETAILS_FAILED:
            return { ...state, detailsLoading: false, failureMessage: action.payload };

        case ActionType.CREATE_COMPANY:
            return { ...state, detailsLoading: true, successMessage: null, failureMessage: null };
        case ActionType.CREATE_COMPANY_SUCCESS:
            return { ...state, detailsLoading: false, successMessage: action.payload.successMessage };
        case ActionType.CREATE_COMPANY_FAILED:
            return { ...state, detailsLoading: false, failureMessage: action.payload };

        case ActionType.LOAD_COMPANY_DASHBOARD_DATA:
            return { ...state, dashboardLoading: true, dashboardError: null };
        case ActionType.LOAD_COMPANY_DASHBOARD_DATA_SUCCESS:
            return {
                ...state,
                dashboardLoading: false,
                dashboard: {
                    isOwner: action.payload.isOwner,
                    vpoTotalImplementation: action.payload.vpoTotalImplementation,
                    vpoTotalForecast: action.payload.vpoTotalForecast,
                    vpoTotalProposition: action.payload.vpoTotalProposition,
                    valuePropositionSummary: action.payload.valuePropositionSummary,
                    totalClients: action.payload.totalClients,
                    totalValueProposition: action.payload.totalValueProposition,
                    totalPortifolioAmount: action.payload.totalPortifolioAmount,
                    totalFundamentals: action.payload.totalFundamentals,
                }
            };
        case ActionType.LOAD_COMPANY_DASHBOARD_DATA_FAILED:
            return { ...state, dashboardLoading: false, dashboardError: action.payload };

        case ActionType.LOAD_COMPANY_CLIENTS:
            return { ...state, listClientsLoading: true, listClientsError: null, listClients: null };
        case ActionType.LOAD_COMPANY_CLIENTS_SUCCESS:
            return { ...state, listClientsLoading: false, listClients: action.payload };
        case ActionType.LOAD_COMPANY_CLIENTS_FAILED:
            return { ...state, listClientsLoading: false, listClientsError: action.payload };
        case ActionType.LOAD_COMPANY_MAIN_CLIENT:
            return { ...state, mainClientProfileLoading: true, mainClientProfileError: null, mainClientProfile: null };
        case ActionType.LOAD_COMPANY_MAIN_CLIENT_SUCCESS:
            return { ...state, mainClientProfileLoading: false, mainClientProfile: action.payload.mainClientProfile };
        case ActionType.LOAD_COMPANY_MAIN_CLIENT_FAILED:
            return { ...state, mainClientProfileLoading: false, mainClientProfileError: action.payload };
        case ActionType.SAVE_COMPANY_MAIN_CLIENT:
            return { ...state, savingMainClient: true, mainClientProfileError: null };
        case ActionType.SAVE_COMPANY_MAIN_CLIENT_SUCCESS:
            return { ...state, savingMainClient: false, mainClientProfile: action.payload };
        case ActionType.SAVE_COMPANY_MAIN_CLIENT_FAILED:
            return { ...state, savingMainClient: false, mainClientProfileError: action.payload };

        case ActionType.LOAD_COMPANY_USERS:
            return { ...state, listUsersLoading: true, listUsersError: null };
        case ActionType.LOAD_COMPANY_USERS_SUCCESS:
            return { ...state, listUsersLoading: false, listUsers: action.payload };
        case ActionType.LOAD_COMPANY_USERS_FAILED:
            return { ...state, listUsersLoading: false, listUsersError: action.payload };

        case ActionType.INVITE_USER_TO_COMPANY:
            return { ...state, performingAction: true, actionSuccessMessage: null, actionFailureMessage: null };
        case ActionType.INVITE_USER_TO_COMPANY_SUCCESS:
            return { ...state, performingAction: false, actionSuccessMessage: action.payload };
        case ActionType.INVITE_USER_TO_COMPANY_FAILED:
            return { ...state, performingAction: false, actionFailureMessage: action.payload };

        case ActionType.MAKE_USER_MANAGER:
            return { ...state, performingAction: true, actionSuccessMessage: null, actionFailureMessage: null };
        case ActionType.MAKE_USER_MANAGER_SUCCESS:
            return { ...state, performingAction: false, actionSuccessMessage: action.payload };
        case ActionType.MAKE_USER_MANAGER_FAILED:
            return { ...state, performingAction: false, actionFailureMessage: action.payload };

        case ActionType.REMOVE_USER_MANAGER_FLAG:
            return { ...state, performingAction: true, actionSuccessMessage: null, actionFailureMessage: null };
        case ActionType.REMOVE_USER_MANAGER_FLAG_SUCCESS:
            return { ...state, performingAction: false, actionSuccessMessage: action.payload };
        case ActionType.REMOVE_USER_MANAGER_FLAG_FAILED:
            return { ...state, performingAction: false, actionFailureMessage: action.payload };

        case ActionType.ASSOCIATE_LICENSE:
            return { ...state, performingAction: true, actionSuccessMessage: null, actionFailureMessage: null };
        case ActionType.ASSOCIATE_LICENSE_SUCCESS:
            return { ...state, performingAction: false, actionSuccessMessage: action.payload };
        case ActionType.ASSOCIATE_LICENSE_FAILED:
            return { ...state, performingAction: false, actionFailureMessage: action.payload };

        case ActionType.CANCEL_LICENSE:
            return { ...state, performingAction: true, actionSuccessMessage: null, actionFailureMessage: null };
        case ActionType.CANCEL_LICENSE_SUCCESS:
            return { ...state, performingAction: false, actionSuccessMessage: action.payload };
        case ActionType.CANCEL_LICENSE_FAILED:
            return { ...state, performingAction: false, actionFailureMessage: action.payload };

        case ActionType.REMOVE_USER_FROM_ORGANIZATION:
            return { ...state, performingAction: true, actionSuccessMessage: null, actionFailureMessage: null };
        case ActionType.REMOVE_USER_FROM_ORGANIZATION_SUCCESS:
            return { ...state, performingAction: false, actionSuccessMessage: action.payload };
        case ActionType.REMOVE_USER_FROM_ORGANIZATION_FAILED:
            return { ...state, performingAction: false, actionFailureMessage: action.payload };
        case ActionType.LOAD_COMPANY_VALUE_PROPOSITIONS:
            return { ...state, vpDetailsDict: {}, vpList: null, vpListLoading: true, vpListFailureMessage: null };
        case ActionType.LOAD_COMPANY_VALUE_PROPOSITIONS_SUCCESS:
            return { ...state, vpListLoading: false, vpList: action.payload};
        case ActionType.LOAD_COMPANY_VALUE_PROPOSITIONS_FAILED:
            return { ...state, };

        case ActionType.GET_COMPANY_VALUE_PROPOSITION_DETAILS:
            return { ...state, vpDetailsLoading: true, vpDetailsFailureMessage: null };
        case ActionType.GET_COMPANY_VALUE_PROPOSITION_DETAILS_SUCCESS:
            return {
                ...state,
                vpDetailsLoading: false,
                vpDetailsDict: {...state.vpDetailsDict, [action.payload.vpId]: action.payload.vpDetails }
            };
        case ActionType.GET_COMPANY_VALUE_PROPOSITION_DETAILS_FAILED:
            return { ...state, vpDetailsLoading: false, vpDetailsFailureMessage: action.payload };

        case ActionType.COMPANY_UPGRADE_LICENSES:
        case ActionType.COMPANY_DOWNGRADE_LICENSES:
        case ActionType.COMPANY_USER_LICENSE_SUCCESS_STRIPE:
            return { ...state, licensesActionLoading: true, licensesActionSuccessMessage: null, licensesActionFailureMessage: null };
        case ActionType.COMPANY_UPGRADE_LICENSES_SUCCESS:
        case ActionType.COMPANY_DOWNGRADE_LICENSES_SUCCESS:
        case ActionType.COMPANY_USER_LICENSE_SUCCESS_STRIPE_SUCCESS:
            return { ...state, licensesActionLoading: false, licensesActionSuccessMessage: action.payload, details: null };
        case ActionType.COMPANY_UPGRADE_LICENSES_FAILED:
        case ActionType.COMPANY_DOWNGRADE_LICENSES_FAILED:
        case ActionType.COMPANY_USER_LICENSE_SUCCESS_STRIPE_FAILED:
            return { ...state, licensesActionLoading: false, licensesActionFailureMessage: action.payload };

        case ActionType.CHANGE_COMPANY_USER_LICENSES:
            return { ...state, licensesActionLoading: true, licensesActionFailureMessage: null, license: null };
        case ActionType.CHANGE_COMPANY_USER_LICENSES_SUCCESS:
            return { ...state, licensesActionLoading: false, license: action.payload };
        case ActionType.CHANGE_COMPANY_USER_LICENSES_FAILED:
            return { ...state, licensesActionLoading: false, licensesActionFailureMessage: action.payload };

        case ActionType.CLEAR_COMPANY_USER_LICENSE:
            return { ...state, license: null }

        case ActionType.GET_MY_INVITATIONS:
            return { ...state, invitationsLoading: true, invitationsError: null, invitations: null };
        case ActionType.GET_MY_INVITATIONS_SUCCESS:
            return { ...state, invitationsLoading: false, invitations: action.payload };
        case ActionType.GET_MY_INVITATIONS_FAILED:
            return { ...state, invitationsLoading: false, invitationsError: action.payload };
        case ActionType.ACCEPT_INVITATION:
            return { ...state, invitationsLoading: true, invitationsError: null };
        case ActionType.ACCEPT_INVITATION_SUCCESS:
            const newInvitationsAfterSuccess = state.invitations
                .filter(invite => invite.companyId !== action.payload.companyId );
            return { ...state, invitationsLoading: false, invitations: newInvitationsAfterSuccess };
        case ActionType.ACCEPT_INVITATION_FAILED:
            return { ...state, invitationsLoading: false, invitationsError: action.payload };
        case ActionType.REJECT_INVITATION:
            return { ...state, invitationsLoading: true, invitationsError: null };
        case ActionType.REJECT_INVITATION_SUCCESS:
            const newInvitationsAfterReject = state.invitations
                .filter(invite => invite.companyId !== action.payload.companyId );
            return { ...state, invitationsLoading: false, invitations: newInvitationsAfterReject };
        case ActionType.REJECT_INVITATION_FAILED:
            return { ...state, invitationsLoading: false, invitationsError: action.payload };

        default:
            return { ...state };
    }
}

export default Company;
