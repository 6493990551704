import * as ActionType from './constants';

export type FollowersAction = {
    type: string;
    payload: {} | string;
};

export const deleteFollower = (id): FollowersAction => ({
    type: ActionType.DELETE_FOLLOWER,
    payload: { id },
});

export const deleteFollowerSuccess = (): FollowersAction => ({
    type: ActionType.DELETE_FOLLOWER_SUCCESS,
    payload: null,
});

export const deleteFollowerFailed = (error: string): FollowersAction => ({
    type: ActionType.DELETE_FOLLOWER_FAILED,
    payload: error,
});

export const acceptFollower = (id): FollowersAction => ({
    type: ActionType.ACCEPT_FOLLOWER,
    payload: { id },
});

export const acceptFollowerSuccess = (id): FollowersAction => ({
    type: ActionType.ACCEPT_FOLLOWER_SUCCESS,
    payload: { id },
});

export const acceptFollowerFailed = (error: string): FollowersAction => ({
    type: ActionType.ACCEPT_FOLLOWER_FAILED,
    payload: error,
});

export const rejectFollower = (id): FollowersAction => ({
    type: ActionType.REJECT_FOLLOWER,
    payload: { id },
});

export const rejectFollowerSuccess = (id): FollowersAction => ({
    type: ActionType.REJECT_FOLLOWER_SUCCESS,
    payload: { id },
});

export const rejectFollowerFailed = (error: string): FollowersAction => ({
    type: ActionType.REJECT_FOLLOWER_FAILED,
    payload: error,
});

export const shareValueProposition = (vpId, name, email): FollowersAction => ({
    type: ActionType.SHARE_VALUE_PROPOSITION,
    payload: { vpId, name, email },
});

export const shareValuePropositionSuccess = (): FollowersAction => ({
    type: ActionType.SHARE_VALUE_PROPOSITION_SUCCESS,
    payload: null,
});

export const shareValuePropositionFailed = (error: string): FollowersAction => ({
    type: ActionType.SHARE_VALUE_PROPOSITION_FAILED,
    payload: error,
});

export const listAllFollowers = (): FollowersAction => ({
    type: ActionType.FOLLOWERS_LIST_ALL,
    payload: null,
});

export const listAllFollowersSuccess = (followersList): FollowersAction => ({
    type: ActionType.FOLLOWERS_LIST_ALL_SUCCESS,
    payload: { followersList },
});

export const listAllFollowersFailed = (error: string): FollowersAction => ({
    type: ActionType.FOLLOWERS_LIST_ALL_FAILED,
    payload: error,
});
