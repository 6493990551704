import { all, fork, put, takeLatest } from '@redux-saga/core/effects';
import { call, delay } from 'redux-saga/effects';

import { apiServiceUrl } from '../../constants';
import {
    fetchGetMethod,
    fetchPostMethod,
    fetchPostMethodWithFormData,
    GetErrorMessageFromStatus
} from '../../helpers/api';
import { getLoggedInUser } from '../../helpers/authUtils';
import * as Actions from './actions';
import * as ActionsType from './constants';

const companyApiServiceUrl = `${apiServiceUrl}/company`
const dashboardApiServiceUrl = `${apiServiceUrl}/dashboard`

// Company Details.
function* loadCompanyDetails() {
    try {
        const user = getLoggedInUser();
        const response = yield call(
            fetchGetMethod,
            `${companyApiServiceUrl}/GetCompanyDetails`,
            user.token
        );
        if (response.status === 200) {
            const { company, status } = yield response.data
                .then((response) => ({ company: response.company }))
                .catch(() => ({ status: 500 }));
            if (company) {
                yield put(Actions.loadCompanyDetailsSuccess(company));
            } else {
                yield put(Actions.loadCompanyDetailsFailed(GetErrorMessageFromStatus({ status: status })));
            }
        } else {
            yield put(Actions.loadCompanyDetailsFailed(GetErrorMessageFromStatus(response)));
        }
    } catch (error) {
        yield put(Actions.loadCompanyDetailsFailed(GetErrorMessageFromStatus(error)));
    }
}

function* createCompany({ payload: { companyDetails } }) {
    const user = getLoggedInUser();
    const data = new FormData();
    data.append('AddressLine1', companyDetails.AddressLine1);
    data.append('AddressLine2', companyDetails.AddressLine2);
    data.append('Country', companyDetails.Country);
    data.append('Email', companyDetails.Email);
    data.append('Name', companyDetails.Name);
    data.append('File', companyDetails.File);
    try {
        const response = yield call(
            fetchPostMethodWithFormData,
            `${companyApiServiceUrl}/CreateCompany`,
            user.token,
            data
        );

        if (response.status === 200) {
            const { successMessage, status, newToken } = yield response.data
                .then((response) => ({ successMessage: response.message, newToken: response.token }))
                .catch(() => ({ status: 500 }));
            if (successMessage && newToken) {
                yield put(Actions.createCompanySuccess(newToken, successMessage));
            } else {
                yield put(Actions.createCompanyFailed(GetErrorMessageFromStatus({ status: status })));
            }
        } else if (response.status === 400) {
            const { message } = yield response.data.then((response) => ({ message: response.message }));
            yield put(Actions.createCompanyFailed(message ?? GetErrorMessageFromStatus(response)));
        } else {
            yield put(Actions.createCompanyFailed(GetErrorMessageFromStatus(response)));
        }
    } catch (error) {
        yield put(Actions.createCompanyFailed(GetErrorMessageFromStatus(error)));
    }
}

function* editCompanyDetails({ payload: { companyDetails } }) {
    const user = getLoggedInUser();
    const data = new FormData();
    data.append('Id', companyDetails.id);
    data.append('AddressLine1', companyDetails.AddressLine1);
    data.append('AddressLine2', companyDetails.AddressLine2);
    data.append('Country', companyDetails.Country);
    data.append('Email', companyDetails.Email);
    data.append('Name', companyDetails.Name);
    data.append('File', companyDetails.File);

    try {
        const response = yield call(
            fetchPostMethodWithFormData,
            `${companyApiServiceUrl}/CreateCompany`,
            user.token,
            data
        );
        if (response.status === 200) {
            const { successMessage, status } = yield response.data
                .then((response) => ({ successMessage: response }))
                .catch(() => ({ status: 500 }));
            if (successMessage) {
                yield put(Actions.editCompanyDetailsSuccess(companyDetails, successMessage));
            } else {
                yield put(Actions.editCompanyDetailsFailed(GetErrorMessageFromStatus({ status: status })));
            }
        } else if (response.status === 400) {
            const { message } = yield response.data.then((response) => ({ message: response.message }));
            yield put(Actions.editCompanyDetailsFailed(message ?? GetErrorMessageFromStatus(response)));
        } else {
            yield put(Actions.editCompanyDetailsFailed(GetErrorMessageFromStatus(response)));
        }
    } catch (error) {
        yield put(Actions.editCompanyDetailsFailed(GetErrorMessageFromStatus(error)));
    }
}

// Company clients.
function* loadCompanyClients() {
    try {
        const user = getLoggedInUser();
        const response = yield call(
            fetchGetMethod,
            `${companyApiServiceUrl}/GetClientsDetails`,
            user.token
        );
        if (response.status === 200) {
            const { companyClients, status } = yield response.data
                .then((response) => ({ companyClients: response }))
                .catch(() => ({ status: 500 }));
            if (companyClients) {
                yield put(Actions.loadCompanyClientsSuccess(companyClients));
            } else {
                yield put(Actions.loadCompanyClientsFailed(GetErrorMessageFromStatus({ status: status })));
            }
        } else if (response.status === 400) {
            const { message } = yield response.data.then((response) => ({ message: response.message }));
            yield put(Actions.loadCompanyClientsFailed(message ?? GetErrorMessageFromStatus(response)));
        } else {
            yield put(Actions.loadCompanyClientsFailed(GetErrorMessageFromStatus(response)));
        }
    } catch (error) {
        yield put(Actions.loadCompanyClientsFailed(GetErrorMessageFromStatus(error)));
    }
}

function* loadCompanyMainClient({ payload: { mainClientId }}) {
    try {
        const user = getLoggedInUser();
        const response = yield call(
            fetchGetMethod,
            `${companyApiServiceUrl}/GetMainClientById?id=${mainClientId}`,
            user.token
        );
        if (response.status === 200) {
            const { profile, status } = yield response.data
                .then((response) => ({ profile: response }))
                .catch(() => ({ status: 500 }))
            if (profile) {
                yield put(Actions.loadCompanyMainClientSuccess(profile));
            } else {
                yield put(Actions.loadCompanyMainClientFailed(GetErrorMessageFromStatus({ status: status })));
            }
        } else {
            yield put(Actions.loadCompanyMainClientFailed(GetErrorMessageFromStatus(response)));
        }
    } catch (error) {
        yield put(Actions.loadCompanyMainClientFailed(GetErrorMessageFromStatus(error)));
    }
}

function* saveCompanyMainClient({ payload: {
    id, name, city, state, country, codeId, groupId, contactEmail, contactName, logo
 } }) {
    const user = getLoggedInUser();
    const data = new FormData();
    data.append('Id', id);
    data.append('Name', name);
    data.append('City', city);
    data.append('State', state);
    data.append('Country', country);
    data.append('CodeId', codeId);
    data.append('GroupId', groupId);
    data.append('ContactEmail', contactEmail);
    data.append('ContactName', contactName);
    data.append('Logo', logo);
    try {
        const response = yield call(
            fetchPostMethodWithFormData,
            `${companyApiServiceUrl}/SaveCompanyMainClient`,
            user.token,
            data
        );
        if (response.status === 200) {
            const { profile, status } = yield response.data
                .then((response) => ({ profile: response }))
                .catch(() => ({ status: 500 }))
            if (profile) {
                yield put(Actions.saveCompanyMainClientSuccess(profile));
            } else {
                yield put(Actions.saveCompanyMainClientFailed(GetErrorMessageFromStatus({ status: status })));
            }
        } else {
            yield put(Actions.saveCompanyMainClientFailed(GetErrorMessageFromStatus(response)));
        }
    } catch (error) {
        yield put(Actions.saveCompanyMainClientFailed(GetErrorMessageFromStatus(error)));
    }

}

// Company users.
function* loadCompanyUsers() {
    try {
        const user = getLoggedInUser();
        const response = yield call(
            fetchGetMethod,
            `${companyApiServiceUrl}/Users`,
            user.token
        );
        if (response.status === 200) {
            const { companyListUsers, status } = yield response.data
                .then((response) => ({ companyListUsers: response.comapanyListUsers }))
                .catch(() => ({ status: 500 }));
            if (companyListUsers) {
                yield put(Actions.loadCompanyUsersSuccess(companyListUsers));
            } else {
                yield put(Actions.loadCompanyUsersFailed(GetErrorMessageFromStatus({ status: status })));
            }
        } else if (response.status === 400) {
            const { message } = yield response.data.then((response) => ({ message: response.message }));
            yield put(Actions.loadCompanyUsersFailed(message ?? GetErrorMessageFromStatus(response)));
        } else {
            yield put(Actions.loadCompanyUsersFailed(GetErrorMessageFromStatus(response)));
        }
    } catch (error) {
        yield put(Actions.loadCompanyUsersFailed(GetErrorMessageFromStatus(error)));
    }
}

function* inviteUserToCompany({ payload: { userEmail } }) {
    try {
        const user = getLoggedInUser();
        const body = {
            Email: userEmail
        };
        const response = yield call(
            fetchPostMethod,
            `${companyApiServiceUrl}/InviteUser`,
            user.token,
            body,
            /* withoutResponseBody */ false
        );
        if (response.status === 200) {
            const { successMessage } = yield response.data.then((response) => ({ successMessage: response.message }));
            if (successMessage) {
                yield put(Actions.inviteUserToCompanySuccess(successMessage));
            } else {
                yield put(Actions.inviteUserToCompanyFailed(GetErrorMessageFromStatus({ status: 500 })));
            }
        } else if (response.status === 400) {
            const { message } = yield response.data.then((response) => ({ message: response.message }));
            yield put(Actions.inviteUserToCompanyFailed(message ?? GetErrorMessageFromStatus(response)));
        } else {
            yield put(Actions.inviteUserToCompanyFailed(GetErrorMessageFromStatus(response)));
        }
    } catch (error) {
        yield put(Actions.inviteUserToCompanyFailed(GetErrorMessageFromStatus(error)));
    }
}

function* removeUserManagerFlag({ payload: { userId } }) {
    try {
        const user = getLoggedInUser();
        const body = {
            UserId: userId
        };
        const response = yield call(
            fetchPostMethod,
            `${companyApiServiceUrl}/RemoveManagerFlag`,
            user.token,
            body,
            /* withoutResponseBody */ false
        );
        if (response.status === 200) {
            const { successMessage } = yield response.data.then((response) => ({ successMessage: response.message }));
            if (successMessage) {
                yield put(Actions.removeUserManagerFlagSuccess(successMessage));
            } else {
                yield put(Actions.removeUserManagerFlagFailed(GetErrorMessageFromStatus({ status: 500 })));
            }
        } else if (response.status === 400) {
            const { message } = yield response.data.then((response) => ({ message: response.message }));
            yield put(Actions.removeUserManagerFlagFailed(message ?? GetErrorMessageFromStatus(response)));
        } else {
            yield put(Actions.removeUserManagerFlagFailed(GetErrorMessageFromStatus(response)));
        }
    } catch (error) {
        yield put(Actions.removeUserManagerFlagFailed(GetErrorMessageFromStatus(error)));
    }
}

function* makeUserManager({ payload: { userId } }) {
    try {
        const user = getLoggedInUser();
        const body = {
            UserId: userId
        };
        const response = yield call(
            fetchPostMethod,
            `${companyApiServiceUrl}/MakeUserManager`,
            user.token,
            body,
            /* withoutResponseBody */ false
        );
        if (response.status === 200) {
            const { successMessage } = yield response.data.then((response) => ({ successMessage: response.message }));
            if (successMessage) {
                yield put(Actions.makeUserManagerSuccess(successMessage));
            } else {
                yield put(Actions.makeUserManagerFailed(GetErrorMessageFromStatus({ status: 500 })));
            }
        } else if (response.status === 400) {
            const { message } = yield response.data.then((response) => ({ message: response.message }));
            yield put(Actions.makeUserManagerFailed(message ?? GetErrorMessageFromStatus(response)));
        } else {
            yield put(Actions.makeUserManagerFailed(GetErrorMessageFromStatus(response)));
        }
    } catch (error) {
        yield put(Actions.makeUserManagerFailed(GetErrorMessageFromStatus(error)));
    }
}

function* associateLicense({ payload: { userId, planId } }) {
    try {
        const user = getLoggedInUser();
        const body = {
            UserId: userId,
            PlanId: planId,
        };
        const response = yield call(
            fetchPostMethod,
            `${companyApiServiceUrl}/AssociateLicense`,
            user.token,
            body,
            /* withoutResponseBody */ false
        );
        if (response.status === 200) {
            const { successMessage } = yield response.data.then((response) => ({ successMessage: response.message }));
            if (successMessage) {
                yield put(Actions.associateLicenseSuccess(successMessage));
            } else {
                yield put(Actions.associateLicenseFailed(GetErrorMessageFromStatus({ status: 500 })));
            }
        } else if (response.status === 400) {
            const { message } = yield response.data.then((response) => ({ message: response.message }));
            yield put(Actions.associateLicenseFailed(message ?? GetErrorMessageFromStatus(response)));
        } else {
            yield put(Actions.associateLicenseFailed(GetErrorMessageFromStatus(response)));
        }
    } catch (error) {
        yield put(Actions.associateLicenseFailed(GetErrorMessageFromStatus(error)));
    }
}

function* cancelLicense({ payload: { userId } }) {
    try {
        const user = getLoggedInUser();
        const body = {
            UserId: userId
        };
        const response = yield call(
            fetchPostMethod,
            `${companyApiServiceUrl}/CancelLicense`,
            user.token,
            body,
            /* withoutResponseBody */ false
        );
        if (response.status === 200) {
            const { successMessage } = yield response.data.then((response) => ({ successMessage: response.message }));
            if (successMessage) {
                yield put(Actions.cancelLicenseSuccess(successMessage));
            } else {
                yield put(Actions.cancelLicenseFailed(GetErrorMessageFromStatus({ status: 500 })));
            }
        } else if (response.status === 400) {
            const { message } = yield response.data.then((response) => ({ message: response.message }));
            yield put(Actions.cancelLicenseFailed(message ?? GetErrorMessageFromStatus(response)));
        } else {
            yield put(Actions.cancelLicenseFailed(GetErrorMessageFromStatus(response)));
        }
    } catch (error) {
        yield put(Actions.cancelLicenseFailed(GetErrorMessageFromStatus(error)));
    }
}

function* removeUserFromOrganization({ payload: { userId } }) {
    try {
        const user = getLoggedInUser();
        const body = {
            UserId: userId
        };
        const response = yield call(
            fetchPostMethod,
            `${companyApiServiceUrl}/RemoveUserFromOrganization`,
            user.token,
            body,
            /* withoutResponseBody */ false
        );
        if (response.status === 200) {
            const { successMessage } = yield response.data.then((response) => ({ successMessage: response.message }));
            if (successMessage) {
                yield put(Actions.removeUserFromOrganizationSuccess(successMessage));
            } else {
                yield put(Actions.removeUserFromOrganizationFailed(GetErrorMessageFromStatus({ status: 500 })));
            }
        } else if (response.status === 400) {
            const { message } = yield response.data.then((response) => ({ message: response.message }));
            yield put(Actions.removeUserFromOrganizationFailed(message ?? GetErrorMessageFromStatus(response)));
        } else {
            yield put(Actions.removeUserFromOrganizationFailed(GetErrorMessageFromStatus(response)));
        }
    } catch (error) {
        yield put(Actions.removeUserFromOrganizationFailed(GetErrorMessageFromStatus(error)));
    }
}

// Company Dashboard.
function* loadCompanyDashboard() {
    try {
        const user = getLoggedInUser();
        const response = yield call(
            fetchGetMethod,
            `${dashboardApiServiceUrl}/CompanyDashboard`,
            user.token
        );
        if (response.status === 200) {
            const {
                isOwner,
                vpoTotalImplementation,
                vpoTotalForecast,
                vpoTotalProposition,
                totalClients,
                totalValueProposition,
                totalPortifolioAmount,
                totalFundamentals,
                valuePropositionSummary,
                status
            } = yield response.data.then((response) => {
                return (response.status === 200)
                    ? (
                        {
                            isOwner: response.isOwner,
                            vpoTotalImplementation: response.vpoTotalImplementation,
                            vpoTotalForecast: response.vpoTotalForecast,
                            vpoTotalProposition: response.vpoTotalProposition,
                            totalClients: response.totalClients,
                            totalValueProposition: response.totalValueProposition,
                            totalPortifolioAmount: response.totalPortifolioAmount,
                            totalFundamentals: response.totalFundamentals,
                            valuePropositionSummary: response.valuePropositionSummary,
                        }
                    ) : { status: response.status };
            }).catch(() => ({ status: 500 }));
            if (
                vpoTotalImplementation && vpoTotalForecast && vpoTotalProposition
                && typeof totalClients !== 'undefined' && typeof totalValueProposition !== 'undefined' 
                && valuePropositionSummary
            ) {
                yield put(Actions.loadCompanyDashboardDataSuccess(
                    isOwner,
                    vpoTotalImplementation,
                    vpoTotalForecast,
                    vpoTotalProposition,
                    totalClients,
                    totalValueProposition,
                    totalPortifolioAmount,
                    totalFundamentals,
                    valuePropositionSummary
                )
                );
            } else {
                yield put(Actions.loadCompanyDashboardDataFailed(GetErrorMessageFromStatus({ status: status })));
            }
        } else {
            yield put(Actions.loadCompanyDashboardDataFailed(GetErrorMessageFromStatus(response)));
        }
    } catch (error) {
        yield put(Actions.loadCompanyDashboardDataFailed(GetErrorMessageFromStatus(error)));
    }
}

// Value proposition.
function* loadCompanyValuePropositions() {
    try {
        const user = getLoggedInUser();
        const response = yield call(
            fetchGetMethod,
            `${companyApiServiceUrl}/GetValuePropositions`,
            user.token
        );
        if (response.status === 200) {
            const { companyVpList } = yield response.data.then(
                (response) => ({ companyVpList: { ...response.companyValueProprosition, users: response.users } })
            );
            if (companyVpList) {
                yield put(Actions.loadCompanyValuePropositionsSuccess(companyVpList));
            } else {
                yield put(Actions.loadCompanyValuePropositionsFailed(GetErrorMessageFromStatus({ status: 500 })));
            }
        } else if (response.status === 400) {
            const { message } = yield response.data.then((response) => ({ message: response.message }));
            yield put(Actions.loadCompanyValuePropositionsFailed(message ?? GetErrorMessageFromStatus(response)));
        } else {
            yield put(Actions.loadCompanyValuePropositionsFailed(GetErrorMessageFromStatus(response)));
        }
    } catch (error) {
        yield put(Actions.loadCompanyValuePropositionsFailed(GetErrorMessageFromStatus(error)));
    }
}

function* getCompanyValuePropositionDetails({ payload: { vpId } }) {
    try {
        const user = getLoggedInUser();
        const body = { ValuePropositionId: vpId };
        const response = yield call(
            fetchPostMethod,
            `${companyApiServiceUrl}/VisualizeValueProposition`,
            user.token,
            body,
            /* withoutResponseBody */ false
        );
        if (response.status === 200) {
            const { companyVpDetails } = yield response.data.then((response) => ({ companyVpDetails: response })
            );
            if (companyVpDetails) {
                yield put(Actions.getCompanyValuePropositionDetailsSuccess(vpId, companyVpDetails));
            } else {
                yield put(Actions.getCompanyValuePropositionDetailsFailed(GetErrorMessageFromStatus({ status: 500 })));
            }
        } else if (response.status === 400) {
            const { message } = yield response.data.then((response) => ({ message: response.message }));
            yield put(Actions.getCompanyValuePropositionDetailsFailed(message ?? GetErrorMessageFromStatus(response)));
        } else {
            yield put(Actions.getCompanyValuePropositionDetailsFailed(GetErrorMessageFromStatus(response)));
        }
    } catch (error) {
        yield put(Actions.getCompanyValuePropositionDetailsFailed(GetErrorMessageFromStatus(error)));
    }
}

// Licenses
function* changeCompanyUserLicenses({ payload: { numberOfLicenses, licenseType, isUpgrade, quantity }}) {
    try {
        const user = getLoggedInUser();
        const body = {
            NumberOfLicenses: numberOfLicenses,
            LicenseType: licenseType,
        };
        const response = yield call(
            fetchPostMethod,
            `${companyApiServiceUrl}/UpgradeCompanyUserLicenses`,
            user.token,
            body,
            /* withoutResponseBody */ false
        );
        if (response.status === 200) {
            const { license } = yield response.data.then((response) => ({ license: response }));
            if (response) {
                yield put(Actions.changeCompanyUserLicensesSuccess({...license, isUpgrade, quantity }));
            } else {
                yield put(Actions.changeCompanyUserLicensesFailed(GetErrorMessageFromStatus({ status: 500 })));
            }
        } else if (response.status === 400) {
            const { message } = yield response.data.then((response) => ({ message: response.message }));
            yield put(Actions.changeCompanyUserLicensesFailed(message ?? GetErrorMessageFromStatus(response)));
        } else {
            yield put(Actions.changeCompanyUserLicensesFailed(GetErrorMessageFromStatus(response)));
        }
    } catch (error) {
        yield put(Actions.changeCompanyUserLicensesFailed(GetErrorMessageFromStatus(error)));
    }
}

function* userLicenseSuccessStrip() {
    try {
        const user = getLoggedInUser();
        const response = yield call(
            fetchGetMethod,
            `${companyApiServiceUrl}/UserLicenseSuccess`,
            user.token,
        );
        if (response.status === 200) {
            yield put(Actions.userLicenseSuccessStripSuccess('Congratulations!! You\'ve purchased more licenses.'));
        } else if (response.status === 400) {
            const { message } = yield response.data.then((response) => ({ message: response.message }));
            yield put(Actions.userLicenseSuccessStripFailed(message ?? GetErrorMessageFromStatus(response)));
        } else {
            yield put(Actions.userLicenseSuccessStripFailed(GetErrorMessageFromStatus(response)));
        }
    } catch (error) {
        yield put(Actions.userLicenseSuccessStripFailed(GetErrorMessageFromStatus(error)));
    }
}

function* upgradeLicenses({ payload: { numberOfLicenses, licenseType }}) {
    try {
        const user = getLoggedInUser();
        const body = {
            NumberOfLicenses: numberOfLicenses,
            LicenseType: licenseType,
        };
        const response = yield call(
            fetchPostMethod,
            `${companyApiServiceUrl}/UpgradeLicenses`,
            user.token,
            body,
            /* withoutResponseBody */ false
        );
        if (response.status === 200) {
            const { successMessage } = yield response.data.then((response) => ({ successMessage: response.message }));
            if (successMessage) {
                yield put(Actions.upgradeLicensesSuccess(successMessage));
            } else {
                yield put(Actions.upgradeLicensesFailed(GetErrorMessageFromStatus({ status: 500 })));
            }
        } else if (response.status === 400) {
            const { message } = yield response.data.then((response) => ({ message: response.message }));
            yield put(Actions.upgradeLicensesFailed(message ?? GetErrorMessageFromStatus(response)));
        } else {
            yield put(Actions.upgradeLicensesFailed(GetErrorMessageFromStatus(response)));
        }
    } catch (error) {
        yield put(Actions.upgradeLicensesFailed(GetErrorMessageFromStatus(error)));
    }
}

function* downgradeLicenses({ payload: { numberOfLicenses, licenseType }}) {
    try {
        const user = getLoggedInUser();
        const body = {
            NumberOfLicenses: numberOfLicenses,
            LicenseType: licenseType,
        };
        const response = yield call(
            fetchPostMethod,
            `${companyApiServiceUrl}/DowngradeLicenses`,
            user.token,
            body,
            /* withoutResponseBody */ false
        );
        if (response.status === 200) {
            const { successMessage } = yield response.data.then((response) => ({ successMessage: response.message }));
            if (successMessage) {
                yield put(Actions.downgradeLicensesSuccess(successMessage));
            } else {
                yield put(Actions.downgradeLicensesFailed(GetErrorMessageFromStatus({ status: 500 })));
            }
        } else if (response.status === 400) {
            const { message } = yield response.data.then((response) => ({ message: response.message }));
            yield put(Actions.downgradeLicensesFailed(message ?? GetErrorMessageFromStatus(response)));
        } else {
            yield put(Actions.downgradeLicensesFailed(GetErrorMessageFromStatus(response)));
        }
    } catch (error) {
        yield put(Actions.downgradeLicensesFailed(GetErrorMessageFromStatus(error)));
    }
}

// Invitations
function* getMyInvitations() {
    try {
        const user = getLoggedInUser();
        const response = yield call(
            fetchGetMethod,
            `${companyApiServiceUrl}/GetMyInvitations`,
            user.token
        );
        if (response.status === 200) {
            const { invitations } = yield response.data.then(
                (response) => ({ invitations: response })
            );
// const invitations2 = [
//     { companyId: 1, companyName: "Main Client Test", imagePath: "https://slemptm20.blob.core.windows.net/company-image/3bb5eadd-6238-4781-85f4-81c28f6f7c37_Main%20Client%20Test%2011adb230e-4d84-49ab-b044-09510c4c964aRectangle%205.png" }
// ]
            if (invitations) {
                yield put(Actions.getMyInvitationsSuccess(invitations));
            } else {
                yield put(Actions.getMyInvitationsFailed(GetErrorMessageFromStatus({ status: 500 })));
            }
        } else if (response.status === 400) {
            const { message } = yield response.data.then((response) => ({ message: response.message }));
            yield put(Actions.getMyInvitationsFailed(message ?? GetErrorMessageFromStatus(response)));
        } else {
            yield put(Actions.getMyInvitationsFailed(GetErrorMessageFromStatus(response)));
        }
    } catch (error) {
        yield put(Actions.getMyInvitationsFailed(GetErrorMessageFromStatus(error)));
    }
}

function* acceptInvitation({ payload: { companyId }}) {
    try {
        const user = getLoggedInUser();
        const body = {
            CompanyId: companyId,
        };
        const response = yield call(
            fetchPostMethod,
            `${companyApiServiceUrl}/AcceptInvitation`,
            user.token,
            body,
            /* withoutResponseBody */ false
        );
        if (response.status === 200) {
            const { successMessage, token } = yield response.data
                .then((response) => ({ successMessage: response.message, token: response.token }));
            if (successMessage && token) {
                yield put(Actions.acceptInvitationSuccess(successMessage, token, companyId));
            } else {
                yield put(Actions.acceptInvitationFailed(GetErrorMessageFromStatus({ status: 500 })));
            }
        } else if (response.status === 400) {
            const { message } = yield response.data.then((response) => ({ message: response.message }));
            yield put(Actions.acceptInvitationFailed(message ?? GetErrorMessageFromStatus(response)));
        } else {
            yield put(Actions.acceptInvitationFailed(GetErrorMessageFromStatus(response)));
        }
    } catch (error) {
        yield put(Actions.acceptInvitationFailed(GetErrorMessageFromStatus(error)));
    }
}

function* rejectInvitation({ payload: { companyId }}) {
    try {
        const user = getLoggedInUser();
        const body = {
            CompanyId: companyId,
        };
        const response = yield call(
            fetchPostMethod,
            `${companyApiServiceUrl}/RejectInvitation`,
            user.token,
            body,
            /* withoutResponseBody */ false
        );
        if (response.status === 200) {
            const { successMessage } = yield response.data.then((response) => ({ successMessage: response.message }));
            if (successMessage) {
                yield put(Actions.rejectInvitationSuccess(successMessage, companyId));
            } else {
                yield put(Actions.rejectInvitationFailed(GetErrorMessageFromStatus({ status: 500 })));
            }
        } else if (response.status === 400) {
            const { message } = yield response.data.then((response) => ({ message: response.message }));
            yield put(Actions.rejectInvitationFailed(message ?? GetErrorMessageFromStatus(response)));
        } else {
            yield put(Actions.rejectInvitationFailed(GetErrorMessageFromStatus(response)));
        }
    } catch (error) {
        yield put(Actions.rejectInvitationFailed(GetErrorMessageFromStatus(error)));
    }
}

export function* watchLoadCompanyDetails(): any {
    yield takeLatest(ActionsType.LOAD_COMPANY_DETAILS, loadCompanyDetails);
}

export function* watchCreateCompany(): any {
    yield takeLatest(ActionsType.CREATE_COMPANY, createCompany);
}

export function* watchEditCompanyDetails(): any {
    yield takeLatest(ActionsType.EDIT_COMPANY_DETAILS, editCompanyDetails);
}

export function* watchLoadCompanyClients(): any {
    yield takeLatest(ActionsType.LOAD_COMPANY_CLIENTS, loadCompanyClients);
}

export function* watchLoadCompanyMainClient(): any {
    yield takeLatest(ActionsType.LOAD_COMPANY_MAIN_CLIENT, loadCompanyMainClient)
}

export function* watchSaveCompanyMainClient(): any {
    yield takeLatest(ActionsType.SAVE_COMPANY_MAIN_CLIENT, saveCompanyMainClient);
}

export function* watchLoadCompanyUsers(): any {
    yield takeLatest(ActionsType.LOAD_COMPANY_USERS, loadCompanyUsers);
}

export function* watchInviteUserToCompany(): any {
    yield takeLatest(ActionsType.INVITE_USER_TO_COMPANY, inviteUserToCompany);
}

export function* watchMakeUserManager(): any {
    yield takeLatest(ActionsType.MAKE_USER_MANAGER, makeUserManager);
}

export function* watchRemoveUserManagerFlag(): any {
    yield takeLatest(ActionsType.REMOVE_USER_MANAGER_FLAG, removeUserManagerFlag);
}

export function* watchAssociateLicense(): any {
    yield takeLatest(ActionsType.ASSOCIATE_LICENSE, associateLicense);
}

export function* watchCancelLicense(): any {
    yield takeLatest(ActionsType.CANCEL_LICENSE, cancelLicense);
}

export function* watchRemoveUserFromOrganization(): any {
    yield takeLatest(ActionsType.REMOVE_USER_FROM_ORGANIZATION, removeUserFromOrganization);
}

export function* watchLoadCompanyDashboard(): any {
    yield takeLatest(ActionsType.LOAD_COMPANY_DASHBOARD_DATA, loadCompanyDashboard);
}

export function* watchLoadCompanyValuePropositions(): any {
    yield takeLatest(ActionsType.LOAD_COMPANY_VALUE_PROPOSITIONS, loadCompanyValuePropositions);
}

export function* watchGetCompanyValuePropositionDetails(): any {
    yield takeLatest(ActionsType.GET_COMPANY_VALUE_PROPOSITION_DETAILS, getCompanyValuePropositionDetails);
}

export function* watchUpgradeLicenses(): any {
    yield takeLatest(ActionsType.COMPANY_UPGRADE_LICENSES, upgradeLicenses);
}

export function* watchDowngradeLicenses(): any {
    yield takeLatest(ActionsType.COMPANY_DOWNGRADE_LICENSES, downgradeLicenses);
}

export function* watchChangeCompanyUserLicenses(): any {
    yield takeLatest(ActionsType.CHANGE_COMPANY_USER_LICENSES, changeCompanyUserLicenses);
}

export function* watchUserLicenseSuccessStrip(): any {
    yield takeLatest(ActionsType.COMPANY_USER_LICENSE_SUCCESS_STRIPE, userLicenseSuccessStrip);
}

export function* watchGetMyInvitations(): any {
    yield takeLatest(ActionsType.GET_MY_INVITATIONS, getMyInvitations);
}

export function* watchAcceptInvitation(): any {
    yield takeLatest(ActionsType.ACCEPT_INVITATION, acceptInvitation);
}

export function* watchRejectInvitation(): any {
    yield takeLatest(ActionsType.REJECT_INVITATION, rejectInvitation);
}

function* companySaga(): any {
    yield all([
        fork(watchAssociateLicense),
        fork(watchCancelLicense),
        fork(watchCreateCompany),
        fork(watchDowngradeLicenses),
        fork(watchEditCompanyDetails),
        fork(watchGetCompanyValuePropositionDetails),
        fork(watchInviteUserToCompany),
        fork(watchLoadCompanyDashboard),
        fork(watchLoadCompanyDetails),
        fork(watchLoadCompanyClients),
        fork(watchLoadCompanyMainClient),
        fork(watchSaveCompanyMainClient),
        fork(watchLoadCompanyUsers),
        fork(watchLoadCompanyValuePropositions),
        fork(watchMakeUserManager),
        fork(watchRemoveUserFromOrganization),
        fork(watchRemoveUserManagerFlag),
        fork(watchUpgradeLicenses),
        fork(watchChangeCompanyUserLicenses),
        fork(watchUserLicenseSuccessStrip),
        fork(watchGetMyInvitations),
        fork(watchAcceptInvitation),
        fork(watchRejectInvitation),
    ]);
}

export default companySaga;
