import * as ActionsType from './constants';
import { ClientAction } from './actions';

const INIT_STATE = {
    clients: null,
    loading: false,
    error: null,
    groupLoading: false,
    groupError: null,
    groups: [],
    profileLoading: false,
    profileError: null,
    profile: null,
    saving: false,
    deleting: false,
    changingActiveState: false,
};

type State = {
    user?: {} | null;
    loading?: boolean;
};

const Client = (state: State = INIT_STATE, action: ClientAction) => {
    switch (action.type) {
        case ActionsType.CLEAR_CLIENT_LIST:
            return { ...state, clients: null };
        case ActionsType.CLEAR_CLIENT_PROFILE:
            return { ...state, profile: null };
        case ActionsType.LOAD_CLIENT_LIST:
            return { ...state, clients: [], loading: true, error: null };
        case ActionsType.LOAD_CLIENT_LIST_SUCCESS:
            return { ...state, clients: action.payload, loading: false };
        case ActionsType.LOAD_CLIENT_LIST_FAILED:
            return { ...state, error: action.payload, loading: false };
        case ActionsType.LOAD_GROUP_OPTIONS:
            return { ...state, groupLoading: true, groupError: null };
        case ActionsType.LOAD_GROUP_OPTIONS_SUCCESS:
            return { ...state, groups: action.payload, groupLoading: false };
        case ActionsType.LOAD_GROUP_OPTIONS_FAILED:
            return { ...state, groupError: action.payload, groupLoading: false };
        case ActionsType.LOAD_CLIENT_PROFILE:
            return { ...state, profileLoading: true, profileError: null, error: null };
        case ActionsType.LOAD_CLIENT_PROFILE_SUCCESS:
            return { ...state, profile: action.payload, profileLoading: false };
        case ActionsType.LOAD_CLIENT_PROFILE_FAILED:
            return { ...state, profileError: action.payload, profileLoading: false };
        case ActionsType.SAVE_CLIENT_PROFILE:
            return { ...state, saving: true, error: null };
        case ActionsType.SAVE_CLIENT_PROFILE_SUCCESS:
            return { ...state, saving: false, profile: action.payload };
        case ActionsType.SAVE_CLIENT_PROFILE_FAILED:
            return { ...state, saving: false, error: action.payload };
        case ActionsType.CHANGE_CLIENT_ACTIVE_STATE:
            return { ...state, changingActiveState: true, error: null };
        case ActionsType.CHANGE_CLIENT_ACTIVE_STATE_SUCCESS:
            return { ...state, changingActiveState: false};
        case ActionsType.CHANGE_CLIENT_ACTIVE_STATE_FAILED:
            return { ...state, changingActiveState: false, error: action.payload };
        case ActionsType.HARD_DELETE_CLIENT:
            return { ...state, deleting: true, error: null };
        case ActionsType.HARD_DELETE_CLIENT_SUCCESS:
            return { ...state, deleting: false };
        case ActionsType.HARD_DELETE_CLIENT_FAILED:
            return { ...state, deleting: false, error: action.payload };
        default:
            return { ...state };
    }
}

export default Client;
