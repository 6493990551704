import {
    CHANGE_CLIENT_ACTIVE_STATE,
    CHANGE_CLIENT_ACTIVE_STATE_FAILED,
    CHANGE_CLIENT_ACTIVE_STATE_SUCCESS,
    CLEAR_CLIENT_LIST,
    CLEAR_CLIENT_PROFILE,
    HARD_DELETE_CLIENT,
    HARD_DELETE_CLIENT_FAILED,
    HARD_DELETE_CLIENT_SUCCESS,
    LOAD_CLIENT_LIST,
    LOAD_CLIENT_LIST_FAILED,
    LOAD_CLIENT_LIST_SUCCESS,
    LOAD_CLIENT_PROFILE,
    LOAD_CLIENT_PROFILE_FAILED,
    LOAD_CLIENT_PROFILE_SUCCESS,
    LOAD_GROUP_OPTIONS,
    LOAD_GROUP_OPTIONS_FAILED,
    LOAD_GROUP_OPTIONS_SUCCESS,
    SAVE_CLIENT_PROFILE,
    SAVE_CLIENT_PROFILE_FAILED,
    SAVE_CLIENT_PROFILE_SUCCESS
} from './constants';

export type ClientAction = {
    type: string;
    payload: {} | string;
};

export const clearClientList = (): ClientAction => ({
    type: CLEAR_CLIENT_LIST,
    payload: null,
})

export const loadClientList = (): ClientAction => ({
    type: LOAD_CLIENT_LIST,
    payload: null,
});

export const loadClientListSuccess = (clients): ClientAction => ({
    type: LOAD_CLIENT_LIST_SUCCESS,
    payload: clients,
});

export const loadClientListFailed = (error: string): ClientAction => ({
    type: LOAD_CLIENT_LIST_FAILED,
    payload: error,
});

export const loadGroupOptions = (token ?: string): ClientAction => ({
    type: LOAD_GROUP_OPTIONS,
    payload: { token },
});

export const loadGroupOptionsSuccess = (groupOptions): ClientAction => ({
    type: LOAD_GROUP_OPTIONS_SUCCESS,
    payload: groupOptions,
});

export const loadGroupOptionsFailed = (error: string): ClientAction => ({
    type: LOAD_GROUP_OPTIONS_FAILED,
    payload: error,
});

export const clearClientProfile = (): ClientAction => ({
    type: CLEAR_CLIENT_PROFILE,
    payload: null,
})

export const loadClientProfile = (clientId): ClientAction => ({
    type: LOAD_CLIENT_PROFILE,
    payload: { clientId },
});

export const loadClientProfileSuccess = (profile): ClientAction => ({
    type: LOAD_CLIENT_PROFILE_SUCCESS,
    payload: profile,
});

export const loadClientProfileFailed = (error: string): ClientAction => ({
    type: LOAD_CLIENT_PROFILE_FAILED,
    payload: error,
});

export const saveClientProfile = (
    id, name, city, state, country, codeId, groupId, contactEmail, contactName, logo, mainClient
): ClientAction => ({
    type: SAVE_CLIENT_PROFILE,
    payload: {
        id, name, city, state, country, codeId, groupId, contactEmail, contactName, logo, mainClient
    },
});

export const saveClientProfileSuccess = (profile): ClientAction => ({
    type: SAVE_CLIENT_PROFILE_SUCCESS,
    payload: profile,
});

export const saveClientProfileFailed = (error: string): ClientAction => ({
    type: SAVE_CLIENT_PROFILE_FAILED,
    payload: error,
});

export const changeClientActiveState = (clientId, isActive): ClientAction => ({
    type: CHANGE_CLIENT_ACTIVE_STATE,
    payload: { clientId, isActive },
});

export const changeClientActiveStateSuccess = (): ClientAction => ({
    type: CHANGE_CLIENT_ACTIVE_STATE_SUCCESS,
    payload: null,
});

export const changeClientActiveStateFailed = (error: string): ClientAction => ({
    type: CHANGE_CLIENT_ACTIVE_STATE_FAILED,
    payload: error,
});

export const hardDeleteClient = (clientId): ClientAction => ({
    type: HARD_DELETE_CLIENT,
    payload: { clientId },
});

export const hardDeleteClientSuccess = (): ClientAction => ({
    type: HARD_DELETE_CLIENT_SUCCESS,
    payload: null,
});

export const hardDeleteClientFailed = (error: string): ClientAction => ({
    type: HARD_DELETE_CLIENT_FAILED,
    payload: error,
});

