/* CLIENT LIST */
export const LOAD_CLIENT_LIST = 'LOAD_CLIENT_LIST';
export const LOAD_CLIENT_LIST_SUCCESS = 'LOAD_CLIENT_LIST_SUCCESS';
export const LOAD_CLIENT_LIST_FAILED = 'LOAD_CLIENT_LIST_FAILED';

export const CLEAR_CLIENT_LIST = 'CLEAR_CLIENT_LIST';
export const CLEAR_CLIENT_PROFILE = 'CLEAR_CLIENT_PROFILE';

/* ADD/EDIT CLIENT */
export const LOAD_GROUP_OPTIONS = 'LOAD_GROUP_OPTIONS';
export const LOAD_GROUP_OPTIONS_SUCCESS = 'LOAD_GROUP_OPTIONS_SUCCESS';
export const LOAD_GROUP_OPTIONS_FAILED = 'LOAD_GROUP_OPTIONS_FAILED';

export const LOAD_CLIENT_PROFILE = 'LOAD_CLIENT_PROFILE';
export const LOAD_CLIENT_PROFILE_SUCCESS = 'LOAD_CLIENT_PROFILE_SUCCESS';
export const LOAD_CLIENT_PROFILE_FAILED = 'LOAD_CLIENT_PROFILE_FAILED';

export const SAVE_CLIENT_PROFILE = 'SAVE_CLIENT_PROFILE';
export const SAVE_CLIENT_PROFILE_SUCCESS = 'SAVE_CLIENT_PROFILE_SUCCESS';
export const SAVE_CLIENT_PROFILE_FAILED = 'SAVE_CLIENT_PROFILE_FAILED';

export const CHANGE_CLIENT_ACTIVE_STATE = 'CHANGE_CLIENT_ACTIVE_STATE';
export const CHANGE_CLIENT_ACTIVE_STATE_SUCCESS = 'CHANGE_CLIENT_ACTIVE_STATE_SUCCESS';
export const CHANGE_CLIENT_ACTIVE_STATE_FAILED = 'CHANGE_CLIENT_ACTIVE_STATE_FAILED';

export const HARD_DELETE_CLIENT = 'HARD_DELETE_CLIENT';
export const HARD_DELETE_CLIENT_SUCCESS = 'HARD_DELETE_CLIENT_SUCCESS';
export const HARD_DELETE_CLIENT_FAILED = 'HARD_DELETE_CLIENT_FAILED';
