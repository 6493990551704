import { FollowersAction } from './actions';
import * as ActionType from './constants';

const INIT_STATE = {
    vpoTotalImplementation: null,
    vpoTotalForecast: null,
    vpoTotalProposition: null,
    valuePropositionSummary: null,
    totalClients: null,
    totalValueProposition: null,
    totalPortifolioAmount: null,
    totalFundamentals: null,
    loading: false,
    error: null
};

type State = {
    vpoTotalImplementation?: {},
    vpoTotalForecast?: {},
    vpoTotalProposition?: {},
    valuePropositionSummary?: {},
    totalClients?: number,
    totalValueProposition?: number,
    totalPortifolioAmount?: number,
    totalFundamentals?: number,
    loading?: Boolean,
    error?: String,
};

const Followers = (state: State = INIT_STATE, action: FollowersAction) => {
    switch (action.type) {
        case ActionType.LOAD_DASHBOARD_DATA:
            return { ...state, loading: true, error: null };
        case ActionType.LOAD_DASHBOARD_DATA_SUCCESS:
            return {
                ...state,
                loading: false,
                vpoTotalImplementation: action.payload.vpoTotalImplementation,
                vpoTotalForecast: action.payload.vpoTotalForecast,
                vpoTotalProposition: action.payload.vpoTotalProposition,
                valuePropositionSummary: action.payload.valuePropositionSummary,
                totalClients: action.payload.totalClients,
                totalValueProposition: action.payload.totalValueProposition,
                totalPortifolioAmount: action.payload.totalPortifolioAmount,
                totalFundamentals: action.payload.totalFundamentals,
            };
        case ActionType.LOAD_DASHBOARD_DATA_FAILED:
            return { ...state, loading: false, error: action.payload };
        default:
            return { ...state };
    }
}

export default Followers;
