import * as ActionType from './constants';

export type DashboardAction = {
    type: string;
    payload: {} | string;
};

export const loadDashboardData = (): DashboardAction => ({
    type: ActionType.LOAD_DASHBOARD_DATA,
    payload: null,
});

export const loadDashboardDataSuccess = (
    vpoTotalImplementation,
    vpoTotalForecast,
    vpoTotalProposition,
    totalClients,
    totalValueProposition,
    totalPortifolioAmount,
    totalFundamentals,
    valuePropositionSummary
): DashboardAction => ({
    type: ActionType.LOAD_DASHBOARD_DATA_SUCCESS,
    payload: {
        vpoTotalImplementation,
        vpoTotalForecast,
        vpoTotalProposition,
        totalClients,
        totalValueProposition,
        totalPortifolioAmount,
        totalFundamentals,
        valuePropositionSummary
    },
});

export const loadDashboardDataFailed = (error: string): DashboardAction => ({
    type: ActionType.LOAD_DASHBOARD_DATA_FAILED,
    payload: error,
});
