// General
export const CLEAR_COMPANY_MESSAGES = 'CLEAR_COMPANY_MESSAGES';

// Details
export const LOAD_COMPANY_DETAILS = 'LOAD_COMPANY_DETAILS';
export const LOAD_COMPANY_DETAILS_SUCCESS = 'LOAD_COMPANY_DETAILS_SUCCESS';
export const LOAD_COMPANY_DETAILS_FAILED = 'LOAD_COMPANY_DETAILS_FAILED';

export const EDIT_COMPANY_DETAILS = 'EDIT_COMPANY_DETAILS';
export const EDIT_COMPANY_DETAILS_SUCCESS = 'EDIT_COMPANY_DETAILS_SUCCESS';
export const EDIT_COMPANY_DETAILS_FAILED = 'EDIT_COMPANY_DETAILS_FAILED';

export const CREATE_COMPANY = 'CREATE_COMPANY';
export const CREATE_COMPANY_SUCCESS = 'CREATE_COMPANY_SUCCESS';
export const CREATE_COMPANY_FAILED = 'CREATE_COMPANY_FAILED';

// Clients
export const LOAD_COMPANY_CLIENTS = 'LOAD_COMPANY_CLIENTS';
export const LOAD_COMPANY_CLIENTS_SUCCESS = 'LOAD_COMPANY_CLIENTS_SUCCESS';
export const LOAD_COMPANY_CLIENTS_FAILED = 'LOAD_COMPANY_CLIENTS_FAILED';

export const LOAD_COMPANY_MAIN_CLIENT = 'LOAD_COMPANY_MAIN_CLIENT';
export const LOAD_COMPANY_MAIN_CLIENT_SUCCESS = 'LOAD_COMPANY_MAIN_CLIENT_SUCCESS';
export const LOAD_COMPANY_MAIN_CLIENT_FAILED = 'LOAD_COMPANY_MAIN_CLIENT_FAILED';

export const SAVE_COMPANY_MAIN_CLIENT = 'SAVE_COMPANY_MAIN_CLIENT';
export const SAVE_COMPANY_MAIN_CLIENT_SUCCESS = 'SAVE_COMPANY_MAIN_CLIENT_SUCCESS';
export const SAVE_COMPANY_MAIN_CLIENT_FAILED = 'SAVE_COMPANY_MAIN_CLIENT_FAILED';

// Users
export const LOAD_COMPANY_USERS = 'LOAD_COMPANY_USERS';
export const LOAD_COMPANY_USERS_SUCCESS = 'LOAD_COMPANY_USERS_SUCCESS';
export const LOAD_COMPANY_USERS_FAILED = 'LOAD_COMPANY_USERS_FAILED';

export const INVITE_USER_TO_COMPANY = 'INVITE_USER_TO_COMPANY';
export const INVITE_USER_TO_COMPANY_SUCCESS = 'INVITE_USER_TO_COMPANY_SUCCESS';
export const INVITE_USER_TO_COMPANY_FAILED = 'INVITE_USER_TO_COMPANY_FAILED';

export const REMOVE_USER_MANAGER_FLAG = 'REMOVE_USER_MANAGER_FLAG';
export const REMOVE_USER_MANAGER_FLAG_SUCCESS = 'REMOVE_USER_MANAGER_FLAG_SUCCESS';
export const REMOVE_USER_MANAGER_FLAG_FAILED = 'REMOVE_USER_MANAGER_FLAG_FAILED';

export const MAKE_USER_MANAGER = 'MAKE_USER_MANAGER';
export const MAKE_USER_MANAGER_SUCCESS = 'MAKE_USER_MANAGER_SUCCESS';
export const MAKE_USER_MANAGER_FAILED = 'MAKE_USER_MANAGER_FAILED';

export const ASSOCIATE_LICENSE = 'ASSOCIATE_LICENSE';
export const ASSOCIATE_LICENSE_SUCCESS = 'ASSOCIATE_LICENSE_SUCCESS';
export const ASSOCIATE_LICENSE_FAILED = 'ASSOCIATE_LICENSE_FAILED';

export const CANCEL_LICENSE = 'CANCEL_LICENSE';
export const CANCEL_LICENSE_SUCCESS = 'CANCEL_LICENSE_SUCCESS';
export const CANCEL_LICENSE_FAILED = 'CANCEL_LICENSE_FAILED';

export const REMOVE_USER_FROM_ORGANIZATION = 'REMOVE_USER_FROM_ORGANIZATION';
export const REMOVE_USER_FROM_ORGANIZATION_SUCCESS = 'REMOVE_USER_FROM_ORGANIZATION_SUCCESS';
export const REMOVE_USER_FROM_ORGANIZATION_FAILED = 'REMOVE_USER_FROM_ORGANIZATION_FAILED';

// Dashboard
export const LOAD_COMPANY_DASHBOARD_DATA = 'LOAD_COMPANY_DASHBOARD_DATA';
export const LOAD_COMPANY_DASHBOARD_DATA_SUCCESS = 'LOAD_COMPANY_DASHBOARD_DATA_SUCCESS';
export const LOAD_COMPANY_DASHBOARD_DATA_FAILED = 'LOAD_COMPANY_DASHBOARD_DATA_FAILED';

// Value proposition
export const LOAD_COMPANY_VALUE_PROPOSITIONS = 'LOAD_COMPANY_VALUE_PROPOSITIONS';
export const LOAD_COMPANY_VALUE_PROPOSITIONS_SUCCESS = 'LOAD_COMPANY_VALUE_PROPOSITIONS_SUCCESS';
export const LOAD_COMPANY_VALUE_PROPOSITIONS_FAILED = 'LOAD_COMPANY_VALUE_PROPOSITIONS_FAILED';

export const GET_COMPANY_VALUE_PROPOSITION_DETAILS = 'GET_COMPANY_VALUE_PROPOSITION_DETAILS';
export const GET_COMPANY_VALUE_PROPOSITION_DETAILS_SUCCESS = 'GET_COMPANY_VALUE_PROPOSITION_DETAILS_SUCCESS';
export const GET_COMPANY_VALUE_PROPOSITION_DETAILS_FAILED = 'GET_COMPANY_VALUE_PROPOSITION_DETAILS_FAILED';

// Licenses
export const COMPANY_UPGRADE_LICENSES = 'COMPANY_UPGRADE_LICENSES';
export const COMPANY_UPGRADE_LICENSES_SUCCESS = 'COMPANY_UPGRADE_LICENSES_SUCCESS';
export const COMPANY_UPGRADE_LICENSES_FAILED = 'COMPANY_UPGRADE_LICENSES_FAILED';

export const COMPANY_DOWNGRADE_LICENSES = 'COMPANY_DOWNGRADE_LICENSES';
export const COMPANY_DOWNGRADE_LICENSES_SUCCESS = 'COMPANY_DOWNGRADE_LICENSES_SUCCESS';
export const COMPANY_DOWNGRADE_LICENSES_FAILED = 'COMPANY_DOWNGRADE_LICENSES_FAILED';

export const CHANGE_COMPANY_USER_LICENSES = 'CHANGE_COMPANY_USER_LICENSES';
export const CHANGE_COMPANY_USER_LICENSES_SUCCESS = 'CHANGE_COMPANY_USER_LICENSES_SUCCESS';
export const CHANGE_COMPANY_USER_LICENSES_FAILED = 'CHANGE_COMPANY_USER_LICENSES_FAILED';

export const COMPANY_USER_LICENSE_SUCCESS_STRIPE = 'COMPANY_USER_LICENSE_SUCCESS_STRIPE';
export const COMPANY_USER_LICENSE_SUCCESS_STRIPE_SUCCESS = 'COMPANY_USER_LICENSE_SUCCESS_STRIPE_SUCCESS';
export const COMPANY_USER_LICENSE_SUCCESS_STRIPE_FAILED = 'COMPANY_USER_LICENSE_SUCCESS_STRIPE_FAILED';

export const CLEAR_COMPANY_USER_LICENSE = 'CLEAR_COMPANY_USER_LICENSE';

// Invites
export const GET_MY_INVITATIONS = 'GET_MY_INVITATIONS';
export const GET_MY_INVITATIONS_SUCCESS = 'GET_MY_INVITATIONS_SUCCESS';
export const GET_MY_INVITATIONS_FAILED = 'GET_MY_INVITATIONS_FAILED';

export const ACCEPT_INVITATION = 'ACCEPT_INVITATION';
export const ACCEPT_INVITATION_SUCCESS = 'ACCEPT_INVITATION_SUCCESS';
export const ACCEPT_INVITATION_FAILED = 'ACCEPT_INVITATION_FAILED';

export const REJECT_INVITATION = 'REJECT_INVITATION';
export const REJECT_INVITATION_SUCCESS = 'REJECT_INVITATION_SUCCESS';
export const REJECT_INVITATION_FAILED = 'REJECT_INVITATION_FAILED';
