import {
    CHANGE_VALUE_PROPOSITION_STATE,
    CHANGE_VALUE_PROPOSITION_STATE_FAILED,
    CHANGE_VALUE_PROPOSITION_STATE_SUCCESS,
    CLEAR_SELECTED_VALUE_PROPOSITION,
    CLEAR_VALUE_PROPOSITION_LIST,
    DELETE_VALUE_PROPOSITION,
    DELETE_VALUE_PROPOSITION_FAILED,
    DELETE_VALUE_PROPOSITION_SUCCESS,
    DUPLICATE_VALUE_PROPOSITION,
    DUPLICATE_VALUE_PROPOSITION_FAILED,
    DUPLICATE_VALUE_PROPOSITION_SUCCESS,
    FETCH_FOLLOWERS_BY_VALUE_PROPOSITION_ID,
    FETCH_FOLLOWERS_BY_VALUE_PROPOSITION_ID_FAILED,
    FETCH_FOLLOWERS_BY_VALUE_PROPOSITION_ID_SUCCESS,
    FETCH_ATTACHMENTS_BY_VALUE_PROPOSITION_ID,
    FETCH_ATTACHMENTS_BY_VALUE_PROPOSITION_ID_FAILED,
    FETCH_ATTACHMENTS_BY_VALUE_PROPOSITION_ID_SUCCESS,
    FETCH_VALUE_PROPOSITION_BY_ID,
    FETCH_VALUE_PROPOSITION_BY_ID_FAILED,
    FETCH_VALUE_PROPOSITION_BY_ID_SUCCESS,
    FETCH_VALUE_PROPOSITION_BY_SHARED_KEY,
    FETCH_VALUE_PROPOSITION_BY_SHARED_KEY_FAILED,
    FETCH_VALUE_PROPOSITION_BY_SHARED_KEY_SUCCESS,
    LOAD_VALUE_PROPOSITIONS_LIST,
    LOAD_VALUE_PROPOSITIONS_LIST_FAILED,
    LOAD_VALUE_PROPOSITIONS_LIST_SUCCESS,
    SAVE_VALUE_PROPOSITION,
    SAVE_VALUE_PROPOSITION_FAILED,
    SAVE_VALUE_PROPOSITION_SUCCESS
} from './constants';

export type ValuePropositionsAction = {
    type: string;
    payload: {} | string;
};

export const clearSelectedValueProposition =(): ValuePropositionsAction => ({
    type: CLEAR_SELECTED_VALUE_PROPOSITION,
    payload: null,
});

export const clearValuePropositionList =(): ValuePropositionsAction => ({
    type: CLEAR_VALUE_PROPOSITION_LIST,
    payload: null,
});

export const loadValuePropositionsList = (): ValuePropositionsAction => ({
    type: LOAD_VALUE_PROPOSITIONS_LIST,
    payload: null,
});

export const loadValuePropositionsListSuccess = (vpList): ValuePropositionsAction => ({
    type: LOAD_VALUE_PROPOSITIONS_LIST_SUCCESS,
    payload: vpList,
});

export const loadValuePropositionsListFailed = (error: string): ValuePropositionsAction => ({
    type: LOAD_VALUE_PROPOSITIONS_LIST_FAILED,
    payload: error,
});

export const duplicateValueProposition = (vpId, clientId): ValuePropositionsAction => ({
    type: DUPLICATE_VALUE_PROPOSITION,
    payload: { vpId, clientId },
});

export const duplicateValuePropositionSuccess = (): ValuePropositionsAction => ({
    type: DUPLICATE_VALUE_PROPOSITION_SUCCESS,
    payload: null,
});

export const duplicateValuePropositionFailed = (error: string): ValuePropositionsAction => ({
    type: DUPLICATE_VALUE_PROPOSITION_FAILED,
    payload: error,
});

export const fetchValuePropositionById = (vpId): ValuePropositionsAction => ({
    type: FETCH_VALUE_PROPOSITION_BY_ID,
    payload: { vpId },
});

export const fetchValuePropositionByIdSuccess = (vp): ValuePropositionsAction => ({
    type: FETCH_VALUE_PROPOSITION_BY_ID_SUCCESS,
    payload: { vp },
});

export const fetchValuePropositionByIdFailed = (error: string): ValuePropositionsAction => ({
    type: FETCH_VALUE_PROPOSITION_BY_ID_FAILED,
    payload: error,
});

export const fetchValuePropositionBySharedKey = (sharedKey): ValuePropositionsAction => ({
    type: FETCH_VALUE_PROPOSITION_BY_SHARED_KEY,
    payload: { sharedKey },
});

export const fetchValuePropositionBySharedKeySuccess = (vp): ValuePropositionsAction => ({
    type: FETCH_VALUE_PROPOSITION_BY_SHARED_KEY_SUCCESS,
    payload: { vp },
});

export const fetchValuePropositionBySharedKeyFailed = (error: string): ValuePropositionsAction => ({
    type: FETCH_VALUE_PROPOSITION_BY_SHARED_KEY_FAILED,
    payload: error,
});

export const saveValueProposition = (vp): ValuePropositionsAction => ({
    type: SAVE_VALUE_PROPOSITION,
    payload: { vp },
});

export const saveValuePropositionSuccess = (vpId, clientId): ValuePropositionsAction => ({
    type: SAVE_VALUE_PROPOSITION_SUCCESS,
    payload: { vpId, clientId },
});

export const saveValuePropositionFailed = (error: string): ValuePropositionsAction => ({
    type: SAVE_VALUE_PROPOSITION_FAILED,
    payload: error,
});

export const changeValuePropositionState = (vpId, isActive): ValuePropositionsAction => ({
    type: CHANGE_VALUE_PROPOSITION_STATE,
    payload: { vpId, isActive },
});

export const changeValuePropositionStateSuccess = (): ValuePropositionsAction => ({
    type: CHANGE_VALUE_PROPOSITION_STATE_SUCCESS,
    payload: null,
});

export const changeValuePropositionStateFailed = (error: string): ValuePropositionsAction => ({
    type: CHANGE_VALUE_PROPOSITION_STATE_FAILED,
    payload: error,
});

export const deleteValueProposition = (vpId): ValuePropositionsAction => ({
    type: DELETE_VALUE_PROPOSITION,
    payload: { vpId },
});

export const deleteValuePropositionSuccess = (): ValuePropositionsAction => ({
    type: DELETE_VALUE_PROPOSITION_SUCCESS,
    payload: null,
});

export const deleteValuePropositionFailed = (error: string): ValuePropositionsAction => ({
    type: DELETE_VALUE_PROPOSITION_FAILED,
    payload: error,
});

export const fetchFollowersByValuePropositionId = (vpId): ValuePropositionsAction => ({
    type: FETCH_FOLLOWERS_BY_VALUE_PROPOSITION_ID,
    payload: { vpId },
});

export const fetchFollowersByValuePropositionIdSuccess = (followers): ValuePropositionsAction => ({
    type: FETCH_FOLLOWERS_BY_VALUE_PROPOSITION_ID_SUCCESS,
    payload: { followers },
});

export const fetchFollowersByValuePropositionIdFailed = (error: string): ValuePropositionsAction => ({
    type: FETCH_FOLLOWERS_BY_VALUE_PROPOSITION_ID_FAILED,
    payload: error,
});

export const fetchAttachmentsByValuePropositionId = (vpId): ValuePropositionsAction => ({
    type: FETCH_ATTACHMENTS_BY_VALUE_PROPOSITION_ID,
    payload: { vpId },
});

export const fetchAttachmentsByValuePropositionIdSuccess = (attachments): ValuePropositionsAction => ({
    type: FETCH_ATTACHMENTS_BY_VALUE_PROPOSITION_ID_SUCCESS,
    payload: { attachments },
});

export const fetchAttachmentsByValuePropositionIdFailed = (error: string): ValuePropositionsAction => ({
    type: FETCH_ATTACHMENTS_BY_VALUE_PROPOSITION_ID_FAILED,
    payload: error,
});