export const HOME_PATH = '/';

// Error
export const NOT_FOUND_PATH = '/pages/error-404';

// Non authenticated pages
export const LOGIN_PATH = '/account/login';
export const SIGN_UP_PATH = '/account/signup';
export const REGISTER_PATH = '/account/register';
export const LOGOUT_PATH = '/account/logout';
export const FORGET_PASSWORD_PATH = '/account/forget-password';
export const FORGOT_PASSWORD_PATH = '/account/forgot-password';

// Error page
export const ERROR_PAGE_PATH = '/error';

// Dashboards
export const DASHBOARDS_PATH = '/dashboards';

// Clients
export const CLIENT_LIST_PATH = '/clients';
export const ADD_CLIENT_PATH = '/clients/add';
export const EDIT_CLIENT_PATH = '/clients/edit';

// Value Propositions
export const VALUE_PREPOSITIONS_LIST_PATH = '/value-prepositions';
export const ADD_VALUE_PREPOSITION_PATH = '/value-prepositions/add';
export const EDIT_VALUE_PREPOSITION_PATH = '/value-prepositions/edit';

export const SHARED_VALUE_PROPOSITION_PATH = '/value-prepositions/shared/:sharedKey'

// VPX
export const VPX_LIST_PATH = '/vpx'
export const VPX_USER_PROFILE_PATH = '/vpx/user-profile'
export const VPX_COMPANY_PROFILE_PATH = '/vpx/company-profile'

export const VPX_INVITATION_LIST_PATH = '/invitation/vpx/list'
export const VPX_INVITATION_PUBLIC_PATH = '/invitation/vpx/public'
export const VPX_INVITATION_PRIVATE_PATH = '/invitation/vpx/private'

// Fundamentals
export const FUNDAMENTALS_LIST_PATH = '/fundamentals';
export const ADD_FUNDAMENTALS_PATH = '/fundamentals/add';
export const EDIT_FUNDAMENTALS_PATH = '/fundamentals/edit';

// Profile
export const PROFILE_SECURITY_PATH = '/profile/security';
export const PROFILE_PERSONAL_INFORMATION_PATH = '/profile/information';
export const PROFILE_SUBSCRIPTION_PATH = '/profile/subscription';
export const PROFILE_PATH = PROFILE_PERSONAL_INFORMATION_PATH;

export const PAYMENT_SUCCESS_PATH = '/paymentsuccess';

// Company
export const COMPANY_PATH = '/company';
export const NON_EXISTENT_COMPANY_PATH = '/non-existent-company';
export const COMPANY_DASHBOARD_PATH = '/company/dashboard';
export const EDIT_COMPANY_DETAILS_PATH = '/company/details/edit';
export const CREATE_COMPANY = '/company/create';
export const LIST_COMPANY_INVITES = '/company/list-invites'
export const COMPANY_CLIENTS_PATH = '/company/clients';
export const ADD_COMPANY_CLIENT_PATH = '/company/clients/add';
export const EDIT_COMPANY_CLIENT_PATH = '/company/clients/edit';
export const COMPANY_USERS_PATH = '/company/users';
export const COMPANY_VALUE_PROPOSITIONS_PATH = '/company/value-propositions';
export const COMPANY_LICENSE_CHANGE_CONFIRM_PATH = '/company/license/confirm';

export const COMPANY_LICENSE_STRIP_PURCHASE_SUCCESS = '/CompanyPaymentSuccess';
export const COMPANY_LICENSE_STRIP_PURCHASE_CANCEL = '/CompanyPaymentCancel';

// Admin paths
export const ADMIN_INVITE_NEW_USERS = '/invite-users';

//Landing Page
export const LANDING_PAGE_PATH = '/landing-page';
