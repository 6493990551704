import { authRoutes, menuRoutes, internalRoutes, rootRoute, internalProfileRoutes } from './Pages';
import { dashboardRoutes, appRoutes, authRoutes as authRoutesTpl, pageRoutes, uiRoutes } from './Pages-tpl';

import * as PagePaths from  '../constants/pagePath';
import { isExecutivePlan } from '../helpers/plan';

// flatten the list of all nested routes
const flattenRoutes = routes => {
    let flatRoutes = [];

    routes = routes || [];
    routes.forEach(item => {
        flatRoutes.push(item);

        if (typeof item.children !== 'undefined') {
            flatRoutes = [...flatRoutes, ...flattenRoutes(item.children)];
        }
    });
    return flatRoutes;
};

const allTemplateRoutes = [dashboardRoutes, ...appRoutes, authRoutesTpl, pageRoutes, uiRoutes];
const authProtectedTemplateRoutes = [dashboardRoutes, ...appRoutes, pageRoutes, uiRoutes];

// All routes
const allRoutes = [rootRoute, authRoutes, ...menuRoutes, ...internalRoutes, ...internalProfileRoutes];

const authProtectedRoutes = [...menuRoutes];

const allProfileRoutes = [...internalProfileRoutes];

const allFlattenRoutes = flattenRoutes(allRoutes);

const isNonExistentCompanyRoute = (path) => [PagePaths.NON_EXISTENT_COMPANY_PATH, PagePaths.CREATE_COMPANY].includes(path);

const isExistentCompanyRoute = (path) => {
    return [
        PagePaths.COMPANY_PATH,
        PagePaths.COMPANY_DASHBOARD_PATH,
        PagePaths.COMPANY_USERS_PATH,
        PagePaths.COMPANY_VALUE_PROPOSITIONS_PATH,
    ].includes(path)
}

const isCompanyPath = (path) => isNonExistentCompanyRoute(path) || isExistentCompanyRoute(path);

const isAdminPageOnly = (path) => {
    return [PagePaths.ADMIN_INVITE_NEW_USERS].includes(path);
}

const isAllowToAccessAdminPage = (user) => {
    //return user?.id === 12 && user?.role === "Admin"; 
    return user?.role === "Admin"; 
}

export const shouldRenderRoute = (routePath, user) => {
    const hasCompany = user?.companyId > 0;
    const isExecutive = isExecutivePlan(user?.currentPlan);
    return (
        (
            !isCompanyPath(routePath) ||
            (isNonExistentCompanyRoute(routePath) && !hasCompany && isExecutive) ||
            (isExistentCompanyRoute(routePath) && hasCompany)
        )
        &&
        (
            !isAdminPageOnly(routePath) || isAllowToAccessAdminPage(user)
        )
    );
}

export { allRoutes, authProtectedRoutes, allFlattenRoutes, allProfileRoutes };
