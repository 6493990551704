import { all, call, fork, put, takeLatest } from '@redux-saga/core/effects';

import { apiServiceUrl, apiSuccessfulCode, defaultErrorMessage } from '../../constants';
import { downloadDataFromApiPromise, fetchGetDataObjectMethod, GetErrorMessageFromStatus } from '../../helpers/api';
import { getLoggedInUser } from '../../helpers/authUtils';
import * as Actions from './actions';
import * as ActionsType from './constants';

const reportApiServiceUrl = `${apiServiceUrl}/report`

function* downloadClientReport({ payload: { clientId, clientName }}) {
    const user = getLoggedInUser();
    try {
        const response = yield call(
            fetchGetDataObjectMethod,
            `${reportApiServiceUrl}/GetValuePropositionByClientReport?clientId=${clientId}`,
            user.token
        );

        if (response.status === apiSuccessfulCode) {
            const { errorMessage } = yield downloadDataFromApiPromise(response, defaultErrorMessage, `${clientName} Report.pdf`)
                .then((response) => ({ errorMessage: response.errorMessage }))
                .catch(() => ({ errorMessage: defaultErrorMessage }));
            if (!errorMessage) {
                yield put(Actions.downloadClientReportSuccess());
            } else {
                yield put(Actions.downloadClientReportFailed(GetErrorMessageFromStatus(response)));
            }
        } else {
            yield put(Actions.downloadClientReportFailed(GetErrorMessageFromStatus(response)));
        }
    } catch (error) {
        yield put(Actions.downloadClientReportFailed(GetErrorMessageFromStatus(error)));
    }
}

function* downloadValuePropositionReport({ payload: { vpId, vpName }}) {
    const user = getLoggedInUser();
    try {
        const response = yield call(
            fetchGetDataObjectMethod,
            `${reportApiServiceUrl}/GetValuePropositionReport?valuePropositionId=${vpId}`,
            user.token
        );

        if (response.status === apiSuccessfulCode) {
            const { errorMessage } = yield downloadDataFromApiPromise(response, defaultErrorMessage, `${vpName} Report.pdf`)
                .then((response) => ({ errorMessage: response.errorMessage }))
                .catch(() => ({ errorMessage: defaultErrorMessage }));
            if (!errorMessage) {
                yield put(Actions.downloadValuePropositionReportSuccess());
            } else {
                yield put(Actions.downloadValuePropositionReportFailed(GetErrorMessageFromStatus(response)));
            }
        } else {
            yield put(Actions.downloadValuePropositionReportFailed(GetErrorMessageFromStatus(response)));
        }
    } catch (error) {
        yield put(Actions.downloadValuePropositionReportFailed(GetErrorMessageFromStatus(error)));
    }
}

export function* watchDownloadClientReport(): any {
    yield takeLatest(ActionsType.DOWNLOAD_CLIENT_REPORT, downloadClientReport);
}

export function* watchDownloadValuePropositionReport(): any {
    yield takeLatest(ActionsType.DOWNLOAD_VALUE_PROPOSITION_REPORT, downloadValuePropositionReport);
}

function* reportSaga(): any {
    yield all([
        fork(watchDownloadClientReport),
        fork(watchDownloadValuePropositionReport),
    ]);
}

export default reportSaga;
