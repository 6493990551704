import { all, call, fork, put, takeEvery, takeLatest } from 'redux-saga/effects';

import {
    loadClientListSuccess,
    loadClientListFailed,
    loadGroupOptionsSuccess,
    loadGroupOptionsFailed,
    saveClientProfileSuccess,
    loadClientProfileSuccess,
    loadClientProfileFailed,
    saveClientProfileFailed,
    changeClientActiveStateSuccess,
    changeClientActiveStateFailed,
    hardDeleteClientSuccess,
    hardDeleteClientFailed,
} from './actions';
import {
    CHANGE_CLIENT_ACTIVE_STATE,
    HARD_DELETE_CLIENT,
    LOAD_CLIENT_LIST,
    LOAD_CLIENT_PROFILE,
    LOAD_GROUP_OPTIONS,
    SAVE_CLIENT_PROFILE,
} from './constants';
import {
    fetchGetMethod,
    fetchPostMethodWithFormData,
    fetchPostMethod,
    GetErrorMessageFromStatus,
} from '../../helpers/api';
import { getLoggedInUser } from '../../helpers/authUtils';
import { apiServiceUrl } from '../../constants';

const clientApiServiceUrl = `${apiServiceUrl}/client`

function* loadClientList() {
    try {
        const user = getLoggedInUser();
        const response = yield call(fetchGetMethod, `${clientApiServiceUrl}/GetClients`, user.token);
        if (response.status === 200) {
            const { clients, status } = yield response.data.then((response) => {
                return (response.status === 200)
                    ? { clients: response.clients }
                    : { status: response.status };
            }).catch(() => ({ status: 500 }));
            if (clients) {
                yield put(loadClientListSuccess(clients));
            } else {
                yield put(loadClientListFailed(GetErrorMessageFromStatus({ status: status })));
            }
        } else {
            yield put(loadClientListFailed(GetErrorMessageFromStatus(response)));
        }
    } catch (error) {
        yield put(loadClientListFailed(GetErrorMessageFromStatus(error)));
    }
}

function* loadGroupOptions({ payload: { token }}) {
    try {
        const userToken = token ?? getLoggedInUser().token;
        const response = yield call(fetchGetMethod, `${apiServiceUrl}/StandardGroup/GetGroups`, userToken);

        if (response.status === 200) {
            const { groupOptions, status } = yield response.data
                .then((response) => ({ groupOptions: response }))
                .catch(() => ({ status: 500 }))
            if (groupOptions) {
                yield put(loadGroupOptionsSuccess(groupOptions));
            } else {
                yield put(loadGroupOptionsFailed(GetErrorMessageFromStatus({ status: status })));
            }
        } else {
            yield put(loadGroupOptionsFailed(GetErrorMessageFromStatus(response)));
        }
    } catch (error) {
        yield put(loadGroupOptionsFailed(GetErrorMessageFromStatus(error)));
    }
}

function* loadClientProfile({ payload: { clientId } }) {
    try {
        const user = getLoggedInUser();
        const response = yield call(fetchGetMethod, `${clientApiServiceUrl}/GetClientById?id=${clientId}`, user.token);
        if (response.status === 200) {
            const { profile, status } = yield response.data
                .then((response) => ({ profile: response }))
                .catch(() => ({ status: 500 }))
            if (profile) {
                yield put(loadClientProfileSuccess(profile));
            } else {
                yield put(loadClientProfileFailed(GetErrorMessageFromStatus({ status: status })));
            }
        } else {
            yield put(loadClientProfileFailed(GetErrorMessageFromStatus(response)));
        }
    } catch (error) {
        yield put(loadClientProfileFailed(GetErrorMessageFromStatus(error)));
    }
}

function* saveClientProfile({ payload: {
    id, name, city, state, country, codeId, groupId, contactEmail, contactName, logo, mainClient
}}) {
    const user = getLoggedInUser();
    const data = new FormData();
    data.append('Id', id);
    data.append('Name', name);
    data.append('City', city);
    data.append('State', state);
    data.append('Country', country);
    data.append('CodeId', codeId);
    data.append('GroupId', groupId);
    data.append('ContactEmail', contactEmail);
    data.append('ContactName', contactName);
    data.append('Logo', logo);
    data.append('MainClient', mainClient)
    
    try {
        const response = yield call(fetchPostMethodWithFormData, `${clientApiServiceUrl}/SaveClient`, user.token, data);
        if (response.status === 200) {
            const { profile, status } = yield response.data
                .then((response) => ({ profile: response }))
                .catch(() => ({ status: 500 }))
            if (profile) {
                yield put(saveClientProfileSuccess(profile));
            } else {
                yield put(saveClientProfileFailed(GetErrorMessageFromStatus({ status: status })));
            }
        } else {
            yield put(saveClientProfileFailed(GetErrorMessageFromStatus(response)));
        }
    } catch (error) {
        yield put(saveClientProfileFailed(GetErrorMessageFromStatus(error)));
    }
}

function *changeClientActiveState({ payload: { clientId, isActive }}) {
    try {
        const user = getLoggedInUser();
        const urlSuffix = isActive ? 'ActiveClient' : 'InactiveClient';
        const body = { Id: clientId };
        const response = yield call(
            fetchPostMethod,
            `${clientApiServiceUrl}/${urlSuffix}`,
            user.token,
            body,
            true
        );

        if (response.status === 200) {
            yield put(changeClientActiveStateSuccess());
        } else {
            yield put(changeClientActiveStateFailed(GetErrorMessageFromStatus(response)));
        }
    } catch (error) {
        yield put(changeClientActiveStateFailed(GetErrorMessageFromStatus(error)));
    }
}

function *hardDeleteClient({ payload: { clientId }}) {
    try {
        const user = getLoggedInUser();
        const body = { Id: clientId };
        const response = yield call(fetchPostMethod, `${clientApiServiceUrl}/DeleteClient`, user.token, body);

        if (response.status === 200) {
            const { profile, status } = yield response.data
                .then((response) => ({ profile: response }))
                .catch(() => ({ status: 500 }))
            if (profile) {
                yield put(hardDeleteClientSuccess(profile));
            } else {
                yield put(hardDeleteClientFailed(GetErrorMessageFromStatus({ status: status })));
            }
        } else {
            yield put(hardDeleteClientFailed(GetErrorMessageFromStatus(response)));
        }
    } catch (error) {
        yield put(hardDeleteClientFailed(GetErrorMessageFromStatus(error)));
    }
}

export function* watchLoadClientList(): any {
    yield takeLatest(LOAD_CLIENT_LIST, loadClientList);
}

export function* watchLoadGroupOptions(): any {
    yield takeEvery(LOAD_GROUP_OPTIONS, loadGroupOptions);
}

export function* watchLoadClientProfile(): any {
    yield takeLatest(LOAD_CLIENT_PROFILE, loadClientProfile);
}

export function* watchSaveClientProfile(): any {
    yield takeEvery(SAVE_CLIENT_PROFILE, saveClientProfile);
}

export function* watchChangeClientActiveState(): any {
    yield takeEvery(CHANGE_CLIENT_ACTIVE_STATE, changeClientActiveState);
}

export function* watchHardDeleteClient(): any {
    yield takeEvery(HARD_DELETE_CLIENT, hardDeleteClient);
}

function* clientSaga(): any {
    yield all([
        fork(watchLoadClientList),
        fork(watchLoadGroupOptions),
        fork(watchLoadClientProfile),
        fork(watchSaveClientProfile),
        fork(watchChangeClientActiveState),
        fork(watchHardDeleteClient),
    ]);
}

export default clientSaga;
