import { all, call, fork, put, takeLatest } from '@redux-saga/core/effects';

import * as Actions from './actions';
import * as ActionType from './constants';
import { fetchGetMethod, fetchPostMethod, GetErrorMessageFromStatus } from '../../helpers/api';
import { getLoggedInUser } from '../../helpers/authUtils';
import { apiServiceUrl } from '../../constants';

const followersApiServiceUrl = `${apiServiceUrl}/followers`

function* deleteFollower({ payload: { id }}) {
    try {
        const user = getLoggedInUser();
        const response = yield call(
            fetchPostMethod,
            `${followersApiServiceUrl}/Delete`,
            user.token,
            { Id: id },
            /* withoutResponseBody */ false
        );

        if (response.status === 200) {
            yield put(Actions.deleteFollowerSuccess())
        } else {
            yield put(Actions.deleteFollowerFailed(GetErrorMessageFromStatus(response)));
        }
    } catch (error) {
        yield put(Actions.deleteFollowerFailed(GetErrorMessageFromStatus(error)));
    }
}

function* acceptFollower({ payload: { id }}) {
    try {
        const user = getLoggedInUser();
        const response = yield call(
            fetchPostMethod,
            `${followersApiServiceUrl}/Accept`,
            user.token,
            { Id: id },
            /* withoutResponseBody */ false
        );

        if (response.status === 200) {
            yield put(Actions.acceptFollowerSuccess(id))
        } else {
            yield put(Actions.acceptFollowerFailed(GetErrorMessageFromStatus(response)));
        }
    } catch (error) {
        yield put(Actions.acceptFollowerFailed(GetErrorMessageFromStatus(error)));
    }
}

function* rejectFollower({ payload: { id }}) {
    try {
        const user = getLoggedInUser();
        const response = yield call(
            fetchPostMethod,
            `${followersApiServiceUrl}/Reject`,
            user.token,
            { Id: id },
            /* withoutResponseBody */ false
        );

        if (response.status === 200) {
            yield put(Actions.rejectFollowerSuccess(id))
        } else {
            yield put(Actions.rejectFollowerFailed(GetErrorMessageFromStatus(response)));
        }
    } catch (error) {
        yield put(Actions.rejectFollowerFailed(GetErrorMessageFromStatus(error)));
    }
}

function* shareValueProposition({ payload: { vpId, name, email }}) {
    try {
        const user = getLoggedInUser();
        const body = {
            ValuePropositionId: vpId,
            Email: email,
            Name: name,
        };
        const response = yield call(
            fetchPostMethod,
            `${followersApiServiceUrl}/Share`,
            user.token,
            body,
            /* withoutResponseBody */ false
        );

        if (response.status === 200) {
            yield put(Actions.shareValuePropositionSuccess())
        } else {
            yield put(Actions.shareValuePropositionFailed(GetErrorMessageFromStatus(response)));
        }
    } catch (error) {
        yield put(Actions.shareValuePropositionFailed(GetErrorMessageFromStatus(error)));
    }
}

function* listAllFollowers({}) {
    try {
        const user = getLoggedInUser();
        const response = yield call(
            fetchGetMethod,
            `${followersApiServiceUrl}/ListAll`,
            user.token
        );

        if (response.status === 200) {
            const { followersList, status } = yield response.data.then((response) => {
                return (response.status === 200)
                    ? { followersList: response.followers }
                    : { status: response.status };
            }).catch(() => ({ status: 500 }));
            if (followersList) {
                yield put(Actions.listAllFollowersSuccess(followersList));
            } else {
                yield put(Actions.listAllFollowersFailed(GetErrorMessageFromStatus({ status: status })));
            }
        } else {
            yield put(Actions.listAllFollowersFailed(GetErrorMessageFromStatus(response)));
        }
    } catch (error) {
        yield put(Actions.listAllFollowersFailed(GetErrorMessageFromStatus(error)));
    }
}

export function* watchDeleteFollower(): any {
    yield takeLatest(ActionType.DELETE_FOLLOWER, deleteFollower);
}

export function* watchAcceptFollower(): any {
    yield takeLatest(ActionType.ACCEPT_FOLLOWER, acceptFollower);
}

export function* watchRejectFollower(): any {
    yield takeLatest(ActionType.REJECT_FOLLOWER, rejectFollower);
}

export function* watchShareValueProposition(): any {
    yield takeLatest(ActionType.SHARE_VALUE_PROPOSITION, shareValueProposition);
}

export function* watchListAllFollowers(): any {
    yield takeLatest(ActionType.FOLLOWERS_LIST_ALL, listAllFollowers);
}

function* followersSaga(): any {
    yield all([
        fork(watchDeleteFollower),
        fork(watchAcceptFollower),
        fork(watchRejectFollower),
        fork(watchShareValueProposition),
        fork(watchListAllFollowers),
    ]);
}

export default followersSaga;
