import { AppAction } from './actions';
import * as Actions from './constants';


const INIT_STATE = {
    currencies: null,
    loading: false,
    error: null,
};

type State = {
    currencies?: {} | null;
    loading?: boolean;
    error?: string;
};

const App = (state: State = INIT_STATE, action: AppAction) => {
    switch (action.type) {
        case Actions.GET_CURRENCIES:
            return { ...state, loading: true, error: null };
        case Actions.GET_CURRENCIES_SUCCESS:
            return { ...state, currencies: action.payload, loading: false };
        case Actions.GET_CURRENCIES_FAILED:
            return { ...state, error: action.payload, loading: false };
        default:
            return { ...state };
    }
}

export default App;
