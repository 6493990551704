import { apiSuccessfulCode } from '../constants';
import _ from 'lodash';

/**
 * Fetch data from given url
 * @param {*} url
 * @param {*} options
 */
export const fetchJSON = (url, options = {}) => {
    return fetch(url, options)
        .then(response => {
            return { status: response.status, data: response.json() };
        })
        .then(json => {
            return json;
        })
        .catch(error => {
            throw error;
        });
};

export const fetchJSONWithoutData = (url, options = {}) => {
    return fetch(url, options)
        .then(response => ({ status: response.status }))
        .catch(error => { throw error; });
};

export const fetchBasicPostMethod = (path, username, password, body) => {
    var promise = fetch(path, {
        method: 'POST',
        headers: {
            'Authorization': 'Basic ' + btoa(`${username}:${password}`),
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(body)
    });
    return getResponseObject(promise);
};

export const fetchBasicPostMethodWithToken = (path, token, body) => {
    const fetchOptions = {
        method: 'POST',
        headers: {
            'Authorization': 'Bearer ' + token,
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(body)
    };
    return fetchJSON(path, fetchOptions);
    // var promise = fetch(path, {
    //     method: 'POST',
    //     headers: {
    //         'Authorization': 'Bearer ' + token,
    //         'Accept': 'application/json',
    //         'Content-Type': 'application/json'
    //     },
    //     body: JSON.stringify(body)
    // });
    // return getResponseObject(promise);
    // return fetchJSON(path, fetchOptions)
    // var promise = fetch(path, {
    //     method: 'POST',
    //     headers: {
    //         'Authorization': 'Bearer ' + token,
    //         'Accept': 'application/json',
    //         'Content-Type': 'application/json'
    //     },
    //     body: JSON.stringify(body)
    // });
    // return getResponseObject(promise);
};

export const fetchPostMethod = (path, userToken, body, withoutResponseBody = false) => {
    const data = new FormData();
    _.forEach(body, (item, key) => {
        if(Array.isArray(item)) {
            _.forEach(item, (itemArray, index) => {
                data.append(`${key}[${index}]`, itemArray);
            })
        } else {
            data.append(key, item);
        }
    })
    const fetchOptions = {
        method: 'POST',
        headers: {
            'Authorization': 'Bearer ' + userToken
        },
        body: data
    };
    if (withoutResponseBody) {
        return fetchJSONWithoutData(path, fetchOptions);
    }
    return fetchJSON(path, fetchOptions);
};

export const fetchPostMethodWithFormData = (path, userToken, body) => {
    return fetchJSON(path, {
        method: 'POST',
        headers: {
            'Authorization': 'Bearer ' + userToken
        },
        body: body
    });
};

export const fetchGetMethod = (path, userToken) => {
    return fetchJSON(path, {
        method: 'GET',
        headers: {
            'Authorization': 'Bearer ' + userToken,
            'Accept': 'application/json'
        }
    });
}

export const fetchGetDataObjectMethod = (path, userToken) => {
    return fetch(path, {
        method: 'GET',
        headers: {
            'Authorization': 'Bearer ' + userToken,
            'Accept': 'application/octet-stream',
            'Content-Type': 'application/json'
        },
    });
}

export const downloadDataFromApiPromise = (promise, defaultErrorMessage, fileName) => {
    return new Promise((accept) => {
        promise.blob().then(blob => {
            accept({ status: apiSuccessfulCode });
            const url = window.URL.createObjectURL(blob);
            const a = document.createElement('a');
            a.href = url;
            a.download = fileName;
            a.click();
        }).catch(() => {
            accept({ errorMessage: defaultErrorMessage });
        });
    });
}

export const GetErrorMessageFromStatus = (response) => {
    switch (response.status) {
        case 500:
            return 'Internal Server Error';
        case 401:
            return 'You don\'t have permission to access the system.';
        case 404:
            return 'Not found.';
        default:
            return 'Invalid credentials';
    }
}

function getResponseObject(promise) {
    return new Promise((accept) => {
        promise.then((response) => {
            if (response.status === 200) {
                response.json()
                    .then((body) =>
                        accept({ body: body, status: response.status })
                    )
                    .catch((error) => {
                        console.log('getResponseObject - error (json response catch)', error);
                        accept({ status: response.status, keyErrorMessage: error.message });
                    });
            } else {
                console.log('getResponseObject - response status different of successful code', response);
                accept({
                    status: response.status
                });
            }
        }).catch((error) => {
            console.log('qual o erro', error, promise);
            accept({ status: 500, serviceUnavailable: true });
        });
    });
}
