import { ReportAction } from './actions';
import * as Actions from './constants';

const INIT_STATE = {
    loading: false,
    error: null,
}

type State = {
    loading: Boolean;
    error?: string;
}

const Report = (state: State = INIT_STATE, action: ReportAction) => {
    switch (action.type) {
        case Actions.DOWNLOAD_CLIENT_REPORT:
            return { ...state, loading: true, error: null };
        case Actions.DOWNLOAD_CLIENT_REPORT_SUCCESS:
            return { ...state, loading: false };
        case Actions.DOWNLOAD_CLIENT_REPORT_FAILED:
            return { ...state, loading: false, error: action.payload };
        case Actions.DOWNLOAD_VALUE_PROPOSITION_REPORT:
            return { ...state, loading: true, error: null };
        case Actions.DOWNLOAD_VALUE_PROPOSITION_REPORT_SUCCESS:
            return { ...state, loading: false };
        case Actions.DOWNLOAD_VALUE_PROPOSITION_REPORT_FAILED:
            return { ...state, loading: false, error: action.payload };
        default:
            return { ...state };
    }
}

export default Report;
