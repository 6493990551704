import { FundamentalsAction } from './actions';
import * as Actions from './constants';

const INIT_STATE = {
    clientProfile: null,
    list: null,
    listLoading: false,
    listError: null,
    listCurrency: null,
    fundament: null,
    fundamentLoading: false,
    fundamentSaving: false,
    fundamentDeleting: false,
    fundamentError: null,
    fundamentalParams: null,
    fundamentalParamsLoading: false,
    fundamentalParamsError: null,
};

type State = {
    clientProfile?: {};
    list?: {};
    listLoading: boolean;
    listError?: string;
    listCurrency?: number;
    fundament?: {},
    fundamentLoading?: boolean,
    fundamentSaving?: boolean,
    fundamentDeleting?: boolean,
    fundamentError?: string,
    fundamentalParams?: {},
    fundamentalParamsLoading?: boolean,
    fundamentalParamsError?: string,
    calculatedFundamentalValues?: {}
};

const Fundamentals = (state: State = INIT_STATE, action: FundamentalsAction) => {
    switch (action.type) {
        case Actions.CLEAR_FUNDAMENTALS_LIST:
            return { ...state, clientProfile: null, list: null };
        case Actions.CLEAR_CALCULATED_FUNDAMENTAL_VALUES:
            return { ...state, calculatedFundamentalValues: undefined };
        case Actions.CALCULATE_FUNDAMENTAL_VALUES:
            return { ...state };
        case Actions.CALCULATE_FUNDAMENTAL_VALUES_SUCCESS:
            return {
                ...state,
                calculatedFundamentalValues: action.payload.calculatedFundamentalValues
            };
        case Actions.CALCULATE_FUNDAMENTAL_VALUES_FAILED:
            return { ...state, fundamentError: action.payload };
        case Actions.LOAD_FUNDAMENTALS_LIST:
            return {
                ...state,
                clientProfile: null,
                list: [],
                listLoading: true,
                listError: null,
                listCurrency: null,
            };
        case Actions.LOAD_FUNDAMENTALS_LIST_FAILED:
            return { ...state, listError: action.payload, listLoading: false };
        case Actions.LOAD_FUNDAMENTALS_LIST_SUCCESS:
            return {
                ...state,
                clientProfile: action.payload.clientProfile,
                list: action.payload.fundamentals,
                listLoading: false,
                listCurrency: action.payload.vpCurrency,
            };
        case Actions.FETCH_FUNDAMENTALS_BY_ID:
            return { ...state, fundament: null, fundamentError: null, fundamentLoading: true };
        case Actions.FETCH_FUNDAMENTALS_BY_ID_FAILED:
            return { ...state, fundamentError: action.payload, fundamentLoading: false };
        case Actions.FETCH_FUNDAMENTALS_BY_ID_SUCCESS:
            return { ...state, fundament: action.payload.fundament, fundamentLoading: false };
        case Actions.SAVE_FUNDAMENTALS:
            return { ...state, fundamentError: null, fundamentSaving: true };
        case Actions.SAVE_FUNDAMENTALS_FAILED:
            return { ...state, fundamentError: action.payload, fundamentSaving: false };
        case Actions.SAVE_FUNDAMENTALS_SUCCESS:
            return { ...state, fundamentSaving: false };
        case Actions.DELETE_FUNDAMENTALS:
            return { ...state, fundamentError: null, fundamentDeleting: true };
        case Actions.DELETE_FUNDAMENTALS_FAILED:
            return { ...state, fundamentError: action.payload, fundamentDeleting: false };
        case Actions.DELETE_FUNDAMENTALS_SUCCESS:
            return { ...state, fundamentDeleting: false };
        case Actions.LOAD_FUNDAMENTAL_PARAMS:
            return { ...state, fundamentalParamsLoading: true, fundamentalParamsError: null };
        case Actions.LOAD_FUNDAMENTAL_PARAMS_SUCCESS:
            return { ...state, fundamentalParamsLoading: false, fundamentalParams: action.payload.params };
        case Actions.LOAD_FUNDAMENTAL_PARAMS_FAILED:
            return { ...state, fundamentalParamsLoading: false, fundamentalParamsError: action.payload };
        default:
            return { ...state };
    }
}

export default Fundamentals;
