import { all, call, fork, put, takeLatest } from '@redux-saga/core/effects';

import * as Actions from './actions';
import * as ActionsType from './constants';
import {
    fetchBasicPostMethodWithToken,
    fetchGetMethod,
    fetchJSON,
    fetchPostMethod,
    GetErrorMessageFromStatus
} from '../../helpers/api';
import { getLoggedInUser } from '../../helpers/authUtils';
import { apiServiceUrl } from '../../constants';

const vpApiServiceUrl = `${apiServiceUrl}/ValueProposition`

function* loadValuePropositionsList() {
    try {
        const user = getLoggedInUser();
        const response = yield call(fetchGetMethod, `${vpApiServiceUrl}/GetValueProposition`, user.token);

        if (response.status === 200) {
            const { vpList, status } = yield response.data.then((response) => {
                return (response.status === 200)
                    ? { vpList: response.vpList }
                    : { status: response.status };
            }).catch(() => ({ status: 500 }));
            if (vpList) {
                yield put(Actions.loadValuePropositionsListSuccess(vpList));
            } else {
                yield put(Actions.loadValuePropositionsListFailed(GetErrorMessageFromStatus({ status: status })));
            }
        } else {
            yield put(Actions.loadValuePropositionsListFailed(GetErrorMessageFromStatus(response)));
        }
    } catch (error) {
        yield put(Actions.loadValuePropositionsListFailed(GetErrorMessageFromStatus(error)));
    }
}

function* duplicateValueProposition({ payload: { vpId, clientId }}) {
    try {
        const user = getLoggedInUser();
        const body = {
            ValuePropositionId: vpId,
            ClientID: clientId,
        };
        const response = yield call(
            fetchPostMethod,
            `${vpApiServiceUrl}/DuplicateValueProposition`,
            user.token,
            body,
            /* withoutResponseBody */ true
        );

        if (response.status === 200) {
            yield put(Actions.duplicateValuePropositionSuccess())
        } else {
            yield put(Actions.duplicateValuePropositionFailed(GetErrorMessageFromStatus(response)));
        }
    } catch (error) {
        yield put(Actions.duplicateValuePropositionFailed(GetErrorMessageFromStatus(error)));
    }
}

function* fetchValuePropositionById({ payload: { vpId }}) {
    try {
        const user = getLoggedInUser();
        const response = yield call(fetchGetMethod, `${vpApiServiceUrl}/GetValuePropositionById?id=${vpId}`, user.token);

        if (response.status === 200) {
            const { vp, status } = yield response.data.then((response) => {
                return (response.status === 200)
                    ? { vp: response.valueProposition }
                    : { status: response.status };
            }).catch(() => ({ status: 500 }));
            if (vp) {
                yield put(Actions.fetchValuePropositionByIdSuccess(vp));
            } else {
                yield put(Actions.fetchValuePropositionByIdFailed(GetErrorMessageFromStatus({ status: status })));
            }
        } else {
            yield put(Actions.fetchValuePropositionByIdFailed(GetErrorMessageFromStatus(response)));
        }
    } catch (error) {
        yield put(Actions.fetchValuePropositionByIdFailed(GetErrorMessageFromStatus(error)));
    }
}

function* saveValueProposition({ payload: { vp }}) {
    try {
        const user = getLoggedInUser();
        const response = yield call(
            fetchPostMethod,
            `${vpApiServiceUrl}/SaveValueProposition`,
            user.token,
            vp,
            /* withoutResponseBody */ false
        );
        if (response.status === 200) {
            const { VpId, ClientId } = yield response.data.then((response) => {
                return (response.status === 200)
                    ? { VpId: response.vpId, ClientId: response.clientId }
                    : { status: response.status };
            }).catch(() => ({ status: 500 }));
            if (VpId && ClientId) {
                yield put(Actions.saveValuePropositionSuccess(VpId, ClientId))
            } else {
            yield put(Actions.saveValuePropositionFailed(GetErrorMessageFromStatus({ status: 500 })));
            }
        } else {
            yield put(Actions.saveValuePropositionFailed(GetErrorMessageFromStatus(response)));
        }
    } catch (error) {
        yield put(Actions.saveValuePropositionFailed(GetErrorMessageFromStatus(error)));
    }
}


function* changeValuePropositionState({ payload: { vpId, isActive }}) {
    try {
        const user = getLoggedInUser();
        const urlSuffix = isActive ? 'ActiveValueProposition' : 'DeactivateValueProposition';
        const body = { Id: vpId };
        const response = yield call(
            fetchPostMethod,
            `${vpApiServiceUrl}/${urlSuffix}`,
            user.token,
            body,
            /* withoutResponseBody */ true
        );

        if (response.status === 200) {
            yield put(Actions.changeValuePropositionStateSuccess());
        } else {
            yield put(Actions.changeValuePropositionStateFailed(GetErrorMessageFromStatus(response)));
        }
    } catch (error) {
        yield put(Actions.changeValuePropositionStateFailed(GetErrorMessageFromStatus(error)));
    }
}

function* deleteValueProposition({ payload: { vpId }}) {
    try {
        const user = getLoggedInUser();
        const body = { Id: vpId };
        const response = yield call(
            fetchPostMethod,
            `${vpApiServiceUrl}/DeleteValueProposition`,
            user.token,
            body,
            /* withoutResponseBody */ true
        );

        if (response.status === 200) {
            yield put(Actions.deleteValuePropositionSuccess());
        } else {
            yield put(Actions.deleteValuePropositionFailed(GetErrorMessageFromStatus(response)));
        }
    } catch (error) {
        yield put(Actions.deleteValuePropositionFailed(GetErrorMessageFromStatus(error)));
    }
}

function* fetchFollowersByValuePropositionId({ payload: { vpId }}) {
    try {
        const user = getLoggedInUser();
        const response = yield call(
            fetchGetMethod,
            `${vpApiServiceUrl}/GetFollowersByValuePropositionId?id=${vpId}`,
            user.token
        );

        if (response.status === 200) {
            const { followers, status } = yield response.data.then((response) => {
                return (response.status === 200)
                    ? { followers: response.followers }
                    : { status: response.status };
            }).catch(() => ({ status: 500 }));
            if (followers) {
                yield put(Actions.fetchFollowersByValuePropositionIdSuccess(followers));
            } else {
                yield put(Actions.fetchFollowersByValuePropositionIdFailed(GetErrorMessageFromStatus({ status: status })));
            }
        } else {
            yield put(Actions.fetchFollowersByValuePropositionIdFailed(GetErrorMessageFromStatus(response)));
        }
    } catch (error) {
        yield put(Actions.fetchFollowersByValuePropositionIdFailed(GetErrorMessageFromStatus(error)));
    }
}

function* fetchAttachmentsByValuePropositionId({ payload: { vpId }}) {
    try {
        const user = getLoggedInUser();
        const response = yield call(
            fetchGetMethod,
            `${vpApiServiceUrl}/GetAttachmentsByValuePropositionId?id=${vpId}`,
            user.token
        );

        if (response.status === 200) {
            const { attachments, status } = yield response.data.then((response) => {
                return (response.status === 200)
                    ? { attachments: response.attachments }
                    : { status: response.status };
            }).catch(() => ({ status: 500 }));
            if (attachments) {
                yield put(Actions.fetchAttachmentsByValuePropositionIdSuccess(attachments));
            } else {
                yield put(Actions.fetchAttachmentsByValuePropositionIdFailed(GetErrorMessageFromStatus({ status: status })));
            }
        } else {
            yield put(Actions.fetchAttachmentsByValuePropositionIdFailed(GetErrorMessageFromStatus(response)));
        }
    } catch (error) {
        yield put(Actions.fetchAttachmentsByValuePropositionIdFailed(GetErrorMessageFromStatus(error)));
    }
}

function* fetchValuePropositionBySharedKey({ payload: { sharedKey }}) {
    try {
        const options = {
            method: 'GET',
            headers: {
                'Accept': 'application/json'
            }
        }
        const response = yield call(
            fetchJSON,
            `${vpApiServiceUrl}/GetValuePropositionBySharedKey?sharedKey=${sharedKey}`,
            options
        );

        if (response.status === 200) {
            const { vp, status } = yield response.data.then((response) => {
                return (response.status === 200)
                    ? { vp: response.valueProposition }
                    : { status: response.status };
            }).catch(() => ({ status: 500 }));
            if (vp) {
                yield put(Actions.fetchValuePropositionBySharedKeySuccess(vp));
            } else {
                yield put(Actions.fetchValuePropositionBySharedKeyFailed(GetErrorMessageFromStatus({ status: status })));
            }
        } else {
            yield put(Actions.fetchValuePropositionBySharedKeyFailed(GetErrorMessageFromStatus(response)));
        }
    } catch (error) {
        yield put(Actions.fetchValuePropositionBySharedKeyFailed(GetErrorMessageFromStatus(error)));
    }
}

export function* watchLoadValuePropositionsList(): any {
    yield takeLatest(ActionsType.LOAD_VALUE_PROPOSITIONS_LIST, loadValuePropositionsList);
}

export function* watchDuplicateValueProposition(): any {
    yield takeLatest(ActionsType.DUPLICATE_VALUE_PROPOSITION, duplicateValueProposition);
}

export function* watchFetchValuePropositionById(): any {
    yield takeLatest(ActionsType.FETCH_VALUE_PROPOSITION_BY_ID, fetchValuePropositionById);
}

export function* watchSaveValueProposition(): any {
    yield takeLatest(ActionsType.SAVE_VALUE_PROPOSITION, saveValueProposition);
}

export function* watchChangeValuePropositionState(): any {
    yield takeLatest(ActionsType.CHANGE_VALUE_PROPOSITION_STATE, changeValuePropositionState);
}

export function* watchDeleteValueProposition(): any {
    yield takeLatest(ActionsType.DELETE_VALUE_PROPOSITION, deleteValueProposition);
}

export function* watchFetchFollowersByValuePropositionId(): any {
    yield takeLatest(ActionsType.FETCH_FOLLOWERS_BY_VALUE_PROPOSITION_ID, fetchFollowersByValuePropositionId);
}

export function* watchFetchAttachmentsByValuePropositionId(): any {
    yield takeLatest(ActionsType.FETCH_ATTACHMENTS_BY_VALUE_PROPOSITION_ID, fetchAttachmentsByValuePropositionId);
}

export function* watchFetchValuePropositionBySharedKey(): any {
    yield takeLatest(ActionsType.FETCH_VALUE_PROPOSITION_BY_SHARED_KEY, fetchValuePropositionBySharedKey);
}

function* valuePropositions(): any {
    yield all([
        fork(watchLoadValuePropositionsList),
        fork(watchDuplicateValueProposition),
        fork(watchFetchValuePropositionById),
        fork(watchSaveValueProposition),
        fork(watchChangeValuePropositionState),
        fork(watchDeleteValueProposition),
        fork(watchFetchFollowersByValuePropositionId),
        fork(watchFetchValuePropositionBySharedKey),
        fork(watchFetchAttachmentsByValuePropositionId),
    ]);
}

export default valuePropositions;
