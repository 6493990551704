import { all, call, fork, put, takeLatest } from 'redux-saga/effects';

import * as Actions from './actions';
import * as ActionType from './constants';
import { fetchGetMethod, GetErrorMessageFromStatus } from '../../helpers/api';
import { getLoggedInUser } from '../../helpers/authUtils';
import { apiServiceUrl } from '../../constants';

function* getCurrencies() {
    try {
        const user = getLoggedInUser();
        const response = yield call(fetchGetMethod, `${apiServiceUrl}/Currency/GetCurrencies`, null);

        if (response.status === 200) {
            const { currencies, status } = yield response.data.then((response) => {
                return { currencies: response };
            }).catch(() => ({ status: 500 }));
            if (currencies) {
                yield put(Actions.getCurrenciesSuccess(currencies));
            } else {
                yield put(Actions.getCurrenciesFailed(GetErrorMessageFromStatus({ status: status })));
            }
        } else {
            yield put(Actions.getCurrenciesFailed(GetErrorMessageFromStatus(response)));
        }
    } catch (error) {
        yield put(Actions.getCurrenciesFailed(GetErrorMessageFromStatus(error)));
    }
}

export function* watchGetCurrencies(): any {
    yield takeLatest(ActionType.GET_CURRENCIES, getCurrencies);
}

function* appSaga(): any {
    yield all([
        fork(watchGetCurrencies),
    ]);
}

export default appSaga;
