// @flow

import { combineReducers } from 'redux';
import App from './app/reducers';
import AppMenu from './appMenu/reducers';
import { LOGOUT_USER } from './auth/constants';
import Auth from './auth/reducers';
import Client from './client/reducers';
import Company from './company/reducers';
import Dashboard from './dashboard/reducers';
import Followers from './followers/reducers';
import Fundamentals from './fundamentals/reducers';
import Layout from './layout/reducers';
import Report from './report/reducers';
import User from './user/reducers';
import ValueProposition from './valuePropositions/reducers';
import Vpx from './vpx/reducers';

const reducers = combineReducers({
    App,
    AppMenu,
    Auth,
    Client,
    Company,
    Dashboard,
    Followers,
    Fundamentals,
    Layout,
    Report,
    User,
    ValueProposition,
    Vpx,
});

export default (state, action) => {
    if (action.type === LOGOUT_USER) {
        return reducers(undefined, action)
    }

    return reducers(state, action)
}
