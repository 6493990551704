import { FollowersAction } from './actions';
import * as ActionType from './constants';

const INIT_STATE = {
    error: null,
    deleting: false,
    accepting: false,
    rejecting: false,
    sharing: false,
    followers: null,
    followersLoading: false,
    followersError: null,
};

type State = {
    error?: string;
    deleting?: boolean;
    accepting?: boolean;
    rejecting?: boolean;
    sharing?: boolean;
    followers?: {};
    followersLoading: boolean,
    followersError?: string,
};

const Followers = (state: State = INIT_STATE, action: FollowersAction) => {
    switch (action.type) {
        case ActionType.ACCEPT_FOLLOWER:
            return { ...state, accepting: true, error: null };
        case ActionType.ACCEPT_FOLLOWER_SUCCESS: {
            const newFollowers = state.followers?.filter(follower =>
                follower.Id !== action.payload.id
            );
            return { ...state, accepting: false, followers: newFollowers };
        }
        case ActionType.ACCEPT_FOLLOWER_FAILED:
            return { ...state, accepting: false, error: action.payload };

        case ActionType.DELETE_FOLLOWER:
            return { ...state, deleting: true, error: null };
        case ActionType.DELETE_FOLLOWER_SUCCESS:
            return { ...state, deleting: false };
        case ActionType.DELETE_FOLLOWER_FAILED:
            return { ...state, deleting: false, error: action.payload };

        case ActionType.REJECT_FOLLOWER:
            return { ...state, rejecting: true, error: null };
        case ActionType.REJECT_FOLLOWER_SUCCESS: {
            const newFollowers = state.followers?.filter(follower =>
                follower.Id !== action.payload.id
            );
            return { ...state, rejecting: false, followers: newFollowers };
        }
        case ActionType.REJECT_FOLLOWER_FAILED:
            return { ...state, rejecting: false, error: action.payload };

        case ActionType.SHARE_VALUE_PROPOSITION:
            return { ...state, sharing: true, error: null };
        case ActionType.SHARE_VALUE_PROPOSITION_SUCCESS:
            return { ...state, sharing: false };
        case ActionType.SHARE_VALUE_PROPOSITION_FAILED:
            return { ...state, sharing: false, error: action.payload };

        case ActionType.FOLLOWERS_LIST_ALL:
            return { ...state, followers: [], followersLoading: true, followersError: null };
        case ActionType.FOLLOWERS_LIST_ALL_SUCCESS:
            return { ...state, followers: action.payload.followersList, followersLoading: false };
        case ActionType.FOLLOWERS_LIST_ALL_FAILED:
            return { ...state, followersLoading: false, followersError: action.payload };

        default:
            return { ...state };
    }
}

export default Followers;
