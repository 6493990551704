import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { Route } from 'react-router-dom';

import { HOME_PATH, LOGIN_PATH } from '../constants';
import { isUserAuthenticated, getLoggedInUser } from '../helpers/authUtils';
import { initMenu, initProfileMenu } from '../redux/actions';

export const MenuOptions = Object.freeze({
    "default": 0,
    "profile": 1,
})

// handle auth and authorization
const PrivateRouteInternal = ({ component: Component, roles, menuOption, ...rest }) => {
    useEffect(() => {
        switch (menuOption) {
            case MenuOptions.profile:
                rest.initProfileMenu();
                break;
            default:
                rest.initMenu();
        }
    }, []);

    return (
        <Route
            {...rest}
            render={props => {
                if (!isUserAuthenticated()) {
                    // not logged in so redirect to login page with the return url
                    return <Redirect to={{ pathname: LOGIN_PATH, state: { from: props.location } }} />;
                }

                const loggedInUser = getLoggedInUser();
                // check if route is restricted by role
                if (roles && roles.indexOf(loggedInUser.role) === -1) {
                    // role not authorised so redirect to home page
                    return <Redirect to={{ pathname: HOME_PATH }} />;
                }

                // authorised so return component
                return <Component {...props} />;
            }}
        />
)};

export const PrivateRoute =
    connect(
        null,
        { initProfileMenu, initMenu }
    )(PrivateRouteInternal);

const PublicAndPrivateRouteInternal = ({ component: Component, roles, menuOption, ...rest }) => {
    useEffect(() => {
        switch (menuOption) {
            case MenuOptions.profile:
                rest.initProfileMenu();
                break;
            default:
                rest.initMenu();
        }
    }, []);

    return (
        <Route
            {...rest}
            render={props => <Component {...props} />}
        />
)};


export const PublicAndPrivateRoute =
    connect(
        null,
        { initProfileMenu, initMenu }
    )(PublicAndPrivateRouteInternal);
