export const DELETE_FOLLOWER = 'DELETE_FOLLOWER';
export const DELETE_FOLLOWER_SUCCESS = 'DELETE_FOLLOWER_SUCCESS';
export const DELETE_FOLLOWER_FAILED = 'DELETE_FOLLOWER_FAILED';

export const ACCEPT_FOLLOWER = 'ACCEPT_FOLLOWER';
export const ACCEPT_FOLLOWER_SUCCESS = 'ACCEPT_FOLLOWER_SUCCESS';
export const ACCEPT_FOLLOWER_FAILED = 'ACCEPT_FOLLOWER_FAILED';

export const REJECT_FOLLOWER = 'REJECT_FOLLOWER';
export const REJECT_FOLLOWER_SUCCESS = 'REJECT_FOLLOWER_SUCCESS';
export const REJECT_FOLLOWER_FAILED = 'REJECT_FOLLOWER_FAILED';

export const SHARE_VALUE_PROPOSITION = 'SHARE_VALUE_PROPOSITION';
export const SHARE_VALUE_PROPOSITION_SUCCESS = 'SHARE_VALUE_PROPOSITION_SUCCESS';
export const SHARE_VALUE_PROPOSITION_FAILED = 'SHARE_VALUE_PROPOSITION_FAILED';

export const FOLLOWERS_LIST_ALL = 'FOLLOWERS_LIST_ALL';
export const FOLLOWERS_LIST_ALL_SUCCESS = 'FOLLOWERS_LIST_ALL_SUCCESS';
export const FOLLOWERS_LIST_ALL_FAILED = 'FOLLOWERS_LIST_ALL_FAILED';
