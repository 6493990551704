import React from 'react';
import { VPFollowersStatus } from './enums';

export * from './fake-backend';

export function formatFxFactor(fxFactor) {
    if (fxFactor !== null && fxFactor !== undefined) {
        return `${fxFactor.toFixed(2)} X`;
    }
    return "No Expenses Provided";
} 

export function formatLocation(state, country) {
    const locationArr = [];
    if(state) locationArr.push(state);
    if(country) locationArr.push(country);
    return locationArr.join(', ');
}

export function GetVPFollowersStatusNameComponent(status) {
    switch (status) {
        case VPFollowersStatus.PENDING:
            return <p className="badge badge-warning-lighten p-1 font-14 mb-0">{'Pending the accept'}</p>;
        case VPFollowersStatus.ACCEPT:
            return <p className="badge badge-success-lighten p-1 font-14 mb-0">{'Accepted'}</p>;
        case VPFollowersStatus.REJECT:
            return <p className="badge badge-danger-lighten p-1 font-14 mb-0">{'Rejected'}</p>;
        case VPFollowersStatus.WAITING_FOR_APPROVAL:
            return <p className="badge badge-warning-lighten p-1 font-14 mb-0">{'Waiting for approval'}</p>;
    }
    return null;
}
