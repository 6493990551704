import * as ActionType from './constants';

export type CompanyAction = {
    type: string;
    payload: {} | string;
};

export const clearCompanyMessages = (): CompanyAction => ({
    type: ActionType.CLEAR_COMPANY_MESSAGES,
    payload: null,
})

export const loadCompanyDetails = (): CompanyAction => ({
    type: ActionType.LOAD_COMPANY_DETAILS,
    payload: null,
});

export const loadCompanyDetailsSuccess = (companyDetails): CompanyAction => ({
    type: ActionType.LOAD_COMPANY_DETAILS_SUCCESS,
    payload: companyDetails,
});

export const loadCompanyDetailsFailed = (error: string): CompanyAction => ({
    type: ActionType.LOAD_COMPANY_DETAILS_FAILED,
    payload: error,
});

export const loadCompanyDashboardData = (): CompanyAction => ({
    type: ActionType.LOAD_COMPANY_DASHBOARD_DATA,
    payload: null,
});

export const loadCompanyDashboardDataSuccess = (
    isOwner,
    vpoTotalImplementation,
    vpoTotalForecast,
    vpoTotalProposition,
    totalClients,
    totalValueProposition,
    totalPortifolioAmount,
    totalFundamentals,
    valuePropositionSummary
): CompanyAction => ({
    type: ActionType.LOAD_COMPANY_DASHBOARD_DATA_SUCCESS,
    payload: {
        isOwner,
        vpoTotalImplementation,
        vpoTotalForecast,
        vpoTotalProposition,
        totalClients,
        totalValueProposition,
        totalPortifolioAmount,
        totalFundamentals,
        valuePropositionSummary
    },
});

export const loadCompanyDashboardDataFailed = (error: string): CompanyAction => ({
    type: ActionType.LOAD_COMPANY_DASHBOARD_DATA_FAILED,
    payload: error,
});

export const editCompanyDetails = (companyDetails): CompanyAction => ({
    type: ActionType.EDIT_COMPANY_DETAILS,
    payload: { companyDetails },
});

export const editCompanyDetailsSuccess = (newCompanyDetails, successMessage): CompanyAction => ({
    type: ActionType.EDIT_COMPANY_DETAILS_SUCCESS,
    payload: { newCompanyDetails, successMessage },
});

export const editCompanyDetailsFailed = (errorMessage): CompanyAction => ({
    type: ActionType.EDIT_COMPANY_DETAILS_FAILED,
    payload: errorMessage,
});

export const createCompany = (companyDetails): CompanyAction => ({
    type: ActionType.CREATE_COMPANY,
    payload: { companyDetails },
});

export const createCompanySuccess = (newToken, successMessage): CompanyAction => ({
    type: ActionType.CREATE_COMPANY_SUCCESS,
    payload: { newUserToken: newToken, successMessage },
});

export const createCompanyFailed = (errorMessage): CompanyAction => ({
    type: ActionType.CREATE_COMPANY_FAILED,
    payload: errorMessage,
});

export const loadCompanyClients = (): CompanyAction => ({
    type: ActionType.LOAD_COMPANY_CLIENTS,
    payload: null,
});

export const loadCompanyClientsSuccess = (companyListClients): CompanyAction => ({
    type: ActionType.LOAD_COMPANY_CLIENTS_SUCCESS,
    payload: companyListClients,
});

export const loadCompanyClientsFailed = (error: string): CompanyAction => ({
    type: ActionType.LOAD_COMPANY_CLIENTS_FAILED,
    payload: error,
});

export const loadCompanyMainClient = (mainClientId): CompanyAction => ({
    type: ActionType.LOAD_COMPANY_MAIN_CLIENT,
    payload: { mainClientId }
});

export const loadCompanyMainClientSuccess = (mainClientProfile): CompanyAction => ({
    type: ActionType.LOAD_COMPANY_MAIN_CLIENT_SUCCESS,
    payload: { mainClientProfile }
});

export const loadCompanyMainClientFailed = (error: string): CompanyAction => ({
    type: ActionType.LOAD_COMPANY_MAIN_CLIENT_FAILED,
    payload: error
});

export const saveCompanyMainClient = (
    id, name, city, state, country, codeId, groupId, contactEmail, contactName, logo
): CompanyAction => ({
    type: ActionType.SAVE_COMPANY_MAIN_CLIENT,
    payload: {
        id, name, city, state, country, codeId, groupId, contactEmail, contactName, logo
    },
});

export const saveCompanyMainClientSuccess = (mainClient): CompanyAction => ({
    type: ActionType.SAVE_COMPANY_MAIN_CLIENT_SUCCESS,
    payload: mainClient,
});

export const saveCompanyMainClientFailed = (error: string): CompanyAction => ({
    type: ActionType.SAVE_COMPANY_MAIN_CLIENT_FAILED,
    payload: error,
});

export const loadCompanyUsers = (): CompanyAction => ({
    type: ActionType.LOAD_COMPANY_USERS,
    payload: null,
});

export const loadCompanyUsersSuccess = (companyListUsers): CompanyAction => ({
    type: ActionType.LOAD_COMPANY_USERS_SUCCESS,
    payload: companyListUsers,
});

export const loadCompanyUsersFailed = (error: string): CompanyAction => ({
    type: ActionType.LOAD_COMPANY_USERS_FAILED,
    payload: error,
});

export const inviteUserToCompany = (userEmail): CompanyAction => ({
    type: ActionType.INVITE_USER_TO_COMPANY,
    payload: { userEmail },
});

export const inviteUserToCompanySuccess = (successMessage): CompanyAction => ({
    type: ActionType.INVITE_USER_TO_COMPANY_SUCCESS,
    payload: successMessage,
});

export const inviteUserToCompanyFailed = (errorMessage: string): CompanyAction => ({
    type: ActionType.INVITE_USER_TO_COMPANY_FAILED,
    payload: errorMessage,
});

export const removeUserManagerFlag = (userId): CompanyAction => ({
    type: ActionType.REMOVE_USER_MANAGER_FLAG,
    payload: { userId },
});

export const removeUserManagerFlagSuccess = (successMessage): CompanyAction => ({
    type: ActionType.REMOVE_USER_MANAGER_FLAG_SUCCESS,
    payload: successMessage,
});

export const removeUserManagerFlagFailed = (errorMessage: string): CompanyAction => ({
    type: ActionType.REMOVE_USER_MANAGER_FLAG_FAILED,
    payload: errorMessage,
});

export const makeUserManager = (userId): CompanyAction => ({
    type: ActionType.MAKE_USER_MANAGER,
    payload: { userId },
});

export const makeUserManagerSuccess = (successMessage): CompanyAction => ({
    type: ActionType.MAKE_USER_MANAGER_SUCCESS,
    payload: successMessage,
});

export const makeUserManagerFailed = (errorMessage: string): CompanyAction => ({
    type: ActionType.MAKE_USER_MANAGER_FAILED,
    payload: errorMessage,
});

export const associateLicense = (userId, planId): CompanyAction => ({
    type: ActionType.ASSOCIATE_LICENSE,
    payload: { userId, planId },
});

export const associateLicenseSuccess = (successMessage): CompanyAction => ({
    type: ActionType.ASSOCIATE_LICENSE_SUCCESS,
    payload: successMessage,
});

export const associateLicenseFailed = (errorMessage: string): CompanyAction => ({
    type: ActionType.ASSOCIATE_LICENSE_FAILED,
    payload: errorMessage,
});

export const cancelLicense = (userId): CompanyAction => ({
    type: ActionType.CANCEL_LICENSE,
    payload: { userId },
});

export const cancelLicenseSuccess = (successMessage): CompanyAction => ({
    type: ActionType.CANCEL_LICENSE_SUCCESS,
    payload: successMessage,
});

export const cancelLicenseFailed = (errorMessage: string): CompanyAction => ({
    type: ActionType.CANCEL_LICENSE_FAILED,
    payload: errorMessage,
});

export const removeUserFromOrganization = (userId): CompanyAction => ({
    type: ActionType.REMOVE_USER_FROM_ORGANIZATION,
    payload: { userId },
});

export const removeUserFromOrganizationSuccess = (successMessage): CompanyAction => ({
    type: ActionType.REMOVE_USER_FROM_ORGANIZATION_SUCCESS,
    payload: successMessage,
});

export const removeUserFromOrganizationFailed = (errorMessage: string): CompanyAction => ({
    type: ActionType.REMOVE_USER_FROM_ORGANIZATION_FAILED,
    payload: errorMessage,
});

export const loadCompanyValuePropositions = (): CompanyAction => ({
    type: ActionType.LOAD_COMPANY_VALUE_PROPOSITIONS,
    payload: null,
});

export const loadCompanyValuePropositionsSuccess = (vpList): CompanyAction => ({
    type: ActionType.LOAD_COMPANY_VALUE_PROPOSITIONS_SUCCESS,
    payload: vpList,
});

export const loadCompanyValuePropositionsFailed = (errorMessage: string): CompanyAction => ({
    type: ActionType.LOAD_COMPANY_VALUE_PROPOSITIONS_FAILED,
    payload: errorMessage,
});

export const getCompanyValuePropositionDetails = (vpId): CompanyAction => ({
    type: ActionType.GET_COMPANY_VALUE_PROPOSITION_DETAILS,
    payload: { vpId },
});

export const getCompanyValuePropositionDetailsSuccess = (vpId, vpDetails): CompanyAction => ({
    type: ActionType.GET_COMPANY_VALUE_PROPOSITION_DETAILS_SUCCESS,
    payload: { vpId, vpDetails },
});

export const getCompanyValuePropositionDetailsFailed = (errorMessage: string): CompanyAction => ({
    type: ActionType.GET_COMPANY_VALUE_PROPOSITION_DETAILS_FAILED,
    payload: errorMessage,
});

export const upgradeLicenses = (numberOfLicenses, licenseType): CompanyAction => ({
    type: ActionType.COMPANY_UPGRADE_LICENSES,
    payload: { numberOfLicenses, licenseType },
});

export const upgradeLicensesSuccess = (successMessage): CompanyAction => ({
    type: ActionType.COMPANY_UPGRADE_LICENSES_SUCCESS,
    payload: successMessage,
});

export const upgradeLicensesFailed = (errorMessage: string): CompanyAction => ({
    type: ActionType.COMPANY_UPGRADE_LICENSES_FAILED,
    payload: errorMessage,
});

export const downgradeLicenses = (numberOfLicenses, licenseType): CompanyAction => ({
    type: ActionType.COMPANY_DOWNGRADE_LICENSES,
    payload: { numberOfLicenses, licenseType },
});

export const downgradeLicensesSuccess = (successMessage): CompanyAction => ({
    type: ActionType.COMPANY_DOWNGRADE_LICENSES_SUCCESS,
    payload: successMessage,
});

export const downgradeLicensesFailed = (errorMessage: string): CompanyAction => ({
    type: ActionType.COMPANY_DOWNGRADE_LICENSES_FAILED,
    payload: errorMessage,
});

export const changeCompanyUserLicenses = (numberOfLicenses, licenseType, isUpgrade, quantity): CompanyAction => ({
    type: ActionType.CHANGE_COMPANY_USER_LICENSES,
    payload: { numberOfLicenses, licenseType, isUpgrade, quantity },
});

export const changeCompanyUserLicensesSuccess = (license): CompanyAction => ({
    type: ActionType.CHANGE_COMPANY_USER_LICENSES_SUCCESS,
    payload: license,
});

export const changeCompanyUserLicensesFailed = (errorMessage: string): CompanyAction => ({
    type: ActionType.CHANGE_COMPANY_USER_LICENSES_FAILED,
    payload: errorMessage,
});

export const clearCompanyUserLicense = () => ({
    type: ActionType.CLEAR_COMPANY_USER_LICENSE,
    payload: null,
});

export const userLicenseSuccessStrip = (): CompanyAction => ({
    type: ActionType.COMPANY_USER_LICENSE_SUCCESS_STRIPE,
    payload: null,
});

export const userLicenseSuccessStripSuccess = (successMessage): CompanyAction => ({
    type: ActionType.COMPANY_USER_LICENSE_SUCCESS_STRIPE_SUCCESS,
    payload: successMessage,
});

export const userLicenseSuccessStripFailed = (errorMessage: string): CompanyAction => ({
    type: ActionType.COMPANY_USER_LICENSE_SUCCESS_STRIPE_FAILED,
    payload: errorMessage,
});

export const getMyInvitations = (): CompanyAction => ({
    type: ActionType.GET_MY_INVITATIONS,
    payload: null,
});

export const getMyInvitationsSuccess = (invitations): CompanyAction => ({
    type: ActionType.GET_MY_INVITATIONS_SUCCESS,
    payload: invitations,
});

export const getMyInvitationsFailed = (errorMessage: string): CompanyAction => ({
    type: ActionType.GET_MY_INVITATIONS_FAILED,
    payload: errorMessage,
});

export const acceptInvitation = (companyId): CompanyAction => ({
    type: ActionType.ACCEPT_INVITATION,
    payload: { companyId },
});

export const acceptInvitationSuccess = (successMessage, newUserToken, companyId): CompanyAction => ({
    type: ActionType.ACCEPT_INVITATION_SUCCESS,
    payload: { newUserToken, successMessage, companyId },
});

export const acceptInvitationFailed = (errorMessage: string): CompanyAction => ({
    type: ActionType.ACCEPT_INVITATION_FAILED,
    payload: errorMessage,
});

export const rejectInvitation = (companyId): CompanyAction => ({
    type: ActionType.REJECT_INVITATION,
    payload: { companyId },
});

export const rejectInvitationSuccess = (successMessage, companyId): CompanyAction => ({
    type: ActionType.REJECT_INVITATION_SUCCESS,
    payload: { companyId, successMessage },
});

export const rejectInvitationFailed = (errorMessage: string): CompanyAction => ({
    type: ActionType.REJECT_INVITATION_FAILED,
    payload: errorMessage,
});
