import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Redirect, withRouter } from 'react-router'

import { COMPANY_DASHBOARD_PATH } from '../../constants';
import { addNotification, defaultDismiss } from '../../helpers/notification';
import { clearCompanyMessages, userLicenseSuccessStrip } from '../../redux/actions';
import LoaderWidget from '../../components/Loader'

const StripCompanyPaymentSuccessFeedbackInternalPage = (props) => {
    useEffect(() => {
        props.userLicenseSuccessStrip();
    }, []);

    useEffect(() => {
        if (!!props.actionSuccessMessage) {
            addNotification({
                title: 'License purchase successfully completed',
                message: props.actionSuccessMessage,
                type: 'success',
                dismiss: {...defaultDismiss, duration: 5000},
            });
            props.history.replace(COMPANY_DASHBOARD_PATH);
        }
    }, [props.actionSuccessMessage]);

    useEffect(() => {
        if (!!props.actionFailureMessage) {
            addNotification({
                title: 'Failed to request license purchase',
                message: props.actionFailureMessage,
                type: 'danger',
                dismiss: {...defaultDismiss, duration: 5000},
            });
            props.history.replace(COMPANY_DASHBOARD_PATH);
        }
    }, [props.actionFailureMessage]);

    return <LoaderWidget />;
}

const mapStateToProps = state => {
    return {
        actionFailureMessage: state.Company.licensesActionFailureMessage,
        actionSuccessMessage: state.Company.licensesActionSuccessMessage,
    }
}

export const StripCompanyPaymentSuccessFeedback = withRouter(
    connect(
        mapStateToProps, { clearCompanyMessages, userLicenseSuccessStrip }
    )(StripCompanyPaymentSuccessFeedbackInternalPage)
);

export const StripCompanyPaymentCancelFeedback = () => {
    useEffect(() => {
        addNotification({
            title: 'Failed to request license purchase',
            message: 'Payment flow has not been completed.',
            type: 'danger',
            dismiss: {...defaultDismiss, duration: 5000},
        });
    }, []);

    return <Redirect to={{ pathname: COMPANY_DASHBOARD_PATH }} />;
}
