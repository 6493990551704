import * as ActionType from './constants';

type Action = { type: string, payload: {} };
type State = { +value: boolean };

const initialState = {
    menuItems: [],
    isMainMenu: true,
}

const AppMenu = (state: State = initialState, action: Action) => {
    switch (action.type) {
        case ActionType.INIT_MENU:
            return { ...state, isMainMenu: true };
        case ActionType.INIT_PROFILE_MENU:
            return { ...state, isMainMenu: false };
        case ActionType.INIT_MENU_SUCCESS:
            return { ...state, ...action.payload };
        case ActionType.CHANGE_ACTIVE_MENU_FROM_LOCATION:
            return { ...state };
        case ActionType.CHANGE_ACTIVE_MENU_FROM_LOCATION_SUCCESS:
            return { ...state, ...action.payload };
        default:
            return state;
    }
};

export default AppMenu;
