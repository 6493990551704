/* AUTH */
export const LOGIN_USER = 'LOGIN_USER';
export const LOGIN_USER_SUCCESS = 'LOGIN_USER_SUCCESS';
export const LOGIN_USER_FAILED = 'LOGIN_USER_FAILED';

export const REGISTER_USER_EMAIL = 'REGISTER_USER_EMAIL';
export const REGISTER_USER_EMAIL_SUCCESS = 'REGISTER_USER_EMAIL_SUCCESS';
export const REGISTER_USER_EMAIL_FAILED = 'REGISTER_USER_EMAIL_FAILED';

export const VALIDATE_REGISTRATION_EMAIL = 'VALIDATE_REGISTRATION_EMAIL';
export const VALIDATE_REGISTRATION_EMAIL_SUCCESS = 'VALIDATE_REGISTRATION_EMAIL_SUCCESS';
export const VALIDATE_REGISTRATION_EMAIL_FAILED = 'VALIDATE_REGISTRATION_EMAIL_FAILED';

export const REGISTER_USER = 'REGISTER_USER';
export const REGISTER_USER_SUCCESS = 'REGISTER_USER_SUCCESS';
export const REGISTER_USER_FAILED = 'REGISTER_USER_FAILED';

export const LOGOUT_USER = 'LOGOUT_USER';

export const FORGET_PASSWORD = 'FORGET_PASSWORD';
export const FORGET_PASSWORD_SUCCESS = 'FORGET_PASSWORD_SUCCESS';
export const FORGET_PASSWORD_FAILED = 'FORGET_PASSWORD_FAILED';

export const RESET_PASSWORD = 'RESET_PASSWORD';
