/* FUNDALMENTALS LIST */
export const LOAD_FUNDAMENTALS_LIST = 'LOAD_FUNDAMENTALS_LIST';
export const LOAD_FUNDAMENTALS_LIST_SUCCESS = 'LOAD_FUNDAMENTALS_LIST_SUCCESS';
export const LOAD_FUNDAMENTALS_LIST_FAILED = 'LOAD_FUNDAMENTALS_LIST_FAILED';

export const CLEAR_FUNDAMENTALS_LIST = 'CLEAR_FUNDAMENTALS_LIST';

/* ADD/EDIT FUNDAMENTALS */
export const FETCH_FUNDAMENTALS_BY_ID = 'FETCH_FUNDAMENTALS_BY_ID';
export const FETCH_FUNDAMENTALS_BY_ID_SUCCESS = 'FETCH_FUNDAMENTALS_BY_ID_SUCCESS';
export const FETCH_FUNDAMENTALS_BY_ID_FAILED = 'FETCH_FUNDAMENTALS_BY_ID_FAILED';

export const SAVE_FUNDAMENTALS = 'SAVE_FUNDAMENTALS';
export const SAVE_FUNDAMENTALS_SUCCESS = 'SAVE_FUNDAMENTALS_SUCCESS';
export const SAVE_FUNDAMENTALS_FAILED = 'SAVE_FUNDAMENTALS_FAILED';

export const DELETE_FUNDAMENTALS = 'DELETE_FUNDAMENTALS';
export const DELETE_FUNDAMENTALS_SUCCESS = 'DELETE_FUNDAMENTALS_SUCCESS';
export const DELETE_FUNDAMENTALS_FAILED = 'DELETE_FUNDAMENTALS_FAILED';

export const LOAD_FUNDAMENTAL_PARAMS = 'LOAD_FUNDAMENTAL_PARAMS';
export const LOAD_FUNDAMENTAL_PARAMS_SUCCESS = 'LOAD_FUNDAMENTAL_PARAMS_SUCCESS';
export const LOAD_FUNDAMENTAL_PARAMS_FAILED = 'LOAD_FUNDAMENTAL_PARAMS_FAILED';

export const CALCULATE_FUNDAMENTAL_VALUES = 'CALCULATE_FUNDAMENTAL_VALUES';
export const CALCULATE_FUNDAMENTAL_VALUES_SUCCESS = 'CALCULATE_FUNDAMENTAL_VALUES_SUCCESS';
export const CALCULATE_FUNDAMENTAL_VALUES_FAILED = 'CALCULATE_FUNDAMENTAL_VALUES_FAILED';

export const CLEAR_CALCULATED_FUNDAMENTAL_VALUES = 'CLEAR_CALCULATED_FUNDAMENTAL_VALUES';
