import { all, call, fork, put, takeLatest } from '@redux-saga/core/effects';

import * as Actions from './actions';
import {
    CALCULATE_FUNDAMENTAL_VALUES,
    DELETE_FUNDAMENTALS,
    FETCH_FUNDAMENTALS_BY_ID,
    LOAD_FUNDAMENTALS_LIST,
    LOAD_FUNDAMENTAL_PARAMS,
    SAVE_FUNDAMENTALS
} from './constants';
import { fetchGetMethod, fetchPostMethod, GetErrorMessageFromStatus } from '../../helpers/api';
import { getLoggedInUser } from '../../helpers/authUtils';
import { apiServiceUrl } from '../../constants';

const fundamentalsApiServiceUrl = `${apiServiceUrl}/fundamentals`

function* loadFundamentalsList({ payload: { vpId }}) {
    try {
        const user = getLoggedInUser();
        const response = yield call(
            fetchGetMethod,
            `${fundamentalsApiServiceUrl}/GetFundamentals?valuePropositionId=${vpId}`,
            user.token
        );

        if (response.status === 200) {
            const { fundamentals, clientProfile, vpCurrency, status } = yield response.data.then((response) => {
                return (response.status === 200)
                    ? ({
                        fundamentals: response.fundamentals,
                        clientProfile: response.client,
                        vpCurrency: response.vpCurrency
                    })
                    : { status: response.status };
            }).catch(() => ({ status: 500 }));
            if (fundamentals && clientProfile && vpCurrency) {
                yield put(Actions.loadFundamentalsListSuccess(fundamentals, clientProfile, vpCurrency));
            } else {
                yield put(Actions.loadFundamentalsListFailed(GetErrorMessageFromStatus({ status: status })));
            }
        } else {
            yield put(Actions.loadFundamentalsListFailed(GetErrorMessageFromStatus(response)));
        }
    } catch (error) {
        yield put(Actions.loadFundamentalsListFailed(GetErrorMessageFromStatus(error)));
    }
}

function* fetchFundamentalsById({ payload: { id }}) {
    try {
        const user = getLoggedInUser();
        const response = yield call(
            fetchGetMethod,
            `${fundamentalsApiServiceUrl}/GetFundamentalById?fundamentalId=${id}`,
            user.token
        );

        if (response.status === 200) {
            const { fundamental , status } = yield response.data.then((response) => {
                return (response.status === 200)
                    ? { fundamental: response.fundamental }
                    : { status: response.status };
            }).catch(() => ({ status: 500 }));
            if (fundamental) {
                yield put(Actions.fetchFundamentalsByIdSuccess(fundamental));
            } else {
                yield put(Actions.fetchFundamentalsByIdFailed(GetErrorMessageFromStatus({ status: status })));
            }
        } else {
            yield put(Actions.fetchFundamentalsByIdFailed(GetErrorMessageFromStatus(response)));
        }
    } catch (error) {
        yield put(Actions.fetchFundamentalsByIdFailed(GetErrorMessageFromStatus(error)));
    }
}

function* saveFundamentals({ payload: { fundament }}) {
    try {
        const user = getLoggedInUser();
        const response = yield call(
            fetchPostMethod,
            `${fundamentalsApiServiceUrl}/SaveFundamentals`,
            user.token,
            fundament,
            /* withoutResponseBody */ false
        );

        if (response.status === 200) {
            yield put(Actions.saveFundamentalsSuccess())
        } else {
            yield put(Actions.saveFundamentalsFailed(GetErrorMessageFromStatus(response)));
        }
    } catch (error) {
        yield put(Actions.saveFundamentalsFailed(GetErrorMessageFromStatus(error)));
    }
}

function* deleteFundamentals({ payload: { id }}) {
    try {
        const user = getLoggedInUser();
        const response = yield call(
            fetchPostMethod,
            `${fundamentalsApiServiceUrl}/DeleteFundamentals`,
            user.token,
            { Id: id },
            /* withoutResponseBody */ false
        );

        if (response.status === 200) {
            yield put(Actions.deleteFundamentalsSuccess())
        } else {
            yield put(Actions.deleteFundamentalsFailed(GetErrorMessageFromStatus(response)));
        }
    } catch (error) {
        yield put(Actions.deleteFundamentalsFailed(GetErrorMessageFromStatus(error)));
    }
}

function* loadFundamentalParams() {
    try {
        const user = getLoggedInUser();
        const response = yield call(
            fetchGetMethod,
            `${fundamentalsApiServiceUrl}/GetFundamentalParams`,
            user.token
        );
        if (response.status === 200) {
            const { params, status } = yield response.data.then((response) => {
                return (response.status === 200)
                    ? ({
                        params: {
                            ExistingUnit: response.existingUnit,
                            ProposingUnit: response.proposingUnit,
                            UnitTypes: response.unitTypes,
                        }
                    })
                    : { status: response.status };
            }).catch(() => ({ status: 500 }));
            if (params) {
                yield put(Actions.loadFundamentalParamsSuccess(params));
            } else {
                yield put(Actions.loadFundamentalParamsFailed(GetErrorMessageFromStatus({ status: status })));
            }
        } else {
            yield put(Actions.loadFundamentalParamsFailed(GetErrorMessageFromStatus(response)));
        }

    } catch (error) {
        yield put(Actions.loadFundamentalParamsFailed(GetErrorMessageFromStatus(error)));
    }
}

function* calculateFundamentalValues({ payload: {
    existingCost, proposedCost, existingUnitId, proposedUnitId,
    existingUnitTypeId, proposedUnitTypeId,
    proposalDate, implementationDate, forecastDate, terminationDate
}}) {
    try {
        const user = getLoggedInUser();
        const body = {
            ExistingCost: existingCost,
            ProposedCost: proposedCost,
            ExistingUnitId: existingUnitId,
            ProposedUnitId: proposedUnitId,
            ExistingUnitTypeId: existingUnitTypeId,
            ProposedUnitTypeId: proposedUnitTypeId,
            ProposalDate: proposalDate ? new Date(proposalDate).toISOString() : null,
            ImplementationDate: implementationDate ? new Date(implementationDate).toISOString() : null,
            ForecastDate: forecastDate ? new Date(forecastDate).toISOString() : null,
            TerminationDate: terminationDate ? new Date(terminationDate).toISOString() : null,
        };
        const response = yield call(
            fetchPostMethod,
            `${fundamentalsApiServiceUrl}/CalculateFundamental`,
            user.token,
            body,
            /* withoutResponseBody */ false
        );

        if (response.status === 200) {
            const { result, status } = yield response.data.then((response) => {
                return (response.status === 200)
                    ? { result: response.result }
                    : { status: 500 }
            });
            if (result) {
                yield put(Actions.calculateFundamentalValuesSuccess(result));
            } else {
                yield put(Actions.calculateFundamentalValuesFailed(GetErrorMessageFromStatus({ status: status })));
            }
        } else {
            yield put(Actions.calculateFundamentalValuesFailed(GetErrorMessageFromStatus(response)));
        }
    } catch (error) {
        yield put(Actions.calculateFundamentalValuesFailed(GetErrorMessageFromStatus(error)));
    }
}

export function* watchLoadFundamentalsList(): any {
    yield takeLatest(LOAD_FUNDAMENTALS_LIST, loadFundamentalsList);
}

export function* watchFetchFundalmentalsById(): any {
    yield takeLatest(FETCH_FUNDAMENTALS_BY_ID, fetchFundamentalsById);
}

export function* watchSaveFundalmentals(): any {
    yield takeLatest(SAVE_FUNDAMENTALS, saveFundamentals);
}

export function* watchDeleteFundalmentals(): any {
    yield takeLatest(DELETE_FUNDAMENTALS, deleteFundamentals);
}

export function* watchLoadFundamentalParams(): any {
    yield takeLatest(LOAD_FUNDAMENTAL_PARAMS, loadFundamentalParams);
}

export function* watchCalculateFundamentalValues(): any {
    yield takeLatest(CALCULATE_FUNDAMENTAL_VALUES, calculateFundamentalValues);
}

function* fundamentalSaga(): any {
    yield all([
        fork(watchLoadFundamentalsList),
        fork(watchFetchFundalmentalsById),
        fork(watchSaveFundalmentals),
        fork(watchDeleteFundalmentals),
        fork(watchLoadFundamentalParams),
        fork(watchCalculateFundamentalValues),
    ]);
}

export default fundamentalSaga;
